import {
  object,
  string,
  boolean,
  constant,
  either,
  array,
  integer,
} from 'decoders';
import { Entity } from '.';

export type WebhookContext =
  | 'Organization'
  | 'Asset'
  | 'Beacon'
  | 'Location'
  | 'RemoteLocation'
  | 'FixedLocation'
  | 'MobileLocation'
  | 'Gateway'
  | 'MobileDevice';

export const webhookContextDecoder = either(
  constant('Organization' as WebhookContext),
  constant('Asset' as WebhookContext),
  constant('Beacon' as WebhookContext),
  constant('Location' as WebhookContext),
  constant('RemoteLocation' as WebhookContext),
  constant('FixedLocation' as WebhookContext),
  constant('MobileLocation' as WebhookContext),
  constant('Gateway' as WebhookContext),
  constant('MobileDevice' as WebhookContext)
);

export interface WebhookRequest {
  timestamp: string;
  url: string;
  headers: { [name: string]: string[] };
  body: string;
}

export interface WebhookResponse {
  timestamp: string;
  code: number;
  headers: { [name: string]: string[] };
  body: string;
}

interface WebhookError {
  message: string;
  backtrace: string[];
}

export interface WebhookResult {
  request: WebhookRequest;
  response?: WebhookResponse;
  error?: WebhookError;
}

export interface Webhook extends Entity {
  type?: WebhookContext;
  eventTypes: string[];
  entityId?: string;
  name?: string;
  enabled: boolean;
  url: string;
  contextId?: string;
  failures?: number;
  recent_results?: WebhookResult[];
}

export const webhookDecoder = object({
  id: string,
  name: string,
  event_types: array(string),
  enabled: boolean,
  failures: integer,
  recent_results: array(string),
  webhook_sendable: object({
    id: string,
    type: webhookContextDecoder,
    name: string,
  }),
  url: string,
}).transform((raw) => {
  return {
    id: raw.id,
    name: raw.name,
    contextId: raw.webhook_sendable.id,
    contextName: raw.webhook_sendable.name,
    type: raw.webhook_sendable.type,
    enabled: raw.enabled,
    failures: raw.failures,
    recent_results: raw.recent_results.map((result) => JSON.parse(result)),
    url: raw.url,
    eventTypes: raw.event_types,
  };
});
