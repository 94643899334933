<ng-container *hasPrivilege="'agreement/sign'; else signedAgreement">
  <ng-container
    *ngIf="
      hasUnsignedAgreement && (isImpersonating$ | async) === false;
      else signedAgreement
    "
  >
    <app-agreement (signed)="onAgreementSigned()"></app-agreement>
  </ng-container>
</ng-container>
<ng-template #signedAgreement>
  <ng-container *ngIf="allowIntoApp(); else subscribeNow">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  <ng-template #subscribeNow>
    <app-subscribe></app-subscribe>
  </ng-template>
</ng-template>
