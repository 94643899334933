import {
  HttpService,
  HttpServiceBuilder,
  HttpServiceConfig,
} from './http.service';
import { Decoder } from 'decoders';
import { identityEncoder } from '../models';
import { Observable } from 'rxjs';

export class FilteredSingletonEntityService<E, F> {
  httpService: HttpService<E>;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<E>,
    route: string,
    decoder: Decoder<E>
  ) {
    this.httpService = httpServiceBuilder.build({
      route,
      decoder,
      encoder: identityEncoder,
    });
  }

  getEntity(filter: F): Observable<E> {
    const config = this.getEntityConfig(filter);
    const query = this.getEntityQuery(filter);

    return this.httpService.getSingleton(config, query);
  }

  getEntityConfig(filter: F): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  getEntityQuery(filter: F): {} {
    return {};
  }
}
