<header>
  <mat-expansion-panel class="flat">
    <mat-expansion-panel-header class="filter-header">
      <mat-panel-title class="filter-title"> Filter </mat-panel-title>
      <mat-panel-description>
        <div class="filter-description">
          <span class="filter-description" *ngIf="!filter.search">
            Showing all webhooks
          </span>
          <span class="filter-description" *ngIf="filter.search">
            Showing webhooks matching "{{ filter.search }}"
          </span>
        </div>
        <div class="filter-clear">
          <button mat-stroked-button (click)="clearFilter($event)">
            Clear
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field class="webhooks-filter">
      <input
        matInput
        [(ngModel)]="filter.search"
        (change)="onFilterChange()"
        placeholder="Search"
      />
    </mat-form-field>
  </mat-expansion-panel>
</header>
<ng-template #noRead>
  <app-authorized-guard
    [authorized]="false"
    message="You are not authorized to view this data"
  ></app-authorized-guard>
</ng-template>
<mat-card appearance="outlined" *hasPrivilege="'webhook/read'; else noRead">
  <app-webhook-list
    [webhooks]="entityList"
    (delete)="confirmDeleteEntity($event)"
    [unauthorized_delete]="unauthorized_delete"
    [global]="true"
  ></app-webhook-list>

  <mat-paginator
    [length]="entityList.total"
    [pageIndex]="entityList.page - 1"
    [pageSize]="entityList.perPage"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageEvent($event)"
  ></mat-paginator>
</mat-card>
