
// THIS IS A GENERATED FILE
export const environment = {
  production: true,
  version: 'fcba958676ecf9fdf70be9aab01dde0b21fd365d',
  base_url: 'https://api.kahi.io',
  web_url: 'https://app.kahi.io',
  ws_url: 'wss://api.kahi.io/ws',
  mapbox_access_token: 'pk.eyJ1Ijoia2FoaS10ZWNob3BzIiwiYSI6ImNrOWthNjE1bDBmbWozcnMxbng3eWk1dXAifQ.rFUKNxj7sPGSIgqZ9qF-4A',
  sentry_dsn: 'https://182231ba11534b9e8137ee267e8bcdfe@o173771.ingest.sentry.io/4504137083060224',
  sentry_env: 'production',
  show_crash_dialog: false,
  segment_write_key: '7lldFoFPHQpsfgxxpCzA9Hz2iLz0jbba',
  vitals_url: 'https://app-react.kahi.io/dashboard'
};
