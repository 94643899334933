import { inject, Injectable } from '@angular/core';
import { Decoder } from 'decoders';
import { Observable } from 'rxjs';
import { EntityList } from 'src/app/models';
import { Coordinates, Location, LocationType } from 'src/app/models/location';
import { RelevanceStatus } from 'src/app/models/relevance-status';
import { EntityService } from 'src/app/util/entity.service';
import { HttpServiceBuilder } from 'src/app/util/http.service';
import { UserDataService } from 'src/app/util/user-data.service';

export interface LocationsFilter {
  type?: LocationType;
  search?: string;
  deleted?: boolean;
  relevanceStatus?: readonly RelevanceStatus[];
  homeLocationId?: string;
  customRoute?: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class LocationsService<
  L extends Location,
  F extends LocationsFilter
> extends EntityService<L, F> {
  private nomenclature = inject(UserDataService).nomenclature;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<L>,
    route: string,
    decoder: Decoder<L>
  ) {
    super(httpServiceBuilder, route, decoder);
  }

  getEntityListQuery(filter: F): {} {
    const query = {};
    if (filter.search) {
      query['search'] = filter.search;
    }
    if (filter.deleted) {
      query['deleted'] = true;
    }

    if (filter.relevanceStatus) {
      query['filter[relevance_status][]'] = filter.relevanceStatus;
    }

    if (filter.homeLocationId) {
      query['filter[home_location_id]'] = filter.homeLocationId;
    }

    return query;
  }

  confirmLocation(entity: L) {
    const config = { ...super.getCreateConfig(entity) };
    config.route = `${config.route}/${entity.id}/confirm`;
    return this.httpService.create(entity, config);
  }

  ignoreLocation(entity: L) {
    const config = { ...super.getCreateConfig(entity) };
    config.route = `${config.route}/${entity.id}/ignore`;
    return this.httpService.create(entity, config);
  }

  getLocationsInGeofence(
    swCorner: Coordinates,
    neCorner: Coordinates
  ): Observable<EntityList<L>> {
    const query = {
      'geofence[sw_corner]': `${swCorner.latitude},${swCorner.longitude}`,
      'geofence[ne_corner]': `${neCorner.latitude},${neCorner.longitude}`,
    };

    return this.httpService.search(query, { page: 0, perPage: 1000 });
  }

  getLocationTypeName(location: L, isPlural?: boolean): string {
    const plurality = isPlural ? 'plural' : 'singular';
    return this.nomenclature[plurality][`${location.type}_location`];
  }
}
