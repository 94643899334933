import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export abstract class StateService<S> implements OnDestroy {
  state$: Observable<S>;
  private stateSubj: BehaviorSubject<S>;

  protected get state(): S {
    return this.stateSubj.getValue();
  }

  private subscriptions = new Subscription();

  constructor(initialState?: S) {
    this.stateSubj = new BehaviorSubject<S>(initialState);
    this.state$ = this.stateSubj.asObservable();
  }

  protected select<R>(mapFn: (state: S) => R): Observable<R> {
    return this.state$.pipe(
      map((state) => mapFn(state)),
      distinctUntilChanged()
    );
  }

  protected setState(state: S): void {
    this.stateSubj.next(state);
  }

  protected patchState(patch: Partial<S>): void {
    this.stateSubj.next({
      ...this.state,
      ...patch,
    });
  }

  protected addSubscription(subscription: Subscription) {
    this.subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this.stateSubj.complete();
    this.subscriptions.unsubscribe();
  }
}
