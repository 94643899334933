import { Injectable } from '@angular/core';
import { ImpersonateDataService } from './impersonate-data.service';
import { AuthService } from '../core/services/api/auth.service';

@Injectable({ providedIn: 'root' })
export class ImpersonateService {

  constructor(
    public data: ImpersonateDataService,
    private auth: AuthService,
  ) {}

  endImpersonation() {
    this.auth.logout(true);
  }

}
