<mat-list>
  <ng-container *ngIf="artifacts.locations && artifacts.locations.length > 0">
    <div mat-subheader [ngPlural]="artifacts?.locations.length">
      <ng-template ngPluralCase="=0">No Locations</ng-template>
      <ng-template ngPluralCase="=1">Location</ng-template>
      <ng-template ngPluralCase="other">Locations</ng-template>
    </div>

    <mat-list-item
      *ngFor="let location of artifacts.locations"
      class="list-item-container"
      [routerLink]="getLocationLink(location)"
    >
      <div class="list-item">
        <app-indicator
          *ngIf="location.tracking"
          [appRelevanceIndicator]="location.tracking.asset_status"
        ></app-indicator>
        <div class="list-item-title">{{ location.name | truncate }}</div>
      </div>
    </mat-list-item>
  </ng-container>

  <ng-container
    *ngIf="artifacts.orphaned_assets && artifacts.orphaned_assets.length > 0"
  >
    <div mat-subheader>Orphaned Assets</div>

    <mat-list-item
      *ngFor="let asset of artifacts.orphaned_assets"
      class="list-item-container"
      [routerLink]="['/assets', asset.id]"
    >
      <div class="list-item">
        <div class="list-item-title">{{ asset.name | truncate }}</div>
      </div>
    </mat-list-item>
  </ng-container>
</mat-list>
