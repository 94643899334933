<ng-container
  *ngIf="(shouldShowMenu$ | async) && locations && locations.length > 1"
>
  <button mat-button [matMenuTriggerFor]="locationPicker">
    <mat-icon>my_location</mat-icon>
    <span class="context-title" *ngIf="selectedLocation; else allWarehouses">{{
      selectedLocation.name
    }}</span>
    <ng-template #allWarehouses>
      <span class="context-title">
        All {{ 'fixed_location' | nomenclature : 'plural' }}
      </span>
    </ng-template>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #locationPicker="matMenu">
    <button mat-menu-item (click)="setLocation(null)">
      All {{ 'fixed_location' | nomenclature : 'plural' }}
    </button>
    <ng-container *ngFor="let location of locations">
      <button
      mat-menu-item
      *ngIf="!location.discarded_at"
      (click)="setLocation(location.id)"
      >
      {{ location.name }}
    </button>
  </ng-container>
  </mat-menu>
</ng-container>
