import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetupStatusItem } from '../../models/setup-status';

@Component({
  selector: 'app-onboarding-checklist',
  templateUrl: './onboarding-checklist.component.html',
  styleUrls: ['./onboarding-checklist.component.scss'],
})
export class OnboardingChecklistComponent {
  @Input() items: SetupStatusItem[];
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  handleClose() {
    this.close.emit();
  }
}
