import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable, InjectionToken, Provider } from '@angular/core';
import { AuthService } from '../core/services/api/auth.service';

const namespacePrefix = 'kahi/state.service';
const version = 3;
const stateTTL = 20 * 1000; // 1 hr expiry

export type State<S, P> = {
  state?: S;
  prefs?: P;
};

interface ExpiringState<S, P> {
  state: State<S, P>;
  version: number;
  expires: number;
}

export const STORE_SERVICE_TOKEN = new InjectionToken<StoreService<any>>(
  'StateService'
);

function storeServiceFactory<S, P>(namespace: string) {
  return (authService: AuthService) =>
    new StoreService<S, P>(namespace, authService);
}

export const storeServiceProvider = <S, P = null>(
  namespace: string
): Provider => ({
  provide: STORE_SERVICE_TOKEN,
  useFactory: storeServiceFactory<S, P>(namespace),
  deps: [AuthService],
});

@Injectable()
export class StoreService<S, P = null> {
  private stateSubj = new BehaviorSubject<State<S, P>>({});

  constructor(private namespace: string, private authService: AuthService) {}

  // Returns the current state, checks local storage if no state is defined yet. Returns empty object if no state is found or state is expired.
  getState(initial?: State<S, P> | null, id?: string): State<S, P> {
    // Get state from local storage
    const userId = this.getUserId();
    const item = window.localStorage.getItem(
      `${namespacePrefix}/${userId}/${this.namespace}${id ? `/${id}` : ''}`
    );

    // Parse state from local storage and check version and expiry
    if (item) {
      const data: ExpiringState<S, P> = JSON.parse(item);
      if (data.version === version) {
        if (Date.now() < data.expires) {
          this.pushState(data.state, id);
          return data.state;
        }

        // Prefs shouldn't reset expiry
        if (data.state.prefs) {
          this.stateSubj.next({ prefs: data.state.prefs });
          return { prefs: data.state.prefs };
        }
      }

      // If state is expired, remove it
      window.localStorage.removeItem(
        `${namespacePrefix}/${userId}/${this.namespace}${id ? `/${id}` : ''}`
      );
    }

    // If no state or preferences is found, set and return initial state
    if (initial) {
      this.pushState(initial, id);
      return initial;
    }

    return {};
  }

  // Pushes a new state, updates expiry and version
  pushState(state: State<S, P>, id?: string) {
    this.putState(
      {
        state,
        version,
        expires: Date.now() + stateTTL,
      },
      id
    );
    this.stateSubj.next(state);
  }

  // Calls the callback when the state updates, uses the initial state if no state is defined yet
  listen(
    callback: (state: State<S, P>) => void,
    initial?: State<S, P>,
    id?: string
  ): Subscription {
    return this.getObservable(initial, id).subscribe({
      next: callback,
    });
  }

  // Returns an observable that emits the current state, uses the initial state if no state is defined yet
  getObservable(
    initial?: State<S, P> | null,
    id?: string
  ): Observable<State<S, P>> {
    this.getState(initial, id);
    return this.stateSubj.asObservable();
  }

  // Gets the userId from the auth service, returns 'anonymous' if no user is logged in
  private getUserId(): string {
    let userId = 'anonymous';
    if (this.authService.loggedInId) {
      userId = btoa(this.authService.loggedInId);
    }
    return userId;
  }

  // Saves the state to local storage
  private putState(data: ExpiringState<S, P>, id?: string) {
    const item = JSON.stringify(data);
    const userId = this.getUserId();
    window.localStorage.setItem(
      `${namespacePrefix}/${userId}/${this.namespace}${id ? `/${id}` : ''}`,
      item
    );
  }
}
