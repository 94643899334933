import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { ControlErrorDirective } from './control-error.directive';
import { ControlErrorOutletDirective } from './control-error-outlet.directive';
import { DynamicControlErrorComponent } from './dynamic-control-error.component';

@Component({
  selector: 'app-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
})
export class ControlErrorsComponent implements AfterViewInit, OnDestroy {
  @Input() controlName: string;

  // If true, error keys that have no provided ControlErrorDirective will be rendered with the default error string
  @Input() showDynamic = true;

  @ViewChild(ControlErrorOutletDirective, { static: true })
  errorOutlet: ControlErrorOutletDirective;
  @ContentChildren(ControlErrorDirective)
  controlErrorDefs: QueryList<ControlErrorDirective>;

  private subscriptions = new Subscription();

  constructor(private controlContainer: ControlContainer) {}

  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      merge(this.form.statusChanges, this.form.valueChanges).subscribe(() => {
        this.renderError();
      })
    );
  }

  private renderError() {
    if (!this.form.get(this.controlName)) {
      return;
    }

    this.errorOutlet.viewContainer.clear();

    const errorDef = this.controlErrorDefs.find((comp) =>
      this.form.get(this.controlName).hasError(comp.errorName)
    );

    if (errorDef) {
      this.renderErrorDef(errorDef);
    }
  }

  private renderErrorDef(errorDef: ControlErrorDirective) {
    if (errorDef.dynamicMessage) {
      const error = this.form
        .get(this.controlName)
        .getError(errorDef.errorName);
      this.renderDynamicControlError(error);
    } else {
      this.errorOutlet.viewContainer.createEmbeddedView(errorDef.template);
    }
  }

  private renderDynamicControlError(error: string) {
    const componentRef = this.errorOutlet.viewContainer.createComponent(
      DynamicControlErrorComponent
    );
    componentRef.instance.message = error;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
