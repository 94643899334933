
import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

export function timeSinceInWords(value: Date): string {
  if (!value || !(value instanceof Date)) {
    return 'unknown';
  }
  return formatDistanceToNow(value, { addSuffix: true });
}
@Pipe({
  name: 'timeSinceInWords',
})
export class TimeSinceInWordsPipe implements PipeTransform {
  transform(value: Date) {
    return timeSinceInWords(value);
  }
}
