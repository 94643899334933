import { array, boolean, number, object, string } from 'decoders';
import { Decodify } from './decoders';

export interface SetupStatus {
  percentage_complete: number;
  setup_status_items: SetupStatusItem[];
}

export interface SetupStatusItem {
  slug: string;
  name: string;
  description: string;
  completed: boolean;
  action_url: string;
  support_url: string;
}

export const setupStatusItemDecoder = object<Decodify<SetupStatusItem>>({
  slug: string,
  name: string,
  description: string,
  completed: boolean,
  action_url: string,
  support_url: string,
});

export const setupStatusDecoder = object<Decodify<SetupStatus>>({
  percentage_complete: number,
  setup_status_items: array(setupStatusItemDecoder),
});
