<div>
  <app-loading-guard [loading]="entityList.loading">
    <mat-table
      id="asset-alerts-table"
      [dataSource]="entityList.entities"
      class="kahi-table"
    >
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="select-column">
          <mat-checkbox
            (change)="selectAll($event.checked)"
            [(ngModel)]="allChecked"
            class="kh-invert-checkbox"
          ></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let item" class="select-column">
          <div>
            <mat-checkbox
              [(ngModel)]="rowSelections[item.id]"
              (click)="checkboxClick($event)"
              (change)="update()"
              class="kh-invert-checkbox"
            ></mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <img
            *ngIf="imageUrl(item) as src; else noImage"
            [src]="src"
            (click)="viewImages(item.images)"
            class="image-thumbnail"
          />
          <ng-template #noImage>
            <div class="image-thumbnail no-image">?</div>
          </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a [routerLink]="'/assets/' + item.id">{{ item.name }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="heartbeat_timestamp">
        <mat-header-cell *matHeaderCellDef>Last Seen</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span *ngIf="item.tracking?.heartbeat_timestamp">{{
            item.tracking.heartbeat_timestamp
              | formatDate : item.tracking?.time_zone
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span
            *ngIf="
              item.tracking && item.tracking.location;
              else unknownLocation
            "
          >
            <app-location-link [location]="item.tracking.location">
              {{ item.tracking.location.name }}
            </app-location-link>
          </span>
          <ng-template #unknownLocation>(Unknown)</ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="home_location">
        <mat-header-cell *matHeaderCellDef>Home Location</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span *ngIf="item.home_location; else noHomeLocation">
            <app-location-link [location]="item.home_location">
              {{ item.home_location.name }}
            </app-location-link>
          </span>
          <ng-template #noHomeLocation>(None)</ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="coordinates">
        <mat-header-cell *matHeaderCellDef>Coordinates</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a
            *ngIf="item.tracking?.coordinates; else nocoordinates"
            (click)="openMapDialog(item)"
          >
            ({{ item.tracking.coordinates.latitude }},
            {{ item.tracking.coordinates.longitude }})
          </a>
          <ng-template #nocoordinates>
            <span>Unknown</span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="heartbeat_status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <app-heartbeat-status
            *ngIf="item.tracking"
            [status]="item.tracking.heartbeat_status"
          ></app-heartbeat-status>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <div class="actions">
            <button
              mat-flat-button
              rippleOnHover
              [routerLink]="'/assets/' + item.id + '/edit'"
              class="action"
              color="accent"
            >
              Edit Asset
            </button>
            <button
              mat-flat-button
              rippleOnHover
              [routerLink]="'/assets/' + item.id + '/details'"
              class="action"
              color="primary"
            >
              View Asset
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="view">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <div class="actions">
            <button
              mat-flat-button
              rippleOnHover
              [routerLink]="'/assets/' + item.id + '/details'"
              class="action"
              color="primary"
            >
              View Asset
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orphaned">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <button
            mat-stroked-button
            class="jobsite-button action"
            *ngIf="item.tracking?.coordinates"
            (click)="openJobsiteSelect(item)"
          >
            Assign To Location
            <mat-icon>open_in_new</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        class="header-row"
        *matHeaderRowDef="displayedColumns"
      ></mat-header-row>
      <mat-row
        class="row"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </app-loading-guard>

  <mat-paginator
    [length]="entityList.total"
    [pageIndex]="entityList.page - 1"
    [pageSize]="entityList.perPage"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageEvent($event)"
  ></mat-paginator>
</div>
