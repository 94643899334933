import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ParamMap } from '@angular/router';
import { NEVER, Observable, Subscription } from 'rxjs';
import { Entity } from '../models';
import { EntityDetailsPageService } from './entity-details-page.service';
import { EntityService } from './entity.service';
import { EventsServiceManager } from './events.service';
import { MessageEvent } from './message-event.service';
import { catchError } from 'rxjs/operators';
import { ActionConfirmer } from './confirmation';

@Directive()
export abstract class EntityDetailsPageComponent<E extends Entity, F = {}>
  implements OnInit, OnDestroy
{
  protected subscription = new Subscription();

  // Observables retrieved from the service
  loading$ = this.detailsService.loading$;
  authorized$ = this.detailsService.authorized$;
  item$ = this.detailsService.getItem(
    (params) => this.loadEntity(params),
    (entity) => this.onEntityLoad?.(entity)
  );

  // Injected services
  private titleService = inject(Title);
  protected eventsService = inject(EventsServiceManager).get(
    'content.messages',
    {
      message: '',
      status: 'success',
    }
  );

  // baseTitle is the page title displayed before the entity is loaded
  abstract baseTitle: string;

  constructor(
    private detailsService: EntityDetailsPageService<E>,
    private entityService: EntityService<E, F>,
    private confirmer: ActionConfirmer
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.baseTitle);
    this.item$.subscribe((entity) => {
      this.titleService.setTitle(this.getTitle(entity));
    });
  }

  protected onEntityLoad?(entity: E): void;

  protected getTitle(entity: E): string {
    return this.baseTitle;
  }

  protected loadEntity(params: ParamMap): Observable<E> {
    const id = params.get('id');
    if (id) {
      return this.entityService.getEntity(id);
    }
    return NEVER;
  }

  /**
   * Opens up a confirmation dialog, if user confirms the callback is called.
   * On error authorized$ is set to false and nothing returned, on callback success OnSuccess is called. Always subscribes
   *
   * @param confirmationMessage The message displayed on the confirmation dialog
   * @param callback Function called on user confirmation
   * @param onSuccess Function called when callback is a success
   */
  protected confirmAction<C>(
    confirmationMessage: string,
    callback: () => Observable<C>,
    onSuccess?: (returnValue: C) => void,
    onError?: (error: Error) => void
  ) {
    this.subscription.add(
      this.confirmer.confirmAction(confirmationMessage, () => {
        this.subscription.add(
          callback()
            .pipe(
              catchError((error: Error) => {
                onError?.(error);
                return NEVER;
              })
            )
            .subscribe(onSuccess)
        );
      })
    );
  }

  protected pushEvent(event: MessageEvent): void {
    this.eventsService.pushEvent(event);
  }

  refreshEntity() {
    this.detailsService.refreshItem();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
