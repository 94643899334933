import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertsLocationService } from 'src/app/alerts/services/alerts-location.service';
import { Location } from 'src/app/models/location';
import { ErrorEventService } from 'src/app/util/error-event.service';
import { MessageEventService } from 'src/app/util/message-event.service';

@Component({
  selector: 'app-confirm-location-dialog',
  templateUrl: './confirm-location-dialog.component.html',
  styleUrls: ['./confirm-location-dialog.component.scss'],
})
export class ConfirmLocationDialogComponent {
  confirm: boolean;
  selectedLocations: Location[];

  dialogTitle: string;
  dialogMessage: string;
  confirmBtnText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirm: boolean;
      selectedLocations: Location[];
    },
    public dialogRef: MatDialogRef<ConfirmLocationDialogComponent>,
    private alertsLocationsService: AlertsLocationService,
    private messageEventService: MessageEventService,
    private errorEventService: ErrorEventService
  ) {
    this.confirm = data.confirm;
    this.selectedLocations = data.selectedLocations;

    this.dialogTitle = this.confirm
      ? 'Confirm Location'
      : 'Ignore Unconfirmed Location';
    this.dialogMessage = this.confirm
      ? 'Are you sure you want to confirm the location of the following assets?'
      : 'Are you sure you want ignore the unconfirmed location of the following assets?';
    this.confirmBtnText = this.confirm ? 'Confirm' : 'Ignore';
  }

  confirmClick(e) {
    e.preventDefault();
    if (this.confirm) {
      this.confirmSelection();
    } else {
      this.ignoreSelection();
    }
  }

  confirmSelection() {
    const selectedLocationIds = this.selectedLocations.flatMap(
      (asset) => asset.id
    );

    this.alertsLocationsService
      .confirmMultipleLocations(selectedLocationIds)
      .pipe(
        catchError((error: Error) => {
          console.error(error);
          this.errorEventService.pushEvent(
            new Error('Failed to confirm location. Try again later')
          );
          return NEVER;
        })
      )
      .subscribe(() => {
        this.messageEventService.pushEvent({
          message: 'Successfully confirmed location',
          status: 'success',
          snackBarConfig: {
            duration: 5000,
          },
        });
        this.sendRefreshEntitiesEvent();
        this.dialogRef.close();
      });
  }

  ignoreSelection() {
    const selectedLocationIds = this.selectedLocations.flatMap(
      (asset) => asset.id
    );

    this.alertsLocationsService
      .ignoreMultipleLocations(selectedLocationIds)
      .pipe(
        catchError((error: Error) => {
          console.error(error);
          this.errorEventService.pushEvent(
            new Error('Failed to ignore unconfirmed location. Try again later.')
          );
          return NEVER;
        })
      )
      .subscribe(() => {
        this.messageEventService.pushEvent({
          message: 'Successfully ignored unconfirmed location',
          status: 'success',
          snackBarConfig: {
            duration: 5000,
          },
        });
        this.sendRefreshEntitiesEvent();
        this.dialogRef.close();
      });
  }

  sendRefreshEntitiesEvent() {
    /**Sending a window event instead of element event because modals are rendered
     * outside of the dom heirarchy of the required parent element */
    window.dispatchEvent(new CustomEvent('RefreshEntitiesEvent'));
  }

  onClose(e) {
    e.preventDefault();
    this.dialogRef.close();
  }
}
