<div class="wrapper">

  <div class="content-wrapper" [ngClass]="item.completed ? 'complete' : 'incomplete'" (click)="handleActionClick()">
    <mat-icon *ngIf="item.completed" class="complete">check_circle</mat-icon>
    <mat-icon *ngIf="!item.completed" class="incomplete">radio_button_unchecked</mat-icon>
    <div class="message" [ngClass]="item.completed ? 'complete' : 'incomplete'">
      {{ item.description }}
    </div>
  </div>

  <button mat-icon-button class="help-button" (click)="handleHelpClick()">
    <mat-icon *ngIf="!item.completed">help_outline</mat-icon>
  </button>

</div>
