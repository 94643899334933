import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { PortalService } from '../portal.service';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-portal',
  template: `
    <ng-template #portal>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class PortalComponent implements OnDestroy, OnInit {

  @Input() name: string = null;

  @ViewChild('portal', { static: true, read: TemplateRef }) portal: TemplateRef<any>;

  constructor(
    public portalService: PortalService,
    private vcRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.portalService.setPortal(
      new TemplatePortal(this.portal, this.vcRef, {}),
      this.name
    );
  }

  ngOnDestroy() {
    this.portalService.clearPortal(this.name);
  }
}
