<app-authorized-guard
  [authorized]="!unauthorized"
  message="You are not authorized to create a webhook."
>
  <form class="entity-form" (submit)="createEntity()">
    <mat-form-field class="form-field col100">
      <mat-label>Webhook Type</mat-label>
      <mat-select [formControl]="form.controls.definition" name="definition">
        <mat-option *ngFor="let defn of definitions" [value]="defn.code"
          >{{ defn.name }} ({{ defn.code }})</mat-option
        >
      </mat-select>
      <mat-error>
        <app-control-errors controlName="type">
          <ng-template appControlError="api" dynamicMessage></ng-template>
        </app-control-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-field col100">
      <input
        matInput
        name="name"
        type="text"
        placeholder="Name"
        [formControl]="form.controls.name"
      />
      <mat-error>
        <app-control-errors controlName="type">
          <ng-template appControlError="api" dynamicMessage></ng-template>
        </app-control-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-field col100">
      <input
        matInput
        name="url"
        type="text"
        placeholder="URL"
        [formControl]="form.controls.url"
      />
      <mat-error>
        <app-control-errors controlName="type">
          <ng-template appControlError="api" dynamicMessage></ng-template>
        </app-control-errors>
      </mat-error>
    </mat-form-field>
    <div>
      <mat-slide-toggle
        aria-label="Enabled"
        color="primary"
        [checked]="form.value.enabled"
        (change)="onUpdateEnabled($event)"
        >Enabled</mat-slide-toggle
      >
    </div>
    <div class="form-buttons">
      <button mat-raised-button color="primary" [disabled]="pending">
        Create
      </button>
      <a mat-button [routerLink]="getReturnPath()">Cancel</a>
    </div>
  </form>
</app-authorized-guard>
