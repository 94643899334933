import { Injectable } from '@angular/core';
import { StateService } from '../core/services/state/state.service';
import { Organization } from '../models/organization';
import { Observable, Subject } from 'rxjs';
import { SetupStatus } from '../models/setup-status';
import { switchMap, tap } from 'rxjs/operators';
import { OrganizationsService } from '../organization/organizations.service';

export interface OnboardingState {
  organization: Organization;
  loading: boolean;
}

const initialState = {
  organization: null,
  loading: false,
};

@Injectable()
export class OnboardingDataService extends StateService<OnboardingState> {
  setupStatus$: Observable<SetupStatus> = this.select(
    (state) => state.organization?.setup_status
  );
  loading$: Observable<boolean> = this.select((state) => state.loading);

  private loadData$ = new Subject();

  constructor(organizationService: OrganizationsService) {
    super(initialState);

    this.addSubscription(
      this.loadData$
        .pipe(
          tap(() => this.patchState({ loading: true })),
          switchMap(() =>
            organizationService
              .getEntity()
              .pipe(
                tap((organization) =>
                  this.patchState({ loading: false, organization })
                )
              )
          )
        )
        .subscribe()
    );
  }

  reload() {
    this.loadData$.next();
  }
}
