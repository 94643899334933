import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AlertsCentreComponent } from './pages/alerts-centre/alerts-centre.component';
import { AlertAssetsComponent } from './pages/assets/assets.component';
import { AlertLocationsComponent } from './pages/locations/locations.component';
import { GatewaysComponent } from './pages/gateways/gateways.component';

const routes: Routes = [
  {
    path: '',
    component: AlertsCentreComponent,
    data: {
      headerTitle: 'Notifications',
    },
    children: [
      {
        path: '',
        redirectTo: '/map',
        pathMatch: 'full',
      },
      {
        path: 'assets/:alert_type',
        component: AlertAssetsComponent,
        data: {
          headerTitle: 'Asset Notifications',
        },
      },
      {
        path: 'locations/:alert_type',
        component: AlertLocationsComponent,
        data: {
          headerTitle: 'Location Notifications',
        },
      },
      {
        path: 'remote_locations/:alert_type',
        component: AlertLocationsComponent,
        data: {
          headerTitle: '@(remote_location) Notifications',
          locationType: 'remote',
        },
      },
      {
        path: 'fixed_locations/:alert_type',
        component: AlertLocationsComponent,
        data: {
          headerTitle: '@(fixed_location) Notifications',
          locationType: 'fixed',
        },
      },
      {
        path: 'mobile_locations/:alert_type',
        component: AlertLocationsComponent,
        data: {
          headerTitle: '@(mobile_location) Notifications',
          locationType: 'mobile',
        },
      },
      {
        path: 'fixed_gateways/:alert_type',
        component: GatewaysComponent,
        data: {
          headerTitle: 'Fixed Gateway Notifications',
          gatewayType: 'fixed',
        },
      },
      {
        path: 'mobile_gateways/:alert_type',
        component: GatewaysComponent,
        data: {
          headerTitle: 'Mobile Gateway Notifications',
          gatewayType: 'mobile',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertsRoutingModule {}
