import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Location } from '../../models/location';
import { LocationContextService } from './location-context.service';

// Base routes that should hide the location context menu
const HIDDEN_ROUTES = ['/inventory', '/reports'];

@Component({
  selector: 'app-location-context-select',
  templateUrl: './location-context-select.component.html',
  styleUrls: ['./location-context-select.component.scss'],
})
export class LocationContextSelectComponent implements OnInit, OnDestroy {
  locations: Location[] = [];
  selectedLocation: Location = null;
  shouldShowMenu$: Observable<boolean>;

  private subscriptions = new Subscription();

  constructor(
    private locationContextService: LocationContextService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // True if current route is not in hidden routes
    this.shouldShowMenu$ = this.router.events.pipe(
      filter((event) => event instanceof Scroll),
      map(
        (event: Scroll) =>
          !HIDDEN_ROUTES.find((route) => event.routerEvent.url.includes(route))
      )
    );
    this.subscriptions.add(
      this.locationContextService.location$.subscribe((location) => {
        if (this.locationContextService.locations) {
          this.locations = this.locationContextService.locations;
          this.selectedLocation = location;
        }
      })
    );
  }

  setLocation(locationId: string) {
    this.locationContextService.setLocation(locationId);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
