import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssetTrackingMapDialogComponent } from 'src/app/assets/components/asset-tabs/asset-tracking-map-dialog/asset-tracking-map-dialog.component';
import { OrphanedAssetService } from 'src/app/assets/services/orphaned-asset.service';
import { AlertsService } from 'src/app/core/services/api/alerts.service';
import { EntityList } from 'src/app/models';
import { Asset } from 'src/app/models/asset';
import { HeartbeatStatus } from 'src/app/models/heartbeat-status';
import { Image } from 'src/app/models/image';
import { RemoteLocation } from 'src/app/models/remote-location';
import { ImageGalleryOverlayService } from 'src/app/shared/image-gallery/image-gallery-overlay.service';
import { actionsTableMixin } from 'src/app/shared/mixins/actions-table.mixin';
import { JobsiteSelectDialogComponent } from '../jobsite-select-dialog/jobsite-select-dialog.component';

// create a mixin for this component to add an actions menu
const AssetsListMixin = actionsTableMixin(class Parent {});

@Component({
  selector: 'app-asset-alerts-list',
  templateUrl: './asset-alerts-list.component.html',
  styleUrls: ['./asset-alerts-list.component.scss'],
  providers: [OrphanedAssetService],
})
export class AssetAlertsListComponent
  extends AssetsListMixin
  implements OnChanges
{
  @Input() entityList: EntityList<Asset>;
  @Input() displayedColumns: string[];

  @Output() pageEvent = new EventEmitter<any>();
  @Output() refreshEvent = new EventEmitter<any>();

  constructor(
    private imageGalleryService: ImageGalleryOverlayService,
    private router: Router,
    private dialog: MatDialog,
    private assetsService: OrphanedAssetService,
    private alertsService: AlertsService
  ) {
    super();
  }

  onPageEvent(event) {
    this.pageEvent.emit(event);
  }

  viewImages(images: Image[]) {
    this.imageGalleryService.open({
      index: 0,
      images,
    });
  }

  imageUrl(asset) {
    return asset.images?.[0]?.small_url;
  }

  openJobsiteSelect(asset: Asset) {
    // Open the dialog that lets you select a jobsite
    this.dialog
      .open(JobsiteSelectDialogComponent, {
        data: {
          asset,
        },
      })
      .afterClosed()
      .subscribe((resp: { jobsite: RemoteLocation; time: Date }) => {
        if (resp && resp.jobsite) {
          this.assetsService
            .pinpointAsset(asset, resp.jobsite, resp.time)
            .subscribe(() => {
              this.refreshEvent.emit();
              this.alertsService.refreshAlerts();
            });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If alerts list is empty redirect to the alerts centre page
    if (this.entityList.loading === false && this.entityList.total === 0) {
      this.router.navigateByUrl('/notifications');
      return;
    }
  }

  openMapDialog(asset: Asset) {
    this.dialog.open(AssetTrackingMapDialogComponent, {
      width: '90%',
      data: {
        heartbeatStatus: HeartbeatStatus.Recent,
        coordinates: {
          latitude: asset.tracking?.coordinates?.latitude,
          longitude: asset.tracking?.coordinates?.longitude,
        },
      },
    });
  }
}
