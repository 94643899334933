import { object, string, maybe, number, constant, either } from 'decoders';
import { Decodify } from './decoders';

export type BatteryStatus = 'unknown' | 'new' | 'good' | 'low' | 'critical';

export interface Beacon {
  id: string;
  advertiser_address: string;
  advertiser_address_qrcode: string;
  battery_percent?: number;
  battery_status: BatteryStatus;
}

export const batteryStatusDecoder = either(
  constant('unknown' as BatteryStatus),
  constant('new' as BatteryStatus),
  constant('good' as BatteryStatus),
  constant('low' as BatteryStatus),
  constant('critical' as BatteryStatus)
);

export const beaconDecoder = object<Decodify<Beacon>>({
  id: string,
  advertiser_address: string,
  advertiser_address_qrcode: string,
  battery_percent: maybe(number),
  battery_status: batteryStatusDecoder,
});
