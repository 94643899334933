<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button
    mat-stroked-button
    [mat-dialog-close]="true"
    color="primary"
    cdkFocusInitial
  >
    Yes
  </button>
  <button mat-stroked-button [mat-dialog-close]="false" color="warn">No</button>
</div>
