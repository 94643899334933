<app-loading-guard [loading]="entityList.loading">
  <div class="locations-list-container">
    <app-location-alerts-list
      [entityList]="entityList"
      [displayedColumns]="displayedColumns"
      [tableId]="getTableId()"
      (pageEvent)="onPageEvent($event)"
      (reload)="refreshLocations()"
    ></app-location-alerts-list>
  </div>
</app-loading-guard>
