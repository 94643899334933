import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule as CdkPortalModule } from '@angular/cdk/portal';
import { PortalComponent } from './portal/portal.component';
import { PortalOutletComponent } from './portal-outlet/portal-outlet.component';

@NgModule({
  imports: [
    CommonModule,
    CdkPortalModule,
  ],
  declarations: [
    PortalComponent,
    PortalOutletComponent,
  ],
  exports: [
    PortalComponent,
    PortalOutletComponent,
  ]
})
export class PortalModule {}
