<app-authorized-guard [authorized]="!unauthorized">
  <div class="plan-list">
    <mat-card
      appearance="outlined"
      *ngFor="let item of entityList.entities"
      class="plan-card"
    >
      <mat-card-header class="plan-header">
        <mat-card-title
          ><p class="plan-title">{{ item.metadata.title }}</p></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <p class="plan-amount">
          {{ formatAmount(item.amount, item.currency, 0) }}
          {{ item.currency }} / {{ item.interval }}
        </p>
      </mat-card-content>
      <mat-card-content class="plan-description">
        <div [innerHTML]="item.metadata.description_html"></div>
      </mat-card-content>
      <mat-divider class="divider"></mat-divider>
      <mat-card-actions class="plan-actions">
        <button
          mat-raised-button
          [disabled]="pending || item.id === active"
          (click)="handlePlanClick(item)"
          class="plan-button"
        >
          {{ item.id === active ? 'Current Plan' : 'Choose Plan' }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</app-authorized-guard>
