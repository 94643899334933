import { Injectable } from '@angular/core';
import { EntityService } from '../util/entity.service';
import { Plan, planDecoder } from '../models/plan';
import { HttpServiceBuilder } from '../util/http.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService extends EntityService<Plan, {}> {
  constructor(httpServiceBuilder: HttpServiceBuilder<Plan>) {
    super(httpServiceBuilder, 'plans', planDecoder);
  }
}
