import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();

  const apiURL = new URL(environment.base_url);
  Sentry.init({
    dsn: environment.sentry_dsn,
    release: environment.version,
    environment: environment.sentry_env,
    ignoreErrors: [/^Unauthorized$/],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [apiURL.hostname],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    tracesSampleRate: 1.0,
  });
} else {
  console.log('Starting in development mode');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
