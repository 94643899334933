<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<a [routerLink]="getLocationRoute()" *ngIf="hasPrivilege; else noPriv">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<ng-template #noPriv>
  <span>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</ng-template>
