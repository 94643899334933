import { Pipe, PipeTransform } from '@angular/core';
import { format, utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'formatDate',
})
export class DateFormatPipe implements PipeTransform {
  transform(time?: Date, timeZone?: string, showSeconds = false): string {
    if (!time) {
      return '';
    }

    // Gets the localdate if there is a given timeZone
    const localDate = timeZone ? utcToZonedTime(time, timeZone) : time;
    const options = timeZone ? { timeZone } : null;

    // Does not show the year if it is this year
    const now = new Date();
    const getDateFormat = () => {
      if (showSeconds) {
        return now.getFullYear() === localDate.getFullYear()
          ? 'EEE MMM d HH:mm:ss zzz'
          : 'EEE MMM d, yyyy HH:mm:ss zzz';
      }
      return now.getFullYear() === localDate.getFullYear()
        ? 'EEE MMM d HH:mm zzz'
        : 'EEE MMM d, yyyy HH:mm zzz';
    };
    return format(localDate, getDateFormat(), options);
  }
}
