import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Organization } from '../models/organization';
import { Plan } from '../models/plan';
import { SubscriptionService } from './subscription.service';

@Component({
  selector: 'app-subscription-create',
  templateUrl: './subscription-create.component.html',
  styleUrls: ['./subscription-create.component.scss'],
})
export class SubscriptionCreateComponent {
  pending = false;

  constructor(
    private subService: SubscriptionService,
    private router: Router
  ) {}

  handlePlanSelect(plan: Plan) {
    this.pending = true;
    const successUrl = `${environment.web_url}/subscription/subscribed`;
    const cancelUrl = location.href;
    this.subService.create(plan.id, successUrl, cancelUrl).subscribe(
      (organization: Organization) => {
        if (organization.stripe_checkout_session_id) {
          location.href = '/checkout.html';
        } else {
          this.router.navigateByUrl('/');
        }
      },
      () => {
        this.pending = false;
      }
    );
  }
}
