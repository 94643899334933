<mat-menu #appMenu="matMenu" xPosition="before" class="menu">
  <div class="menu">
    <a routerLink="/profile" mat-ripple>
      <div class="menu-header">
        <mat-icon class="header-icon">account_circle</mat-icon>
        <div class="mat-h3">{{ user && user.email ? user.email : '' }}</div>
      </div>
    </a>
    <mat-divider></mat-divider>
    <ng-container *ngFor="let item of navItems">
      <a class="kh-text-primary" mat-menu-item [routerLink]="item.route"
        ><mat-icon>{{ item.icon }}</mat-icon
        >{{ item.label }}</a
      >
    </ng-container>
  </div>
</mat-menu>

<a [matMenuTriggerFor]="appMenu"><ng-content></ng-content></a>
