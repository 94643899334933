import { array, maybe, object, string } from 'decoders';
import { Entity } from '.';
import { Decodify, isodate } from './decoders';
import { HeartbeatStatus, heartbeatStatusDecoder } from './heartbeat-status';
import { Image, imageDecoder } from './image';
import { LocationType, locationTypeDecoder } from './location';

export interface GatewayLocation {
  id: string;
  name: string;
  type: LocationType;
}

export interface GatewayTracking {
  id?: string;
  synchronized_at?: Date;
  heartbeat_status?: HeartbeatStatus;
  heartbeat_timestamp?: Date;
}

export interface InstallLocation {
  id: string;
  install_area: string;
  installed_at: Date;
  images?: Image[];
}
export interface Gateway extends Entity {
  name: string;
  external_uid: string;
  external_uid_qrcode: string;
  platform: string;
  serial_number?: string;
  location?: GatewayLocation;
  location_install?: InstallLocation;
  tracking?: GatewayTracking;
  type?: string;
}

const gatewayLocationDecoder = object<Decodify<GatewayLocation>>({
  id: string,
  name: string,
  type: locationTypeDecoder,
});

const installLocationDecoder = object<Decodify<InstallLocation>>({
  id: string,
  install_area: string,
  installed_at: isodate,
  images: maybe(array(imageDecoder)),
});

export const gatewayTrackingDecoder = object<Decodify<GatewayTracking>>({
  id: maybe(string),
  synchronized_at: maybe(isodate),
  heartbeat_timestamp: maybe(isodate),
  heartbeat_status: maybe(heartbeatStatusDecoder),
});

export const gatewayDecoder = object<Decodify<Gateway>>({
  id: string,
  name: string,
  external_uid: string,
  external_uid_qrcode: string,
  platform: string,
  serial_number: maybe(string),
  location: maybe(gatewayLocationDecoder),
  location_install: maybe(installLocationDecoder),
  tracking: maybe(gatewayTrackingDecoder),
  type: maybe(string),
});
