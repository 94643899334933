import { AfterViewInit, Component, OnDestroy, Renderer2 } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { SegmentService } from 'ngx-segment-analytics';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/app/util/user-data.service';
import { environment } from 'src/environments/environment';
import { AppIcons } from './app.icons';
import { AppNavigationConfig } from './app.navigation';
import { AppUpToDateService } from './core/services/app-up-to-date/app-up-to-date.service';
import { NavigationService } from './core/services/navigation/navigation.service';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { SnackbarNotificationService } from './shared/notification/snackbar-notification.service';
import { SnackbarNotificationComponent } from './shared/notification/snackbar-notification/snackbar-notification.component';
import { EventsService, EventsServiceManager } from './util/events.service';
import { IconInitializerService } from './util/icons/icon-initializer.service';
import { MessageEvent } from './util/message-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, AfterViewInit {
  private subscriptions = new Subscription();
  private eventsService: EventsService<MessageEvent>;
  private errorsService: EventsService<Error>;

  constructor(
    iconInitializer: IconInitializerService,
    sidebarService: SidenavService,
    private router: Router,
    eventsServiceManager: EventsServiceManager,
    notificationService: SnackbarNotificationService,
    appUpdateService: AppUpToDateService,
    private renderer: Renderer2,
    private navigationService: NavigationService, // initializes history app-wide
    private segment: SegmentService,
    private userDataService: UserDataService
  ) {
    this.router.initialNavigation();
    this.initializeAnalytics();
    sidebarService.initializeNavigation(AppNavigationConfig);
    iconInitializer.initializeSvgIcons(AppIcons);

    this.eventsService = eventsServiceManager.get('content.messages', {
      message: '',
      status: 'success',
    });
    this.errorsService = eventsServiceManager.get(
      'content.errors',
      new Error()
    );

    this.subscriptions.add(
      this.errorsService.subscribe((error) => {
        notificationService.show({ message: error.message, status: 'error' });
      })
    );
    this.subscriptions.add(
      this.eventsService.subscribe((event) => {
        notificationService.show(
          {
            message: event.message,
            status: event.status,
          },
          event.snackBarConfig
        );
      })
    );
    this.subscriptions.add(
      appUpdateService.isUpToDate.subscribe((isUpToDate) => {
        if (!isUpToDate) {
          const a: MatSnackBarRef<SnackbarNotificationComponent> =
            notificationService.show(
              {
                message: 'Refresh now to get the latest version of the app.',
                status: 'info',
                actionIcon: 'refresh',
              },
              {
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
              }
            );

          let dismissedWithoutAction = true;
          a.onAction().subscribe(() => {
            dismissedWithoutAction = false;
            window.location.reload();
          });

          // since the toast can get dismissed without click the refresh action
          // we want the toast to return again to remind them to refresh
          // if it is dismissed without any action
          a.afterDismissed().subscribe(() => {
            if (dismissedWithoutAction) {
              appUpdateService.restartPolling();
            }
          });
        }
      })
    );
  }

  ngAfterViewInit(): void {
    const loader = this.renderer.selectRootElement('#loading');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Initialize segment data so every subsequent event has user id and email traits
   * Also fire page load event + subsequent routing update page events.
   */
  initializeAnalytics() {
    this.segment.load(environment.segment_write_key, {
      disable: environment.production === false,
    });
    this.subscriptions.add(
      this.userDataService.userSubject.subscribe((user) => {
        if (!user) {
          return;
        }

        const { id, email, name, type } = user;
        this.segment.identify(
          id,
          {
            email,
            name,
            type,
          },
          {}
        );

        this.segment.page(location.href);
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.segment.page(event.url);
          }
        });
      })
    );
  }
}
