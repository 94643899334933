import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialCommonModule } from '../material/material-common.module';
import { MaterialModule } from '../material/material.module';
import { SharedPipeModule } from '../pipes/shared-pipe.module';
import { AuthorizedGuardComponent } from './authorized-guard/authorized-guard.component';
import { BatteryStatusComponent } from './battery-status/battery-status.component';
import { ChipComponent } from './chip/chip.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { HeartbeatStatusComponent } from './heartbeat-status/heartbeat-status.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { RelevanceIndicatorDirective } from './indicator/relevance-indicator.directive';
import { LoadingGuardComponent } from './loading-guard/loading-guard.component';
import { LocationLinkComponent } from './location-link/location-link.component';
import { PrivilegedLinkComponent } from './privileged-link/privileged-link.component';
import { RelevanceStatusChipComponent } from './relevance-status-chip/relevance-status-chip.component';
import { SidebarToolbarComponent } from './sidebar-toolbar/sidebar-toolbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MaterialCommonModule,
    RouterModule,
    SharedPipeModule,
  ],
  declarations: [
    AuthorizedGuardComponent,
    BatteryStatusComponent,
    LoadingGuardComponent,
    HeartbeatStatusComponent,
    PrivilegedLinkComponent,
    IndicatorComponent,
    RelevanceIndicatorDirective,
    ChipComponent,
    SidebarComponent,
    RelevanceStatusChipComponent,
    LocationLinkComponent,
    ConfirmationDialogComponent,
    SidebarToolbarComponent,
  ],
  exports: [
    AuthorizedGuardComponent,
    BatteryStatusComponent,
    LoadingGuardComponent,
    HeartbeatStatusComponent,
    PrivilegedLinkComponent,
    IndicatorComponent,
    RelevanceIndicatorDirective,
    ChipComponent,
    SidebarComponent,
    RelevanceStatusChipComponent,
    LocationLinkComponent,
    ConfirmationDialogComponent,
    SidebarToolbarComponent,
  ],
})
export class SharedComponentModule {}
