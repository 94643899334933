<div class="snackbar-container">

  <span class="snackbar-detail">
    <mat-icon class="snackbar-icon">{{ iconMap[data.status] }}</mat-icon>
    <span class="snackbar-text">{{ data.message }}</span>
  </span>

  <span class="snackbar-action">
    <button title="{{data.actionTitle}}" mat-icon-button (click)="actionButtonClick()"><mat-icon>{{ data.actionIcon }}</mat-icon></button>
  </span>

  <span class="timeout-indicator" *ngIf="!!data.duration" [ngStyle]="{'animation-duration': data.duration + 'ms' }"></span>
  
</div>
