<div class="table-wrapper">
  <div class="table-actions">
    <button
      mat-raised-button
      color="primary"
      class="kh-button-sm"
      [disabled]="noneChecked"
      (click)="clickConfirmLocation()"
    >
      Confirm Location
    </button>
    <button
      mat-raised-button
      color="primary"
      class="kh-button-sm"
      [disabled]="noneChecked"
      (click)="clickIgnoreLocation()"
    >
      Ignore
    </button>
  </div>
  <app-loading-guard [loading]="entityList.loading">
    <mat-table
      class="kahi-table"
      id="alert-locations-table"
      [dataSource]="entityList.entities"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a
            *ngIf="isUnconfirmed(item); else details"
            [routerLink]="'/map/locations/unconfirmed/' + item.id"
            >{{ item.name }}</a
          >
          <ng-template #details>
            <app-location-link [location]="item">
              {{ item.name }}
            </app-location-link>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="select-column">
          <mat-checkbox
            (change)="selectAll($event.checked)"
            [(ngModel)]="allChecked"
            class="kh-invert-checkbox"
          ></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let item" class="select-column">
          <div>
            <mat-checkbox
              [(ngModel)]="rowSelections[item.id]"
              (click)="checkboxClick($event)"
              (change)="update()"
              class="kh-invert-checkbox"
            ></mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span>{{ item.address }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <div class="actions">
            <button
              mat-flat-button
              color="accent"
              (click)="confirmLocation(item)"
            >
              Confirm
            </button>
            <button
              mat-flat-button
              (click)="ignoreLocation(item)"
              color="primary"
            >
              Ignore
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="heartbeat_timestamp">
        <mat-header-cell *matHeaderCellDef>Last Seen At</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span *ngIf="item.tracking">{{ item.tracking.synchronized_at }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        class="header-row"
        *matHeaderRowDef="displayedColumns"
      ></mat-header-row>
      <mat-row
        class="row"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </app-loading-guard>
  <mat-paginator
    [length]="entityList.total"
    [pageIndex]="entityList.page - 1"
    [pageSize]="entityList.perPage"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageEvent($event)"
  ></mat-paginator>
</div>
