import { Entity } from './index';
import { maybe, object, string } from 'decoders';
import { Decodify, isodate } from './decoders';

export interface LocationVisitSummary extends Entity {
  started_at: Date;
  ended_at: Date;
  label?: string;
}

export const locationVisitSummaryDecoder = object<
  Decodify<LocationVisitSummary>
>({
  id: string,
  started_at: isodate,
  ended_at: isodate,
  label: maybe(string),
});
