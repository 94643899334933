import { hardcoded, maybe, nullable, number, object, string } from 'decoders';
import { Decodify, isodate } from './decoders';
import { FixedLocation, fixedLocationDecoder } from './fixed-location';
import { HeartbeatStatus, heartbeatStatusDecoder } from './heartbeat-status';
import {
  Coordinates,
  Location,
  LocationTracking,
  coordinateDecoder,
  locationObject,
  locationTrackingDecoderObject,
} from './location';

export interface MobileLocationTracking extends LocationTracking {
  heartbeat_status?: HeartbeatStatus;
  heartbeat_timestamp?: Date;
  coordinates?: Coordinates;
  time_zone?: string | null;
  asset_count?: number;
}

export const mobileLocationTrackingDecoder = object<
  Decodify<MobileLocationTracking>
>({
  ...locationTrackingDecoderObject,
  heartbeat_status: maybe(heartbeatStatusDecoder),
  heartbeat_timestamp: maybe(isodate),
  coordinates: maybe(coordinateDecoder),
  time_zone: maybe(nullable(string)),
  asset_count: number,
});

export interface MobileLocation extends Location {
  type: 'mobile';
  tracking?: MobileLocationTracking;
  license_plate?: string;
  vin?: string;
  home_location_id?: string;
  home_location?: FixedLocation;
}

export const mobileLocationDecoder = object<Decodify<MobileLocation>>({
  ...locationObject,
  type: hardcoded('mobile'),
  tracking: maybe(mobileLocationTrackingDecoder),
  license_plate: maybe(string),
  vin: maybe(string),
  home_location: maybe(fixedLocationDecoder),
});
