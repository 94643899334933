import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ApiKeyCreateComponent } from './api-key-create.component';
import { ApiKeysComponent } from './api-keys.component';
import { RouterModule } from '@angular/router';
import { ApiKeyDetailsComponent } from './api-key-details/api-key-details.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [
    ApiKeyCreateComponent,
    ApiKeysComponent,
    ApiKeyDetailsComponent,
  ],
  exports: [ApiKeyCreateComponent, ApiKeysComponent],
})
export class ApiKeysModule {}
