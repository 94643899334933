import { Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Decoder } from 'decoders';

export class EventsService<T> {
  private subject = new Subject<T>();

  pushEvent(event: T) {
    this.subject.next(event);
  }

  getObservable(): Observable<T> {
    return this.subject.asObservable();
  }

  subscribe(callback: (event: T) => void): Subscription {
    return this.subject.subscribe({
      next: callback
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventsServiceManager {
  private services: { [key: string]: EventsService<any> } = {};

  get<T>(name: string, arg: Decoder<T> | T): EventsService<T> {
    if (!this.services[name]) {
      this.services[name] = new EventsService<T>();
    }

    return this.services[name];
  }
}
