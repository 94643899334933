import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { StateService } from '../../core/services/state/state.service';
import { LocationArtifact, MapArtifacts } from '../../models/map-artifacts';
import {
  ActiveRecentInactive,
  RelevanceStatus,
} from '../../models/relevance-status';
import {
  MapArtifactsGeofence,
  MapArtifactsService,
} from './map-artifacts.service';

interface State extends ArtifactParams {
  loading: boolean;
  artifacts: MapArtifacts;
}

interface ArtifactParams {
  visibility: ArtifactVisibility;
  geofence: MapArtifactsGeofence;
  selectedId: string;
}

const initialState = {
  loading: true,
  artifacts: null,
  visibility: {
    orphanedAssets: true,
    remote: ActiveRecentInactive,
    fixed: ActiveRecentInactive,
    mobile: ActiveRecentInactive,
    roving: ActiveRecentInactive,
  },
  selectedId: null,
  geofence: null,
};

interface ArtifactVisibility {
  orphanedAssets: boolean;
  remote: readonly RelevanceStatus[];
  fixed: readonly RelevanceStatus[];
  mobile: readonly RelevanceStatus[];
  roving: readonly RelevanceStatus[];
}

@Injectable()
export class MapArtifactsStore extends StateService<State> {
  loading$ = this.select((s) => s.loading);

  filteredArtifacts$ = this.select((s) => {
    const locationFilterFn = (l: LocationArtifact) =>
      l.tracking?.asset_status
        ? s.visibility[l.type].includes(l.tracking.asset_status)
        : false;
    return s.artifacts
      ? {
          ...s.artifacts,
          orphaned_assets: s.visibility.orphanedAssets
            ? s.artifacts.orphaned_assets
            : [],
          fixed_locations: s.artifacts.fixed_locations.filter(locationFilterFn),
          remote_locations:
            s.artifacts.remote_locations.filter(locationFilterFn),
          mobile_locations:
            s.artifacts.mobile_locations.filter(locationFilterFn),
          roving_locations:
            s.artifacts.roving_locations.filter(locationFilterFn),
        }
      : null;
  });

  selected$ = this.select(this.findSelectedArtifact);

  private loadArtifacts$ = new Subject();

  constructor(private artifactsService: MapArtifactsService) {
    super(initialState);

    this.addSubscription(
      this.loadArtifacts$
        .pipe(
          tap(() => this.patchState({ loading: true })),
          switchMap(() =>
            this.artifactsService
              .getEntity({
                relevance_statuses: this.state.visibility.remote,
                geofence: this.state.geofence,
              })
              .pipe(
                tap((artifacts) =>
                  this.patchState({ loading: false, artifacts })
                )
              )
          )
        )
        .subscribe()
    );
  }

  patchParams(params: Partial<ArtifactParams>) {
    this.patchState({
      ...params,
    });
    this.loadArtifacts$.next();
  }

  setSelectedId(selectedId: string) {
    this.patchState({
      selectedId,
    });
  }

  private findSelectedArtifact(state?: State): LocationArtifact | null {
    if (!state?.artifacts) return null;
    const allLocations = [
      ...state.artifacts.fixed_locations,
      ...state.artifacts.remote_locations,
      ...state.artifacts.mobile_locations,
      ...state.artifacts.roving_locations,
    ];
    return (
      allLocations.find((location) => location.id === state.selectedId) || null
    );
  }
}
