import { SafeHtml } from '@angular/platform-browser';
import { maybe, object, string } from 'decoders';
import { Entity } from '.';
import { Decodify, isodate, safehtml } from './decoders';

export interface Agreement extends Entity {
  executed_at: Date | null;
  html: SafeHtml;
}

export const agreementDecoder = object<Decodify<Agreement>>({
  id: string,
  executed_at: maybe(isodate),
  html: safehtml,
});
