<div class="header" *ngIf="alerts$ | async as alerts">
  <button
    class="alert-button"
    mat-flat-button
    *ngFor="let alert of alerts"
    [routerLink]="alert.route"
    routerLinkActive="selected-route"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <mat-icon fab color="warn" class="icon" [ngClass]="alert.color">{{
      alert.icon
    }}</mat-icon>
    {{ alert.message }} ({{ alert.value }})
  </button>
</div>
<router-outlet></router-outlet>
