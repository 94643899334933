import { Directive, OnDestroy, OnInit } from '@angular/core';
import { EntityService } from '../entity.service';
import {
  EntityListComponent,
  EntityListPrefs,
  EntityListState,
} from './entity-list.base';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DeletionConfirmer } from '../confirmation';
import { Entity } from '../../models';
import { State, StoreService, storeServiceProvider } from '../store.service';

export interface FilteredEntityListState<F> extends EntityListState {
  filter: F;
}
export interface FilteredEntityListPrefs extends EntityListPrefs {
  filterExpanded: boolean;
}
export type FilteredEntityListStore<F> = State<
  FilteredEntityListState<F>,
  FilteredEntityListPrefs
>;

export type DefaultFilteredEntityListStore<F> = StoreService<
  FilteredEntityListState<F>,
  FilteredEntityListPrefs
>;
export const defaultFilteredEntityListStoreProvider = <F>(namespace: string) =>
  storeServiceProvider<FilteredEntityListState<F>, FilteredEntityListPrefs>(
    namespace
  );

@Directive()
export abstract class FilteredEntityListComponent<
  E extends Entity,
  F,
  S extends FilteredEntityListState<F> = FilteredEntityListState<F>,
  P extends FilteredEntityListPrefs = FilteredEntityListPrefs
> extends EntityListComponent<E, F, S, P> {
  filter: F;
  filterExpanded: boolean = false;

  protected readonly filterDebounceTime = 1000;
  filterChanges: Subject<void> = new Subject<void>();

  constructor(
    entityService: EntityService<E, F>,
    store: StoreService<S, P>,
    filter: F,
    deletionConfirmer?: DeletionConfirmer<E>
  ) {
    super(entityService, store, deletionConfirmer);
    this.filter = filter;
  }

  getState(input?: Partial<State<S, P>>): State<S, P>;
  getState(
    input?: Partial<FilteredEntityListStore<F>>
  ): FilteredEntityListStore<F> {
    return this.getEntityListState(input);
  }

  protected getEntityListState(
    input?: Partial<FilteredEntityListStore<F>>
  ): FilteredEntityListStore<F> {
    input = input || {};
    const filterExpanded =
      typeof input.prefs?.filterExpanded !== 'undefined'
        ? input.prefs.filterExpanded
        : this.filterExpanded;

    const state = {
      filter: input.state?.filter || this.filter,
      page: input.state?.page || this.entityList.page,
    };
    const prefs = {
      perPage: input.prefs?.perPage || this.entityList.perPage,
      filterExpanded,
    };
    return {
      state,
      prefs,
    };
  }

  protected getEntityListFilter(): F {
    return this.filter;
  }

  protected loadStateFromStore({ state, prefs }: State<S, P>) {
    super.loadStateFromStore({ state, prefs });
    if (state?.filter) this.filter = state.filter;
    if (prefs?.filterExpanded) this.filterExpanded = prefs.filterExpanded;
  }

  onFilterExpansionChange(expanded: boolean) {
    this.filterExpanded = expanded;
    const state = this.getState();
    this.store.pushState(state, this.getStoreEntityId());
  }

  onFilterChange(filter?: F, updateStore: boolean = true) {
    if (filter) this.filter = filter;
    this.entityList.page = 1;
    if (updateStore) {
      const state = this.getState();
      this.store.pushState(state, this.getStoreEntityId());
    }
    this.loadEntities();
  }
}
