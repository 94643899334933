import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ConfirmLocationDialogComponent } from 'src/app/alerts/components/location-alerts-list/confirm-location-dialog/confirm-location-dialog.component';
import { EntityList, Identified } from 'src/app/models';
import { Location } from 'src/app/models/location';
import { RelevanceStatus } from 'src/app/models/relevance-status';
import { actionsTableMixin } from 'src/app/shared/mixins/actions-table.mixin';
import { ActionConfirmer } from 'src/app/util/confirmation';
import { ActionConfirmationService } from 'src/app/util/confirmation/confirmation.service';
import { MessageEventService } from 'src/app/util/message-event.service';
import { AlertsLocationService } from '../../services/alerts-location.service';

// create a mixin for this component to add an actions menu
const AssetsListMixin = actionsTableMixin(class Parent {});

@Component({
  selector: 'app-location-alerts-list',
  templateUrl: './location-alerts-list.component.html',
  styleUrls: ['./location-alerts-list.component.scss'],
})
export class LocationAlertsListComponent
  extends AssetsListMixin
  implements OnDestroy, OnChanges
{
  @Input() entityList: EntityList<Location>;
  @Input() tableId: string;
  @Input() displayedColumns: string[];
  @Output() pageEvent = new EventEmitter<any>();
  @Output() reload = new EventEmitter<void>();

  private subscription = new Subscription();

  private actionConfirmer: ActionConfirmer;

  rowSelections: { [key: string]: boolean };
  allChecked = false;
  noneChecked = true;

  dialogRef;

  constructor(
    private locationsService: AlertsLocationService,
    private messageService: MessageEventService,
    private dialog: MatDialog,
    private router: Router
  ) {
    super();
    this.actionConfirmer = new ActionConfirmationService(dialog);
  }

  onPageEvent(event) {
    this.pageEvent.emit(event);
  }

  onUnconfirmedActionComplete() {
    this.reload.emit();
  }

  // confirmLocation confirms location then reloads the alerts list
  confirmLocation(location: Location) {
    this.subscription.add(
      this.actionConfirmer.confirmAction(
        `Are you sure you want to confirm ${location.name}?`,
        () => {
          this.subscription.add(
            this.locationsService.confirmLocation(location).subscribe(() => {
              this.messageService.pushEvent({
                message: `Location ${location.name} confirmed`,
                status: 'success',
              });
              this.reload.next();
            })
          );
        }
      )
    );
  }

  // ignoreLocation ignores the location then reloads the alert list
  ignoreLocation(location: Location) {
    this.subscription.add(
      this.actionConfirmer.confirmAction(
        `Are you sure you want to ignore ${location.name}?`,
        () => {
          this.subscription.add(
            this.locationsService.ignoreLocation(location).subscribe(() => {
              this.messageService.pushEvent({
                message: `Location ${location.name} ignored`,
                status: 'success',
              });
              this.reload.next();
            })
          );
        }
      )
    );
  }

  isUnconfirmed(location: Location): boolean {
    return location.tracking?.asset_status === RelevanceStatus.Unconfirmed;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If alerts list is empty redirct to the alerts centre page
    if (this.entityList.loading === false && this.entityList.total === 0) {
      this.router.navigateByUrl('/notifications');
    }

    if (changes['entityList']) {
      this.rowSelections = this.entityList.entities.reduce((prev, curr) => {
        return { ...prev, [curr.id]: false };
      }, {});
      this.updateRowsFromEntityList(
        this.entityList.entities as Identified<Location>[]
      );
      this.update();
    }
  }

  // checkboxClick stops the event from propagating
  checkboxClick(event: PointerEvent) {
    event.stopPropagation();
  }

  update() {
    const values = Object.values(this.rowSelections);
    this.noneChecked = values.length === 0 || !values.some(Boolean);
    this.allChecked = values.length > 0 && values.every(Boolean);
  }

  selectAll(isChecked: boolean) {
    Object.keys(this.rowSelections).forEach((key) => {
      this.rowSelections[key] = isChecked;
    });
    this.update();
  }

  getSelectedLocations() {
    const selectedLocationsIds = [];
    const keys = Object.keys(this.rowSelections);
    keys.forEach((key) => {
      if (this.rowSelections[key]) {
        selectedLocationsIds.push(key);
      }
    });

    const selectedLocations = this.entityList.entities.filter((entity) =>
      selectedLocationsIds.includes(entity.id)
    );

    return selectedLocations;
  }

  clickConfirmLocation() {
    this.openConfirmLocationDialog(true);
  }
  clickIgnoreLocation() {
    this.openConfirmLocationDialog(false);
  }

  openConfirmLocationDialog(confirm: boolean) {
    this.dialogRef = this.dialog.open(ConfirmLocationDialogComponent, {
      data: {
        selectedLocations: this.getSelectedLocations(),
        confirm,
      },
    });
  }
}
