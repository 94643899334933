import { Component, Input } from '@angular/core';
import { HeartbeatStatus } from '../../../models/heartbeat-status';
import { getHeartbeatStatusColor } from '../../../core/ui/theme/heartbeat-status-colors';

@Component({
  selector: 'app-heartbeat-status',
  templateUrl: './heartbeat-status.component.html',
  styleUrls: ['./heartbeat-status.component.scss'],
})
export class HeartbeatStatusComponent {
  readonly getColor = getHeartbeatStatusColor;

  @Input() status: HeartbeatStatus;
}
