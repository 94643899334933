import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarNotificationComponent } from './snackbar-notification/snackbar-notification.component';
import { SnackbarNotificationService } from './snackbar-notification.service';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [
    SnackbarNotificationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  providers: [
    SnackbarNotificationService,
  ],
})
export class NotificationModule { }
