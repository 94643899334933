import { Injectable } from '@angular/core';
import { User, userDecoder, userProfileUpdateEncoder } from '../models/user';
import { HttpServiceBuilder, HttpServiceConfig } from '../util/http.service';
import { SingletonEntityService } from '../util/singleton-entity.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends SingletonEntityService<User> {
  constructor(httpServiceBuilder: HttpServiceBuilder<User>) {
    super(httpServiceBuilder, 'auth/settings', userDecoder);
  }

  getUpdateConfig(entity: User): HttpServiceConfig<User> {
    return {
      ...super.getUpdateConfig(entity),
      encoder: userProfileUpdateEncoder,
    };
  }
}
