import { Directive, OnDestroy, OnInit, inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { Subject, Subscription, TeardownLogic, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { Entity, EntityList } from '../../models';
import { timeSinceInWords } from '../../shared/pipes/time-since-in-words.pipe';
import { DeletionConfirmer } from '../confirmation';
import { EntityService } from '../entity.service';
import { EventsService, EventsServiceManager } from '../events.service';
import { UnauthorizedError } from '../http.service';
import { MessageEvent } from '../message-event.service';
import { State, StoreService, storeServiceProvider } from '../store.service';

export interface EntityListState {
  page: number;
}

export interface EntityListPrefs {
  perPage: number;
}

export type EntityListStore = State<EntityListState, EntityListPrefs>;

export type DefaultEntityListStore = StoreService<
  EntityListState,
  EntityListPrefs
>;
export const defaultEntityListStoreProvider = (namespace: string) =>
  storeServiceProvider<EntityListState, EntityListPrefs>(namespace);

@Directive()
export abstract class EntityListComponent<
  E extends Entity,
  F,
  S extends EntityListState = EntityListState,
  P extends EntityListPrefs = EntityListPrefs
> implements OnInit, OnDestroy
{
  private subscription: Subscription = new Subscription();
  protected eventsService: EventsService<MessageEvent>;
  protected eventsServiceManager = inject(EventsServiceManager);
  private titleService = inject(Title);
  private refreshToken$ = new Subject<void>();

  protected pageTitle: string;
  private lastRequestTimestamp = 0;
  entityList: EntityList<E> = {
    entities: [],
    page: 1,
    perPage: 20,
    total: 0,
    loading: true,
  };
  unauthorized = false;
  unauthorized_delete: { [key: string]: boolean } = {};

  constructor(
    protected entityService: EntityService<E, F>,
    protected store: StoreService<S, P>,
    private deletionConfirmer?: DeletionConfirmer<E>
  ) {
    this.eventsService = this.eventsServiceManager.get<MessageEvent>(
      'content.messages',
      {
        message: '',
        status: 'success',
      }
    );
  }

  getStoreEntityId(): string | undefined {
    return undefined;
  }

  addSubscription(teardown: TeardownLogic): Subscription {
    return this.subscription.add(teardown);
  }

  formatAmount(amount: number, currency: string, decimals: number = 2): string {
    // for now we assume that amount from stripe is in a currency that is 100x the base amount
    const value = amount / 100;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    return formatter.format(value);
  }

  getState(input?: Partial<State<S, P>>): State<S, P>;
  getState(input?: Partial<EntityListStore>): EntityListStore {
    return this.getEntityListState(input);
  }

  protected getEntityListState(
    input?: Partial<EntityListStore>
  ): EntityListStore {
    input = input || {};
    const state = { page: input.state?.page || this.entityList.page };
    const prefs = { perPage: input.prefs?.perPage || this.entityList.perPage };
    return {
      state,
      prefs,
    };
  }

  getTimeSinceInWords(seen_at: Date) {
    return timeSinceInWords(seen_at);
  }

  confirmDeleteEntity(entity: E) {
    if (!this.deletionConfirmer) {
      return;
    }

    this.deletionConfirmer.confirmDeleteEntity(
      entity,
      this.subscription,
      this.unauthorized_delete,
      () => this.loadEntities()
    );
  }

  protected getEntityListFilter(): F;
  protected getEntityListFilter(): {} {
    return {};
  }

  protected handleEntityListLoaded(list: EntityList<E>) {
    // no-op
  }

  loadEntities() {
    this.refreshToken$.next();
  }

  private setupEntityList() {
    let id;
    let timer;

    this.refreshToken$
      .asObservable()
      .pipe(
        debounceTime(500),
        tap(() => {
          id = new Date().valueOf();
          this.lastRequestTimestamp = id;
          timer = setTimeout(() => (this.entityList.loading = true), 500);
        }),
        switchMap(() => {
          return this.entityService
            .getEntityList(this.getEntityListFilter(), {
              page: this.entityList.page,
              perPage: this.entityList.perPage,
            })
            .pipe(
              tap((list: EntityList<E>) => {
                this.handleEntityListLoaded(list);
              }),
              catchError((error: Error) => {
                if (error instanceof UnauthorizedError) {
                  this.unauthorized = true;
                }
                return of(this.entityList);
              })
            );
        })
      )
      .subscribe((entities) => {
        // always clear the timer because it's local
        clearTimeout(timer);

        if (id < this.lastRequestTimestamp) {
          // skip these results
          return;
        }

        this.entityList = entities;
        this.entityList.loading = false;
      });
  }

  abstract getTableId(): string;

  onPageEvent(event: PageEvent) {
    const table = document.getElementById(this.getTableId());
    if (table) {
      const bounds = table.getBoundingClientRect();
      if (bounds.top < 0) {
        table.scrollIntoView();
      }
    }
    this.entityList.page = event.pageIndex + 1;
    this.entityList.perPage = event.pageSize;

    let { state, prefs } = this.getState();
    if (state) state.page = this.entityList.page;
    if (prefs) prefs.perPage = this.entityList.perPage;

    this.store.pushState(
      this.getState({ state, prefs }),
      this.getStoreEntityId()
    );

    this.loadEntities();
  }

  protected loadStateFromStore({ state, prefs }: State<S, P>) {
    if (state?.page) this.entityList.page = state.page;
    if (prefs?.perPage) this.entityList.perPage = prefs.perPage;
  }

  ngOnInit() {
    this.loadStateFromStore(
      this.store.getState(this.getState(), this.getStoreEntityId())
    );

    if (this.pageTitle) {
      this.titleService.setTitle(`${this.pageTitle} | Kahi`);
    }

    this.setupEntityList();
    this.loadEntities();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
