import { Injectable } from '@angular/core';
import { HttpServiceBuilder, HttpService } from '../util/http.service';
import { organizationDecoder, Organization } from '../models/organization';
import { SingletonEntityService } from '../util/singleton-entity.service';
import { Observable } from 'rxjs';
import { object } from 'decoders';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends SingletonEntityService<Organization> {
  private webhookSigningKeyHttpService: HttpService<{}, Organization>;

  constructor(
    orgHttpServiceBuilder: HttpServiceBuilder<Organization>,
    wskHttpServiceBuilder: HttpServiceBuilder<{}, Organization>
  ) {
    super(orgHttpServiceBuilder, 'organization', organizationDecoder);

    this.webhookSigningKeyHttpService = wskHttpServiceBuilder.build({
      route: 'organization/webhook_signing_key',
      decoder: organizationDecoder,
      encoder: (entity: {}) => entity,
    });
  }

  clearWebhookSigningKey(): Observable<boolean> {
    return this.webhookSigningKeyHttpService.deleteSingleton();
  }

  regenerateWebhookSigningKey(): Observable<Organization> {
    return this.webhookSigningKeyHttpService.create({});
  }
}
