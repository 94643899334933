import { dict, either, number, string } from 'decoders';

export type MetadataContext = 'Asset' | 'Location';

export type MetadataValue = string | number;

export type MetadataKey = string;

export type Metadata = Record<MetadataKey, MetadataValue>;

export const metadataDecoder = dict(either(number, string));

export const metadataKeyUpdateDecoder = either(number, string).transform(
  (value) => ({ value })
);

export const metadataKeyUpdateEncoder = ({ value }: Metadata) => ({ value });
