import {
  array,
  constant,
  either,
  maybe,
  nullable,
  number,
  object,
  optional,
  string,
} from 'decoders';
import { Entity, numericStringDecoder } from '.';
import { Decodify, isodate } from './decoders';
import { Image, imageDecoder } from './image';
import {
  LocationVisitSummary,
  locationVisitSummaryDecoder,
} from './location-visit';
import { Metadata, metadataDecoder } from './metadata';
import { RelevanceStatus, relevanceStatusDecoder } from './relevance-status';

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export type LocationType = 'fixed' | 'mobile' | 'remote' | 'roving';

export interface LocationTracking {
  id?: string;
  synchronized_at?: Date;
  asset_geofence_radius?: number;
  asset_status?: RelevanceStatus;
  coordinates?: Coordinates;
}

export interface Location extends Entity {
  type?: LocationType;
  name: string;
  address?: string;
  coordinates?: Coordinates;
  time_zone?: string | null;
  discarded_at?: Date;
  images?: Image[];
  vin?: string;
  asset_count?: number;
  metadata?: Metadata;
  created_at?: Date;
  latest_activity_session?: LocationVisitSummary;
  tracking?: LocationTracking;
}

export const coordinateDecoder = object({
  latitude: numericStringDecoder,
  longitude: numericStringDecoder,
});

export const locationTypeDecoder = either(
  constant('fixed' as LocationType),
  constant('mobile' as LocationType),
  constant('remote' as LocationType),
  constant('roving' as LocationType)
);

export const locationTrackingDecoderObject = {
  id: maybe(string),
  synchronized_at: maybe(isodate),
  asset_geofence_radius: maybe(number),
  asset_status: maybe(relevanceStatusDecoder),
  coordinates: maybe(coordinateDecoder),
};

export const locationTrackingDecoder = object<Decodify<LocationTracking>>(
  locationTrackingDecoderObject
);

export const locationObject = {
  id: string,
  name: string,
  type: optional(locationTypeDecoder),
  address: maybe(string),
  coordinates: maybe(coordinateDecoder),
  time_zone: maybe(nullable(string)),
  discarded_at: maybe(isodate),
  images: maybe(array(imageDecoder)),
  vin: maybe(string),
  license_plate: maybe(string),
  asset_count: maybe(number),
  metadata: maybe(metadataDecoder),
  created_at: maybe(isodate),
  latest_activity_session: maybe(locationVisitSummaryDecoder),
  tracking: maybe(locationTrackingDecoder),
};

export const locationDecoder = object<Decodify<Location>>(locationObject);
