import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FixedLocation } from 'src/app/models/fixed-location';
import { RovingLocation } from 'src/app/models/roving-location';
import { StateService } from '../../util/legacy-state.service';
import { UserDataService } from '../../util/user-data.service';

const namespace = 'location-context.service';

@Injectable({
  providedIn: 'root',
})
export class LocationContextService implements OnDestroy {
  private locationSubject = new BehaviorSubject<FixedLocation | RovingLocation>(
    null
  );
  location$: Observable<FixedLocation | RovingLocation> =
    this.locationSubject.asObservable();
  locationId$: Observable<string> = this.location$.pipe(
    map((location) => location?.id)
  );
  locations = this.userService.user?.locations || [];

  private subscriptions = new Subscription();

  constructor(
    private stateService: StateService,
    private userService: UserDataService
  ) {
    this.subscriptions.add(
      this.stateService.getObservable(namespace, {}).subscribe((state) => {
        const location = state['locationId']
          ? this.locations.find((loc) => loc.id === state['locationId'])
          : null;

        this.locationSubject.next(location);

        // Clear the state if set to an invalid location ID
        if (state['locationId'] && !location) {
          this.stateService.pushState(namespace, { locationId: null });
        }
      })
    );
  }

  setLocation(locationId: string) {
    this.stateService.pushState(namespace, { locationId });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
