import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormattedAlert } from 'src/app/map/overlays/map-page-overlay/formatted-alert';

@Component({
  selector: 'app-alert-notification-panel',
  templateUrl: './alert-notification-panel.component.html',
  styleUrls: ['./alert-notification-panel.component.scss'],
})
export class AlertNotificationPanelComponent {
  @Output() closeEvent = new EventEmitter<any>();
  @Input() alerts: FormattedAlert[];

  constructor(private router: Router) {}

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
    this.closeEvent.emit();
  }
}
