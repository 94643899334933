import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
} from '../navigation/navigation-item';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavService } from '../sidenav/sidenav.service';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { LayoutService } from '../layout.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserDataService } from 'src/app/util/user-data.service';
import { Nomenclature } from 'src/app/models/organization';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
})
export class SidenavItemComponent implements OnInit, OnDestroy {
  @Input() item: NavigationItem;
  @Input() level = 0;
  @Input() collapsed: boolean;

  isOpen: boolean;
  isActive: boolean;

  dialogCloseTimer?: ReturnType<typeof setTimeout>;

  nomenclature: Nomenclature = this.userDataService.nomenclature;

  showChildren$ = combineLatest([
    this.layoutService.sidenavCollapsedOpen$,
    this.layoutService.sidenavCollapsed$,
  ]).pipe(map(([collapsedOpen, collapsed]) => collapsedOpen || !collapsed));

  private subscriptions = new Subscription();

  isDropdown = (item: NavigationItem) => item.type === 'dropdown';

  constructor(
    private router: Router,
    private navService: SidenavService,
    private layoutService: LayoutService,
    private userDataService: UserDataService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter<NavigationEnd>((event) => event instanceof NavigationEnd),
          startWith({}),
          filter(() => this.isDropdown(this.item))
        )
        .subscribe(() => this.onRouteChange())
    );

    this.subscriptions.add(
      this.navService.openChange$
        .pipe(filter(() => this.isDropdown(this.item)))
        .subscribe((item) => this.onOpenChange(item))
    );
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navService.triggerOpenChange(this.item as NavigationDropdown);
  }

  onOpenChange(item: NavigationDropdown) {
    if (
      !this.hasActiveChilds(this.item as NavigationDropdown) &&
      this.item !== item
    ) {
      this.isOpen = false;
    }
  }

  onLinkClicked() {
    if (this.collapsed) {
      this.layoutService.collapseCloseSidenav();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navService.triggerOpenChange(this.item as NavigationDropdown);
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navService.triggerOpenChange(this.item as NavigationDropdown);
    }
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some((child) => {
      return this.isLinkActive(child as NavigationLink);
    });
  }

  isLinkActive(item: NavigationLink) {
    return this.router.isActive(item.route as string, false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openMenu(trigger: MatMenuTrigger) {
    clearTimeout(this.dialogCloseTimer);
    trigger.openMenu();
  }

  closeMenu(trigger: MatMenuTrigger) {
    // Mouse leaves the nav button before entering the menu, timeout prevents menu from closing in that short period
    this.dialogCloseTimer = setTimeout(() => {
      trigger.closeMenu();
    }, 100);
  }

  getLabel(item: NavigationLink): string {
    if (item.apiLabel) {
      const [key, count] = item.apiLabel.split(':');
      return this.nomenclature?.[count]?.[key] || item.label;
    }
    return item.label;
  }
}
