import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ImageGalleryOverlayComponent } from './image-gallery-overlay/image-gallery-overlay.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [ImageGalleryComponent, ImageGalleryOverlayComponent],
  exports: [ImageGalleryComponent],
})
export class ImageGalleryModule {}
