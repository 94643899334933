import { Entity } from '.';
import { string, object, maybe, integer } from 'decoders';
import { Plan, planDecoder } from './plan';
import { Decodify, isodate } from './decoders';

interface PaymentMethod extends Entity {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

const paymentMethodDecoder = object<Decodify<PaymentMethod>>({
  id: string,
  brand: string,
  exp_month: integer,
  exp_year: integer,
  last4: string,
});

export interface Subscription extends Entity {
  start_at: Date;
  ended_at?: Date;
  cancel_at?: Date;
  canceled_at?: Date;
  current_period_start_at: Date;
  current_period_end_at: Date;
  payment_method?: PaymentMethod;
  status: string;
  plan: Plan;
}

export const subscriptionDecoder = object<Decodify<Subscription>>({
  id: string,
  start_at: isodate,
  ended_at: maybe(isodate),
  cancel_at: maybe(isodate),
  canceled_at: maybe(isodate),
  current_period_start_at: isodate,
  current_period_end_at: isodate,
  payment_method: maybe(paymentMethodDecoder),
  status: string,
  plan: planDecoder,
});
