import { Decoder, regex } from 'decoders';
import { format, parse } from 'date-fns';

/**
 * Decoders and encoders for API date strings
 */

const dateStringFormat = 'yyyy-MM-dd';
const dateRegexp = /^[\d]{4}-[\d]{2}-[\d]{2}$/;

// Date string decoder
export const dateString: Decoder<Date> = regex(
  dateRegexp,
  'Must be formatted as yyyy-MM-dd'
)
  .transform<Date>((value) => {
    return parse(value, dateStringFormat, new Date());
  })
  .refine((date) => !isNaN(date.getTime()), 'Must be valid date value');

// Date string encoder
export const dateStringEncoder = (date?: Date): string | null =>
  date ? format(date, dateStringFormat) : null;
