import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeletionConfirmationService } from 'src/app/util/confirmation/confirmation.service';
import {
  DefaultFilteredEntityListStore,
  FilteredEntityListComponent,
  defaultFilteredEntityListStoreProvider,
} from 'src/app/util/entity-list/filtered-entity-list.base';
import { STORE_SERVICE_TOKEN } from 'src/app/util/store.service';
import { Webhook } from '../../../models/webhook';
import { EventsServiceManager } from '../../../util/events.service';
import {
  WebhooksFilter,
  WebhooksService,
} from '../../services/webhooks.service';

class WebhooksDeletionConfirmationService extends DeletionConfirmationService<Webhook> {
  getConfirmDeleteEntityMessage(webhook: Webhook): string {
    return `Are you sure you want to delete webhook ${webhook.name}?`;
  }

  getEntityDeletedMessage(webhook: Webhook): string {
    return `Webhook ${webhook.name} deleted`;
  }
}

@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.scss'],
  providers: [
    defaultFilteredEntityListStoreProvider<WebhooksFilter>(
      'webhooks.component'
    ),
  ],
})
export class WebhooksComponent
  extends FilteredEntityListComponent<Webhook, WebhooksFilter>
  implements OnInit, OnDestroy
{
  pageTitle: string = 'Webhooks';

  constructor(
    webhooksService: WebhooksService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultFilteredEntityListStore<WebhooksFilter>,
    eventsServiceManager: EventsServiceManager,
    dialog: MatDialog
  ) {
    super(
      webhooksService,
      store,
      { type: null },
      new WebhooksDeletionConfirmationService(
        webhooksService,
        dialog,
        eventsServiceManager
      )
    );
  }

  protected getEntityListFilter() {
    return {
      ...this.filter,
      type: null,
    };
  }

  clearFilter(event: MouseEvent) {
    this.filter.search = '';

    event.stopPropagation();

    this.onFilterChange();
  }

  getTableId(): string {
    return 'webhooks-table';
  }
}
