import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { OnboardingDataService } from '../onboarding-data.service';
import { SetupStatus } from '../../models/setup-status';

@Component({
  selector: 'app-onboarding-trigger',
  templateUrl: './onboarding-trigger.component.html',
  styleUrls: ['./onboarding-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, // required to override badge styles
  providers: [OnboardingDataService],
})
export class OnboardingTriggerComponent implements OnInit {
  setupStatus$ = this.onboardingDataService.setupStatus$;

  constructor(
    public onboardingDataService: OnboardingDataService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onboardingDataService.reload();
  }

  isOpen = false;

  shouldShowTrigger(setupStatus: SetupStatus) {
    return setupStatus && setupStatus.percentage_complete !== 100;
  }

  toggleOpen(event?: Event) {
    this.isOpen = !this.isOpen;
    event?.stopPropagation();
    this.cdRef.detectChanges();
  }
}
