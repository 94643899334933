import { Injectable } from '@angular/core';
import { HttpServiceBuilder, HttpServiceConfig } from '../../util/http.service';
import { Webhook, webhookDecoder, WebhookContext } from '../../models/webhook';
import { EntityService } from '../../util/entity.service';

type TypeSegment = {
  apiPath: string;
  routePath: string | string[];
};

export const typeSegmentMap: { [key in WebhookContext]: TypeSegment } = {
  Asset: { apiPath: 'assets', routePath: 'assets' },
  Beacon: { apiPath: 'beacons', routePath: 'beacons' },
  Gateway: { apiPath: 'gateways', routePath: 'gateways' },
  Location: { apiPath: 'locations', routePath: 'locations' },
  FixedLocation: {
    apiPath: 'fixed_locations',
    routePath: ['locations', 'warehouse'],
  },
  RemoteLocation: { apiPath: 'remote_locations', routePath: 'locations' },
  MobileLocation: {
    apiPath: 'mobile_locations',
    routePath: ['locations', 'vehicle'],
  },
  Organization: { apiPath: 'organization', routePath: 'organization' },
  MobileDevice: { apiPath: 'mobile_devices', routePath: '' },
};

export interface WebhooksFilter {
  type: WebhookContext;
  entityId?: string;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WebhooksService extends EntityService<Webhook, WebhooksFilter> {
  constructor(httpServiceBuilder: HttpServiceBuilder<Webhook>) {
    super(httpServiceBuilder, 'webhooks', webhookDecoder);
  }

  getCreateConfig(webhook: Webhook): HttpServiceConfig<Webhook> {
    return {
      ...super.getCreateConfig(webhook),
      route: this.getApiRouteFromType(webhook.type, webhook.entityId),
      encoder: this.getEncoder(),
    };
  }
  getUpdateConfig(webhook: Webhook): HttpServiceConfig<Webhook> {
    return {
      ...super.getUpdateConfig(webhook),
      encoder: this.getEncoder(),
    };
  }
  getEncoder() {
    return (entity: Webhook) => ({
      name: entity.name,
      url: entity.url,
      enabled: entity.enabled,
      event_types: entity.eventTypes,
    });
  }

  getEntityListConfig(filter: WebhooksFilter): HttpServiceConfig<Webhook> {
    return {
      ...super.getEntityListConfig(filter),
      route: this.getApiRouteFromType(filter.type, filter.entityId),
    };
  }

  getEntityListQuery(filter: WebhooksFilter): {} {
    const query: any = {};
    if (filter.search) {
      query['search'] = filter.search;
    }

    return query;
  }

  getContextRouterLink(item: Webhook): string[] {
    if (item.type === 'Organization') {
      return ['/organization'];
    }
    if (item.type === 'Beacon') {
      return ['/'];
    }
    return [typeSegmentMap[item.type].routePath, item.contextId].flat();
  }

  private getApiRouteFromType(type: WebhookContext, entityId?: string): string {
    if (type === 'Organization') {
      return `organization/webhooks`;
    }
    if (type === null || type === 'Beacon' || type === 'Gateway') {
      return 'webhooks';
    }
    return `${typeSegmentMap[type].apiPath}/${entityId}/webhooks`;
  }
}
