import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionState } from 'src/app/models/organization';

@Injectable({
  providedIn: 'root',
})
export class IsSubscribedService {
  private isSubscribed = new BehaviorSubject<boolean>(false);
  isSubscribed$ = this.isSubscribed.asObservable();

  setIsSubscribed(subscriptionState: SubscriptionState) {
    if (subscriptionState === SubscriptionState.PastDue) {
      this.isSubscribed.next(false);
    } else {
      this.isSubscribed.next(true);
    }
  }
}
