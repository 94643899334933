import { EventsService, EventsServiceManager } from '../events.service';
import { ActionConfirmer, DeletionConfirmer } from '../confirmation';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NEVER, Subscription } from 'rxjs';
import { Entity, Identified } from '../../models';
import { MessageEvent } from '../message-event.service';
import { EntityService } from '../entity.service';
import { catchError } from 'rxjs/operators';
import { UnauthorizedError } from '../http.service';

export class ActionConfirmationService implements ActionConfirmer {
  constructor(private dialog: MatDialog) {}

  confirmAction(
    confirmationMessage: string,
    callback: () => void
  ): Subscription {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: confirmationMessage,
      },
    });

    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        callback();
      }
    });
  }
}

export abstract class DeletionConfirmationService<E extends Entity>
  implements DeletionConfirmer<E>
{
  private eventsService: EventsService<MessageEvent>;

  constructor(
    protected entityService: EntityService<E, any>,
    private dialog: MatDialog,
    eventsServiceManager: EventsServiceManager
  ) {
    this.eventsService = eventsServiceManager.get('content.messages', {
      message: '',
      status: 'success',
    } as MessageEvent);
  }
  abstract getConfirmDeleteEntityMessage(entity: E): string;

  abstract getEntityDeletedMessage(entity: E): string;

  confirmDeleteEntity(
    entity: Identified<E>,
    subscription: Subscription,
    unauthorized_delete: Record<string, boolean>,
    callback: () => void
  ) {
    unauthorized_delete[entity.id] = false;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.getConfirmDeleteEntityMessage(entity),
      },
    });

    subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          subscription.add(
            this.entityService
              .deleteEntity(entity)
              .pipe(
                catchError((error: Error) => {
                  if (error instanceof UnauthorizedError) {
                    unauthorized_delete[entity.id] = true;
                  }
                  return NEVER;
                })
              )
              .subscribe((isDeleted) => {
                if (isDeleted) {
                  this.eventsService.pushEvent({
                    message: this.getEntityDeletedMessage(entity),
                    status: 'success',
                  });
                  callback();
                }
              })
          );
        }
      })
    );
  }
}
