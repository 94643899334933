import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationEnd,
  NavigationExtras,
  Router,
  UrlTree,
} from '@angular/router';
import { Subscription } from 'rxjs';

type ExcludePath = {
  path: string;
  from: 'anywhere' | 'start';
};

/**
 * Service for routing/navigation within the context of the app
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private history: string[] = [];
  private subscriptions = new Subscription();

  constructor(private router: Router, private location: Location) {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }
      })
    );
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): void {
    this.router.navigateByUrl(url, extras);
  }

  navigate(commands: any[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras);
  }

  /**
   * Go back, if the previous location is within the context of the webapp
   * Otherwise, use the fallback route
   */
  back(fallbackRoute: string = ''): void {
    this.history.pop();

    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackRoute);
    }
  }

  /**
   * Get a previous url. Counts back from current (previous URL is 1, one before that is 2 etc...)
   * Can exclude paths so you can go back to a page before a certain route pattern.
   * @param count Number to go back in history. Returns null if no history.
   * @param exclude (optional) Return a path that does not start with the exclude path.
   * @param exclude.path String to look for in url
   * @param exclude.from start = looks from the start of the url, anywhere = look anywhere in the string
   * @returns the (last - count) path in angular router's history that doesn't match the exclude
   */
  getPreviousUrl(count: number, exclude?: ExcludePath): string {
    if (!this.history) {
      return null;
    }

    const indexFromLast = 1 + count;
    const history = exclude ? this.getFilteredHistory(exclude) : this.history;
    return history[history.length - indexFromLast] || null;
  }

  /**
   * Gets the array of history urls that *do not* match the exclude path and locations
   * @param exclude Return a path that does not start with the exclude path.
   * @param exclude.path String to look for in url
   * @param exclude.from start = looks from the start of the url, anywhere = look anywhere in the string
   */
  getFilteredHistory(exclude: ExcludePath) {
    return this.history.filter((h) => {
      if (exclude.from === 'start') {
        return h.indexOf(exclude.path) !== 0;
      } else if (exclude.from === 'anywhere') {
        return h.indexOf(exclude.path) === -1;
      }
      return true;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
