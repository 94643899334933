<app-loading-guard [loading]="webhooks.loading">
  <mat-table
    id="reports-table"
    class="kahi-table"
    [dataSource]="webhooks.entities"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let item">
        <a class="webhook-name" [routerLink]="['/webhooks', item.id]">{{
          item.name
        }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
      <mat-header-cell *matHeaderCellDef>Organization</mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.contextName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.type }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div class="actions">
          <button
            *hasPrivilege="'webhook/update'; else read"
            mat-raised-button
            rippleOnHover
            color="primary"
            (click)="editWebhook(item, false)"
          >
            Edit
          </button>
          <ng-template #read>
            <button
              *hasPrivilege="'webhook/read'"
              mat-raised-button
              rippleOnHover
              color="primary"
              (click)="editWebhook(item, true)"
            >
              Edit
            </button>
          </ng-template>
          <button
            mat-icon-button
            rippleOnHover
            color="primary"
            (click)="handleDeleteClick(item)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="row"
    ></mat-row>
  </mat-table>
</app-loading-guard>
