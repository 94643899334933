<mat-dialog-content class="confirm-asset-edit-dialog kh-dialog-lg">
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <p>{{ dialogMessage }}</p>
  <ol class="location-list">
    <li *ngFor="let location of data.selectedLocations">{{ location.name }}</li>
  </ol>
  <div class="form-buttons">
    <button mat-raised-button color="primary" (click)="confirmClick($event)">
      {{ confirmBtnText }}
    </button>
    <button mat-button (click)="onClose($event)">Cancel</button>
  </div>
</mat-dialog-content>
