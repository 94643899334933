import { LngLatLike, Map, PointLike } from 'mapbox-gl';
import { Coordinates } from '../../models/location';

export const getPopupOffsetByRadius = (
  radius: number
): { [key: string]: PointLike } => ({
  bottom: [0, -radius],
  top: [0, radius],
  right: [-radius, 0],
  left: [radius, 0],
});

const comparisonExpression = (operator, val1, val2) => [operator, val1, val2];
const getExpression = (value, obj?) =>
  obj ? ['get', value, obj] : ['get', value];

export const toLngLatLike = (coordinates: Coordinates): [number, number] => [
  coordinates.longitude,
  coordinates.latitude,
];

export const MapExpressionBuilders = {
  dataPropertyEquals: (propertyName: string, value: any) =>
    comparisonExpression(
      '==',
      getExpression(propertyName, getExpression('data')),
      value
    ),
  dataPropertyNotEquals: (propertyName: string, value: any) =>
    comparisonExpression(
      '!=',
      getExpression(propertyName, getExpression('data')),
      value
    ),
  propertyEquals: (propertyName: string, value: any) =>
    comparisonExpression('==', getExpression(propertyName), value),
  propertyNotEquals: (propertyName: string, value: any) =>
    comparisonExpression('!=', getExpression(propertyName), value),
};

const loadImage = (map: Map, url: string, name: string) =>
  new Promise<void>((resolve, _) => {
    map.loadImage(url, (error, res) => {
      if (!error) {
        map.addImage(name, res, { sdf: true });
      }
      resolve();
    });
  });

export const loadImages = (map, images: { url; name }[]) =>
  Promise.all(images.map(({ url, name }) => loadImage(map, url, name)));
