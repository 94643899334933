import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebhooksService } from '../../services/webhooks.service';
import {
  Webhook,
  WebhookResult,
  WebhookRequest,
  WebhookResponse,
} from '../../../models/webhook';
import { EntityDetailsPageComponent } from '../../../util/entity-details-page.component';
import { EntityDetailsPageService } from 'src/app/util/entity-details-page.service';
import { ActionConfirmationService } from 'src/app/util/confirmation/confirmation.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-webhook-details',
  templateUrl: './webhook-details.component.html',
  styleUrls: ['./webhook-details.component.scss'],
  providers: [EntityDetailsPageService],
})
export class WebhookDetailsComponent
  extends EntityDetailsPageComponent<Webhook>
  implements OnInit, OnDestroy
{
  baseTitle: string = 'Webhook Details';

  constructor(
    private webhooksService: WebhooksService,
    detailsService: EntityDetailsPageService<Webhook>,
    dialog: MatDialog
  ) {
    super(
      detailsService,
      webhooksService,
      new ActionConfirmationService(dialog)
    );
  }

  getContextRouterLink(item: Webhook): string[] {
    return this.webhooksService.getContextRouterLink(item);
  }

  getEventTypesFormatted(item: Webhook): string {
    return item.eventTypes.join(', ');
  }

  getResultStatus(result: WebhookResult): string {
    return result.response ? result.response.code.toString() : 'Error';
  }

  private getBody(r: WebhookRequest | WebhookResponse): string {
    const keys = Object.keys(r.headers || []);
    const lcMap = keys.reduce((acc, curr) => {
      acc[curr.toLowerCase()] = r.headers[curr];
      return acc;
    }, {});

    if (
      lcMap['content-type'] &&
      lcMap['content-type'].indexOf('application/json') > -1
    ) {
      return JSON.stringify(r.body, null, 2);
    }

    return r.body;
  }

  getRequestBody(result: WebhookResult): string {
    return this.getBody(result.request);
  }

  getResponseBody(result: WebhookResult): string {
    return this.getBody(result.response);
  }

  getTitle(webhook: Webhook): string {
    return webhook.name;
  }
}
