import { SvgIcon } from './util/icons/svg-icon';

const iconNames = [
  'assets',
  'battery_20',
  'battery_60',
  'battery_alert',
  'battery_full',
  'battery_unknown',
  'humidity',
  'menu',
  'more_vert',
  'report_problem',
  'settings',
  'sign_out',
  'thermometer',
  'visibility',
  'visibility_off',
];

export const AppIcons: SvgIcon[] = iconNames.map((iconName) => ({
  iconName,
  url: `assets/icons/${iconName}.svg`,
}));
