import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

interface ImageOverlayEvent {
  delete?: string;
}

export class ImageGalleryOverlayRef {
  #onCloseSubj = new Subject<string>();
  onClose$ = this.#onCloseSubj.asObservable();

  constructor(private overlayRef: OverlayRef) {}

  close(event?: ImageOverlayEvent): void {
    if (event?.delete) {
      this.#onCloseSubj.next(event.delete);
    }
    this.overlayRef.dispose();
  }
}
