import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { OnboardingDataService } from '../onboarding-data.service';

@Component({
  selector: 'app-onboarding-overlay',
  templateUrl: './onboarding-overlay.component.html',
  styleUrls: ['./onboarding-overlay.component.scss'],
})
export class OnboardingOverlayComponent implements OnInit, OnDestroy {
  setupStatus$ = this.onboardingDataService.setupStatus$;
  loading$ = this.onboardingDataService.loading$;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  updating = false;

  private subscriptions = new Subscription();
  constructor(private onboardingDataService: OnboardingDataService) {}

  handleClose() {
    this.close.emit();
  }

  ngOnInit() {
    this.onboardingDataService.reload();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
