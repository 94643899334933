import {
  AfterViewInit,
  Component,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { ResizeObserverService } from 'src/app/core/services/resize-observer/resize-observer.service';
import { MapArtifactsStore } from 'src/app/map/services/map-artifacts-store.service';
import { MapboxComponent } from 'src/app/mapbox/mapbox.component';
import { Asset } from 'src/app/models/asset';
import { ActiveRecentInactive } from 'src/app/models/relevance-status';
import { OrphanedAssetMapService } from '../../services/orphaned-asset-map.service';

@Component({
  selector: 'app-jobsite-select-map',
  templateUrl: './jobsite-select-map.component.html',
  styleUrls: ['./jobsite-select-map.component.scss'],
})
export class JobsiteSelectMapComponent
  extends MapboxComponent
  implements AfterViewInit
{
  @Input() asset: Asset;

  constructor(
    private orphanedAssetMapService: OrphanedAssetMapService,
    viewContainerRef: ViewContainerRef,
    resizeObserver: ResizeObserverService,
    artifactsService: MapArtifactsStore
  ) {
    super(
      orphanedAssetMapService,
      viewContainerRef,
      resizeObserver,
      artifactsService
    );
    this.visibility = {
      orphanedAssets: false,
      remote: ActiveRecentInactive,
      fixed: ActiveRecentInactive,
      roving: ActiveRecentInactive,
      mobile: [],
    };
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.orphanedAssetMapService.initialize(this.asset);
  }
}
