import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import {
  GatewayFilter,
  GatewayLocationType,
  GatewaysService,
} from 'src/app/gateways/gateways.service';
import { LocationContextService } from 'src/app/layout/location-context/location-context.service';
import {
  Gateway,
  GatewayTracking,
  gatewayTrackingDecoder,
} from 'src/app/models/gateway';
import {
  DefaultFilteredEntityListStore,
  FilteredEntityListComponent,
  defaultFilteredEntityListStoreProvider,
} from 'src/app/util/entity-list/filtered-entity-list.base';
import {
  EventsService,
  EventsServiceManager,
} from 'src/app/util/events.service';
import { STORE_SERVICE_TOKEN } from 'src/app/util/store.service';

@Component({
  selector: 'app-alert-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss'],
  providers: [
    defaultFilteredEntityListStoreProvider<GatewayFilter>(
      'alert-gateways.component'
    ),
  ],
})
export class GatewaysComponent
  extends FilteredEntityListComponent<Gateway, GatewayFilter>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() alertType?: string;
  @Input() gatewayType?: GatewayLocationType;

  displayedColumns: string[] = [
    'external_uid',
    'platform',
    'location',
    'heartbeat_timestamp',
    'actions',
  ];

  gatewayEventsService: EventsService<GatewayTracking>;

  private homeLocationId: string;
  customRoute: string;

  constructor(
    gatewaysService: GatewaysService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultFilteredEntityListStore<GatewayFilter>,
    eventsServiceManager: EventsServiceManager,
    locationContextService: LocationContextService,
    route: ActivatedRoute
  ) {
    super(gatewaysService, store, {});
    route.paramMap
      .pipe(withLatestFrom(route.data))
      .subscribe(([params, data]) => {
        const type = params.get('alert_type');
        if (type) {
          this.updateAlertType(type, data.gatewayType);
        }
      });
    this.gatewayEventsService = eventsServiceManager.get(
      'gateways.changes',
      gatewayTrackingDecoder
    );

    this.addSubscription(
      locationContextService.locationId$.subscribe((locationId) => {
        this.homeLocationId = locationId;
        this.onFilterChange();
      })
    );
  }

  protected getEntityListFilter(): GatewayFilter {
    const filter: GatewayFilter = {};
    if (this.customRoute) {
      filter.customRoute = this.customRoute;
    }
    if (this.homeLocationId) {
      filter.location = { id: this.homeLocationId, type: this.gatewayType };
    }
    return filter;
  }

  getTableId(): string {
    return 'gateways-table';
  }

  onFilterChange() {
    this.filterChanges.next();
  }

  updateAlertType(alertType: string, gatewayType: string) {
    // Update the route to use the alert type
    this.customRoute = `${gatewayType}_gateways/${alertType}`;
    this.filter.customRoute = this.customRoute;
    // Update the entities for the new alert type
    this.entityList.loading = true;
    this.onFilterChange();
  }

  ngOnChanges(): void {
    if (this.alertType && this.gatewayType) {
      this.updateAlertType(this.alertType, this.gatewayType);
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.addSubscription(
      this.filterChanges
        .pipe(debounceTime(this.filterDebounceTime))
        .subscribe(() => {
          this.store.pushState(this.getState());
        })
    );

    this.addSubscription(
      this.gatewayEventsService.subscribe((event: GatewayTracking) => {
        const matchingGateway = this.entityList.entities.find(
          (gateway) => gateway.id === event.id
        );

        if (matchingGateway && matchingGateway.tracking) {
          matchingGateway.tracking.synchronized_at = event.synchronized_at;
          matchingGateway.tracking.heartbeat_status = event.heartbeat_status;
        }
      })
    );
  }
}
