<ng-container *ngIf="authorized; else errorDisplay">
  <ng-content></ng-content>
</ng-container>

<ng-template #errorDisplay>
  <ng-container
    *ngTemplateOutlet="
      unauthorizedTemplate ? unauthorizedTemplate : defaultUnauthorizedTemplate;
      context: { $implicit: message }
    "
  ></ng-container>
</ng-template>
<ng-template #defaultUnauthorizedTemplate let-message>
  <p>
    {{ message }}
  </p>
</ng-template>
