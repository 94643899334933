import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PlansChooserComponent } from './plans-chooser.component';

@NgModule({
  imports: [SharedModule],
  declarations: [PlansChooserComponent],
  exports: [PlansChooserComponent],
})
export class PlansChooserModule {}
