import { Directive, HostBinding } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

/**
 * Applies common attributes to form fields throughout the app
 */
@Directive({
  selector: 'mat-form-field[appFormField]',
})
export class FormFieldDirective {
  @HostBinding('class.form-field') formFieldClass = true;

  constructor(private matFormField: MatFormField) {
    this.matFormField.appearance = 'outline';
  }
}
