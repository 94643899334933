import { Decoder, either, number, string } from 'decoders';

export interface Entity {
  id?: string;
}

export type Identified<T extends Entity> = T & {
  id: string;
};

export interface EntityList<E extends Entity> {
  entities: E[];
  perPage: number;
  page: number;
  total: number;
  loading: boolean;
}

export const identityEncoder = function <E>(entity: E) {
  return entity;
};

export const numericStringDecoder: Decoder<number> = either(
  number,
  string
).transform((val) => Number(val));
