import { ReplaySubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../core/services/api/auth.service';

const namespacePrefix = 'kahi/state.service';
const version = 2;
const stateTTL = 3600 * 1000; // 1 hr expiry

interface State {
  state: {};
  version: number;
  expires: number;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private subject = {};

  constructor(private authService: AuthService) {}

  pushState(namespace: string, state: {}) {
    this.putState(namespace, {
      state,
      version,
      expires: Date.now() + stateTTL
    });
    this.getSubject(namespace).next(state);
  }

  listen(namespace: string, callback: (state: {}) => void): Subscription {
    return this.getSubject(namespace).subscribe({
      next: callback
    });
  }

  getObservable(
    namespace: string,
    initial: {},
  ) {
    const subject = this.getSubject(namespace);
    const userId = this.getUserId();

    const item = window.localStorage.getItem(`${namespacePrefix}/${userId}/${namespace}`);

    if (item) {
      const data: State = JSON.parse(item);
      if (data.version === version) {
        if (Date.now() < data.expires) {
          initial = data.state;
        }

        // preferences do not expire
        initial['prefs'] = data.state['prefs'] || {};
      }
    }

    this.pushState(namespace, initial);
    return subject;
  }
  subscribe(
    namespace: string,
    initial: {},
    callback: (state: {}) => void
  ): Subscription {
    return this.getObservable(namespace, initial).subscribe({
      next: callback
    });
  }

  private getUserId(): string {
    let userId = 'anonymous';
    if (this.authService.loggedInId) {
      userId = btoa(this.authService.loggedInId);
    }
    return userId;
  }

  private putState(namespace: string, data: State) {
    const item = JSON.stringify(data);
    const userId = this.getUserId();
    window.localStorage.setItem(
      `${namespacePrefix}/${userId}/${namespace}`,
      item
    );
  }

  private getSubject(namespace: string) {
    if (!this.subject[namespace]) {
      this.subject[namespace] = new ReplaySubject<{}>(1);
    }

    return this.subject[namespace];
  }
}
