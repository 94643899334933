import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PortalService } from '../portal.service';
import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-portal-outlet',
  template: `<ng-template *cdkPortalOutlet></ng-template>`,
})
export class PortalOutletComponent implements OnInit, OnDestroy {

  @Input() name: string = null;

  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: PortalOutlet;

  private subscriptions = new Subscription();

  constructor(
    public portalService: PortalService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.portalService.portal$(this.name).subscribe((portal) => {
      this.detachPortalOutlet();
      if (!!portal) {
        this.portalOutlet.attach(portal);
      }
    }));
  }

  private detachPortalOutlet() {
    if (this.portalOutlet.hasAttached()) {
      this.portalOutlet.detach();
    }
  }

  ngOnDestroy() {
    this.portalService.unregisterPortal(this.name);
    this.detachPortalOutlet();

    this.subscriptions.unsubscribe();
  }
}
