import { Component, Input } from '@angular/core';
import { filterNavigationItems } from 'src/app/app.navigation';
import { UserDataService } from 'src/app/util/user-data.service';
import { User } from '../../models/user';
import { NavigationLink } from '../navigation/navigation-item';

const SETTINGS_MENU_ITEMS: NavigationLink[] = [
  {
    type: 'link',
    label: 'Organization',
    route: '/organization',
    icon: 'corporate_fare',
    privilege: 'organization/read',
  },
  {
    type: 'link',
    label: 'Gateways',
    route: '/gateways',
    icon: 'settings_remote',
    privilege: 'gateway/read',
  },
  {
    type: 'link',
    label: 'Webhooks',
    route: '/webhooks',
    icon: 'sync_alt',
    privilege: 'webhook/read',
  },
  {
    type: 'link',
    label: 'Order Hardware',
    route: '/hardware/order',
    icon: 'shopping_cart',
    privilege: 'order/create',
  },
  {
    type: 'link',
    label: 'Billing',
    route: '/billing',
    icon: 'request_quote',
    privilege: 'subscription/read',
  },
  {
    type: 'link',
    label: 'Change Password',
    route: '/profile/change-password',
    icon: 'vpn_key',
  },
];

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent {
  @Input() user: User;

  navItems = filterNavigationItems(
    SETTINGS_MENU_ITEMS,
    this.userDataService.privileges,
    this.userDataService.featureFlags
  );

  constructor(private userDataService: UserDataService) {}
}
