import { Injectable, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Webhook, WebhookContext } from '../../models/webhook';
import { EntityFormService, Form } from '../../util/entity-form.service';

export interface WebhookForm extends Form<any> {
  definition: FormControl<string>;
  name: FormControl<string>;
  url: FormControl<string>;
  enabled: FormControl<boolean>;
}

@Injectable()
export class WebhookFormService
  extends EntityFormService<Webhook, WebhookForm>
  implements OnDestroy
{
  webhookType: WebhookContext;
  webhookEntityId: string;

  constructor(protected fb: FormBuilder) {
    super();
    super.initialize();
  }

  setWebhookType(webhookType: WebhookContext) {
    this.webhookType = webhookType;
  }

  setWebhookEntityId(webhookEntityId: string) {
    this.webhookEntityId = webhookEntityId;
  }

  protected initializeForm() {
    this.form = new FormGroup({
      definition: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      url: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      enabled: new FormControl(true),
    });
  }

  public buildEntity(entity: Partial<Webhook> = {}): Webhook {
    const formValue = this.form.value;
    const webhook: Webhook = {
      ...entity,
      name: formValue.name,
      url: formValue.url || '',
      eventTypes: [],
      enabled: formValue.enabled,
      type: this.webhookType,
      entityId: this.webhookEntityId,
    };
    if (formValue.definition) {
      webhook.eventTypes = [formValue.definition];
    }

    return webhook;
  }
}
