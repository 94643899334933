import {
  array,
  boolean,
  constant,
  dict,
  either,
  maybe,
  number,
  object,
  string,
} from 'decoders';
import { Entity } from '.';
import { Decodify } from './decoders';
import { FixedLocation, fixedLocationDecoder } from './fixed-location';
import { SetupStatus, setupStatusDecoder } from './setup-status';

export enum PlanTier {
  Monitoring = 'monitoring',
  Tracking = 'tracking',
  Inventory = 'inventory',
  RavenOnly = 'raven-only',
}

export type PlanTiers = PlanTier[];

export enum SubscriptionState {
  Signup = 'signup',
  Customer = 'customer',
  Subscriber = 'subscriber',
  PastCustomer = 'past_customer',
  PastDue = 'past_due',
}

export interface HomesickCategories {
  [key: string]: number;
}

export interface NomenclatureKeys {
  fixed_location: string;
  mobile_location: string;
  remote_location: string;
  roving_location: string;
}

export interface Nomenclature {
  singular: NomenclatureKeys;
  plural: NomenclatureKeys;
}

interface Industry {
  nomenclature: Nomenclature;
}

export interface Organization extends Entity {
  name: string;
  street_address: string;
  street_address2: string;
  city: string;
  region: string;
  country: string;
  postal_code: string;
  billing_email: string;
  subscription_state: SubscriptionState;
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  stripe_checkout_session_id?: string;
  webhook_signing_key?: string;
  setup_status?: SetupStatus;
  primary_location?: FixedLocation;
  unsigned_agreement?: boolean;
  discover_locations?: boolean;
  any_fixed_is_home?: boolean;
  default_homesick_days?: number;
  homesick_categories?: HomesickCategories;
  plan_tiers?: PlanTiers;
  industry?: Industry;
  reorder_enabled: boolean;
}

export const subscriptionStateDecoder = either(
  constant(SubscriptionState.Signup),
  constant(SubscriptionState.Customer),
  constant(SubscriptionState.Subscriber),
  constant(SubscriptionState.PastCustomer),
  constant(SubscriptionState.PastDue)
);

export const planTiersDecoder = array(
  either(
    constant(PlanTier.Monitoring),
    constant(PlanTier.Inventory),
    constant(PlanTier.Tracking),
    constant(PlanTier.RavenOnly)
  )
);

const nomenclatureKeysDecoder = object<Decodify<NomenclatureKeys>>({
  fixed_location: string,
  mobile_location: string,
  remote_location: string,
  roving_location: string,
});

const nomenclatureDecoder = object<Decodify<Nomenclature>>({
  singular: nomenclatureKeysDecoder,
  plural: nomenclatureKeysDecoder,
});

const industryDecoder = object<Decodify<Industry>>({
  nomenclature: nomenclatureDecoder,
});

export const organizationDecoder = object<Decodify<Organization>>({
  id: string,
  name: string,
  street_address: maybe(string),
  street_address2: maybe(string),
  city: maybe(string),
  region: maybe(string),
  country: maybe(string),
  postal_code: maybe(string),
  billing_email: maybe(string),
  subscription_state: maybe(subscriptionStateDecoder),
  stripe_customer_id: maybe(string),
  stripe_subscription_id: maybe(string),
  stripe_checkout_session_id: maybe(string),
  webhook_signing_key: maybe(string),
  setup_status: maybe(setupStatusDecoder),
  primary_location: maybe(fixedLocationDecoder),
  unsigned_agreement: maybe(boolean),
  discover_locations: maybe(boolean),
  any_fixed_is_home: maybe(boolean),
  default_homesick_days: maybe(number),
  homesick_categories: maybe(dict(number)),
  plan_tiers: maybe(planTiersDecoder),
  industry: maybe(industryDecoder),
  reorder_enabled: maybe(boolean),
}).transform((raw) => {
  return {
    ...raw,
    reorder_enabled: !!raw.reorder_enabled,
  };
});
