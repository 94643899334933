import { object, string, array } from 'decoders';
import { Decodify } from './decoders';
import { WebhookContext, webhookContextDecoder } from './webhook';

export interface WebhookDefinition {
  id?: string;
  code: string;
  name: string;
  context: WebhookContext[];
  description: string;
}

export const webhookDefinitionDecoder = object<Decodify<WebhookDefinition>>({
  id: string,
  code: string,
  name: string,
  context: array(webhookContextDecoder),
  description: string,
});
