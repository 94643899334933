import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api/auth.service';
import {
  EventsService,
  EventsServiceManager,
} from 'src/app/util/events.service';
import { errorHandler } from 'src/app/util/http.service';
import { AgreementService } from './agreement.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent implements OnInit {
  private errorsService: EventsService<Error>;

  agreed = false;

  content: SafeHtml = '';

  @Output() signed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private agreementService: AgreementService,
    private authService: AuthService,
    eventsServiceManager: EventsServiceManager
  ) {
    this.errorsService = eventsServiceManager.get(
      'content.errors',
      new Error()
    );
  }

  async submit() {
    if (this.agreed) {
      await this.agreementService.signAgreement();

      this.signed.emit();
    }
  }

  ngOnInit(): void {
    this.loadEula();
  }

  private async loadEula() {
    this.content = '<b>Loading</b>...';
    try {
      const agreement = await this.agreementService.getEntity().toPromise();
      this.content = agreement.html;
    } catch (err) {
      errorHandler(this.router, this.authService, this.errorsService);
    }
  }
}
