import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coordinates } from '../../../../models/location';

@Component({
  selector: 'app-asset-tracking-map-dialog',
  templateUrl: './asset-tracking-map-dialog.component.html',
  styleUrls: ['./asset-tracking-map-dialog.component.scss'],
  providers: [],
})
export class AssetTrackingMapDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { coordinates: Coordinates }
  ) {}
}
