import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filterNavigationItems } from 'src/app/app.navigation';
import { UserDataService } from 'src/app/util/user-data.service';
import { LayoutService } from '../layout.service';
import { InventoryLink, NavigationItem } from '../navigation/navigation-item';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() collapsed: boolean;

  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();

  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  isDesktop$ = this.layoutService.isDesktop$;

  user = this.userDataService.user;
  planTiers = this.userDataService.planTiers;
  navigationItems: NavigationItem[];
  inventoryItems: InventoryLink[];

  constructor(
    private layoutService: LayoutService,
    private navigationService: SidenavService,
    private userDataService: UserDataService
  ) {}

  ngOnInit(): void {
    const { featureFlags, privileges } = this.userDataService;
    const { items, inventoryItems } = this.navigationService;
    this.navigationItems = filterNavigationItems(
      items,
      privileges,
      featureFlags
    );
    this.inventoryItems = filterNavigationItems(
      inventoryItems,
      privileges,
      featureFlags
    ) as InventoryLink[];
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  onSignOut() {
    this.signOut.emit();
  }
  toggleCollapsed() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }
}
