import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetupStatusItem } from '../../models/setup-status';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-checklist-item',
  templateUrl: './onboarding-checklist-item.component.html',
  styleUrls: ['./onboarding-checklist-item.component.scss'],
})
export class OnboardingChecklistItemComponent {
  @Input() item: SetupStatusItem;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  constructor(private router: Router) {}

  handleActionClick() {
    if (!this.item.completed) {
      this.router.navigateByUrl(new URL(this.item.action_url).pathname);
      this.close.emit();
    }
  }

  handleHelpClick() {
    window.open(this.item.support_url, '_blank');
  }
}
