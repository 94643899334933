<mat-card appearance="outlined" class="details-card">
  <mat-card-content>
    <dl class="details-list">
      <dt>Latitude</dt>
      <dd>{{ coordinates.latitude }}</dd>
      <dt>Longitude</dt>
      <dd>{{ coordinates.longitude }}</dd>
    </dl>
    <div class="resp-container">
      <div class="resp-iframe">
        <div #map id="map" class="map"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
