import { Component, Input, TemplateRef } from '@angular/core';

/**
 * Displays the given message instead of the child content when unauthorized
 */
@Component({
  selector: 'app-authorized-guard',
  templateUrl: './authorized-guard.component.html',
  styleUrls: [],
})
export class AuthorizedGuardComponent {

  @Input() authorized: boolean;
  @Input() message = 'You are not authorized to access this data.';
  @Input() unauthorizedTemplate: TemplateRef<any>;
}
