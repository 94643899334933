import { Entity } from './index';
import { array, boolean, maybe, number, object, string } from 'decoders';
import { Decodify } from './decoders';

export interface AssetCategory extends Entity {
  name: string;
  full_name: string;
  categories?: string[];
  cataloged?: boolean;
  asset_group: string;
  asset_count?: number;
}

export const assetCategoryDecoder = object<Decodify<AssetCategory>>({
  id: string,
  name: string,
  full_name: string,
  categories: maybe(array(string)),
  cataloged: maybe(boolean),
  asset_group: maybe(string),
  asset_count: maybe(number),
});
