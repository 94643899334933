import { Component } from '@angular/core';
import { ImpersonateService } from '../../impersonate/impersonate.service';

@Component({
  selector: 'app-impersonating-banner',
  templateUrl: './impersonating-banner.component.html',
  styleUrls: ['./impersonating-banner.component.scss']
})
export class ImpersonatingBannerComponent {

  constructor(
    public impersonateService: ImpersonateService,
  ) {}

  endImpersonation() {
    this.impersonateService.endImpersonation();
  }

}
