import { AnyLayer, GeoJSONSource, GeoJSONSourceRaw, Map } from 'mapbox-gl';
import turfCircle from '@turf/circle';
import { featureCollection, point } from '@turf/helpers';
import { Coordinates } from '../../models/location';

export class MapGeofence {

  static instanceCount = 0;

  private readonly sourceId: string;
  private readonly fillId: string;
  private readonly strokeId: string;

  private map: Map;

  private options = {
    strokeColor: '#214D7D',
    strokeWeight: 0.75,
    strokeOpacity: 0.75,
    fillColor: '#214D7D',
    fillOpacity: 0.1,
  };

  constructor(private coordinates: Coordinates, private radius: number) {
    this.sourceId = `geofence-source-${MapGeofence.instanceCount}`;
    this.fillId = `geofence-fill-${MapGeofence.instanceCount}`;
    this.strokeId = `geofence-stroke-${MapGeofence.instanceCount}`;

    MapGeofence.instanceCount = MapGeofence.instanceCount + 1;
  }

  setCoordinates(coordinates: Coordinates) {
    this.coordinates = coordinates;
    this.update();
  }

  setRadius(radius: number) {
    this.radius = radius;
    this.update();
  }

  private update() {
    if (this.map) {
      const source = <GeoJSONSource>this.map.getSource(this.sourceId);
      source.setData(this.getCircleGeoJSON());
    }
  }

  addTo(map: Map, beforeLayer = '') {
    const addCircleToMap = () => {
      map.addSource(this.sourceId, this.getCircleMapSource());

      map.addLayer(this.getCircleStrokeLayer(), beforeLayer);
      map.addLayer(this.getCircleFillLayer(), beforeLayer);
    };

    // @ts-ignore - hack https://github.com/mapbox/mapbox-gl-js/issues/6707#issuecomment-495481411
    if (map._loaded) {
      addCircleToMap();
    } else {
      map.once('load', () => {
        addCircleToMap();
      });
    }


    this.map = map;

    return this;
  }

  private getCircleMapSource(): GeoJSONSourceRaw {
    return {
      type: 'geojson',
      data: this.getCircleGeoJSON(),
      buffer: 1,
    };
  }

  private getCircleGeoJSON() {
    return featureCollection([this.getCircleFeature()]);
  }

  private getCircleFeature() {
    return turfCircle(point(this.getCenter()), this.radius, { steps: 64, units: 'meters' });
  }

  private getCenter(): number[] {
    return [this.coordinates.longitude, this.coordinates.latitude];
  }

  private getCircleStrokeLayer(): AnyLayer {
    return {
      id: this.strokeId,
      type: 'line',
      source: this.sourceId,
      paint: {
        'line-color': this.options.strokeColor,
        'line-width': this.options.strokeWeight,
        'line-opacity': this.options.strokeOpacity
      },
      filter: ['==', '$type', 'Polygon']
    };
  }

  private getCircleFillLayer(): AnyLayer {
    return {
      id: this.fillId,
      type: 'fill',
      source: this.sourceId,
      paint: {
        'fill-color': this.options.fillColor,
        'fill-opacity': this.options.fillOpacity
      },
      filter: ['==', '$type', 'Polygon']
    };
  }

  remove() {
    if (this.map.getLayer(this.fillId)) {
      this.map.removeLayer(this.fillId);
    }

    if (this.map.getLayer(this.strokeId)) {
      this.map.removeLayer(this.strokeId);
    }

    if (this.map.getSource(this.sourceId)) {
      this.map.removeSource(this.sourceId);
    }

    this.map = null;
  }
}
