import { hardcoded, maybe, object } from 'decoders';
import { Decodify } from './decoders';
import {
  coordinateDecoder,
  Coordinates,
  Location,
  locationObject,
  LocationTracking,
  locationTrackingDecoderObject,
} from './location';

export interface RemoteLocationTracking extends LocationTracking {}

export interface RemoteLocation extends Location {
  type: 'remote';
  tracking?: RemoteLocationTracking;
  coordinates?: Coordinates;
}

export const remoteLocationTrackingDecoder = object<
  Decodify<RemoteLocationTracking>
>({
  ...locationTrackingDecoderObject,
});

export const remoteLocationDecoder = object<Decodify<RemoteLocation>>({
  ...locationObject,
  type: hardcoded('remote'),
  tracking: maybe(remoteLocationTrackingDecoder),
  coordinates: maybe(coordinateDecoder),
});
