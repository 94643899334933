import { Directive, Input, TemplateRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: 'ng-template[appControlError]',
})
export class ControlErrorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appControlError') errorName: string;

  // If true, the value assigned to the form error will be shown (instead of the compiled template)
  @Input()
  get dynamicMessage(): boolean {
    return this._dynamicMessage;
  }
  set dynamicMessage(value: boolean) {
    this._dynamicMessage = coerceBooleanProperty(value);
  }
  private _dynamicMessage = false;

  constructor(public template: TemplateRef<any>) {}
}
