import { Directive, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NEVER, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EntityFormService } from './entity-form.service';
import { EntityService } from './entity.service';
import { UnauthorizedError, UnprocessableError } from './http.service';
import { MessageEventService } from './message-event.service';

@Directive()
export abstract class ReactiveEntityCreatePageComponent<
  E,
  F extends { [K in keyof F]: AbstractControl<any, any> }
> implements OnInit, OnDestroy
{
  protected subscription: Subscription = new Subscription();

  loading = false;
  pending = false;
  unauthorized = false;

  get form(): FormGroup<F> {
    return this.formService.form;
  }

  protected constructor(
    private entityService: EntityService<E, {}>,
    protected messageService: MessageEventService,
    protected router: Router,
    protected titleService: Title,
    protected formService: EntityFormService<E, F>
  ) {}

  protected abstract getEntityCreatedMessage(createdEntity: E): string;

  protected abstract getPostCreateRoute(createdEntity: E): string[];

  protected abstract getTitle(): string;

  protected abstract cancelCreate();

  createEntity(): void {
    if (this.form.valid && !this.pending) {
      this.pending = true;
      const entity = this.formService.buildEntity();
      this.subscription.add(
        this.entityService
          .createEntity(entity)
          .pipe(
            catchError((error: Error) => {
              if (error instanceof UnprocessableError) {
                this.mapErrorsToForm(error.errors);
              } else if (error instanceof UnauthorizedError) {
                this.unauthorized = true;
              }
              this.pending = false;
              return NEVER;
            })
          )
          .subscribe(
            (createdEntity: E) => {
              this.messageService.pushEvent({
                message: this.getEntityCreatedMessage(createdEntity),
                status: 'success',
              });
              this.afterCreate(createdEntity);
            },
            () => {
              this.pending = false;
            }
          )
      );
    }
  }

  protected afterCreate(createdEntity) {
    this.router.navigate(this.getPostCreateRoute(createdEntity));
  }

  protected mapErrorsToForm(errors) {
    Object.entries(errors).forEach(([key, value]) => {
      this.form.get(key).setErrors({
        api: value,
      });
      this.form.get(key).markAsTouched();
    });
  }

  ngOnInit() {
    this.titleService.setTitle(`${this.getTitle()} | Kahi`);

    this.loading = true;

    this.subscription.add(
      this.formService.additionalFormData$
        .pipe(
          catchError((error: Error) => {
            if (error instanceof UnauthorizedError) {
              this.unauthorized = true;
            }
            this.loading = false;
            return NEVER;
          })
        )
        .subscribe((data) => {
          this.loading = false;
          this.handleLoadedFormData(data);
        })
    );
  }

  protected handleLoadedFormData(data: any) {}

  addSubscription(subscription: Subscription) {
    this.subscription.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
