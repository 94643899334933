import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../core/services/api/auth.service';
import { Organization, organizationDecoder } from '../models/organization';
import { Subscription, subscriptionDecoder } from '../models/subscription';
import { EventsService, EventsServiceManager } from '../util/events.service';
import {
  BASE_URL,
  errorHandler,
  HttpServiceBuilder,
} from '../util/http.service';
import { httpOptions } from '../util/http.util';
import { SingletonEntityService } from '../util/singleton-entity.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService extends SingletonEntityService<Subscription> {
  private errorsService: EventsService<Error>;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<Subscription>,
    eventsServiceManager: EventsServiceManager,
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) {
    super(httpServiceBuilder, 'subscription', subscriptionDecoder);
    this.errorsService = eventsServiceManager.get(
      'content.errors',
      new Error()
    );
  }

  changePlan(plan_id: string): Observable<Subscription> {
    const url = `${BASE_URL}/subscription/change_plan`;
    return this.http.patch(url, { plan_id }, httpOptions()).pipe(
      map((response) => {
        return subscriptionDecoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  create(
    plan_id: string,
    success_url: string,
    cancel_url: string
  ): Observable<Organization> {
    const params = {
      plan_id,
      success_url,
      cancel_url,
    };
    const url = `${BASE_URL}/subscription`;
    return this.http.post(url, params, httpOptions()).pipe(
      map((response) => {
        return organizationDecoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  cancel() {
    const url = `${BASE_URL}/subscription`;
    return this.http.delete(url, httpOptions()).pipe(
      map((response) => true),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  update(success_url: string, cancel_url: string): Observable<Organization> {
    const params = {
      success_url,
      cancel_url,
    };
    const url = `${BASE_URL}/subscription`;
    return this.http.put(url, params, httpOptions()).pipe(
      map((response) => {
        return organizationDecoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }
}
