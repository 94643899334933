<mat-dialog-content>
    <div class="dialog-container">
      <div class="details">
        <div class="header">
          <h1>Map Coordinates</h1>
          <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="map">
        <app-mapbox
          *ngIf="data.coordinates; else noCoords"
          [coordinates]="data.coordinates"
          [heartbeatStatus]="data.heartbeatStatus"
        ></app-mapbox>
        <ng-template #noCoords>
          <mat-card appearance="outlined" class="nocoords">
            <mat-card-title>Location coordinates unknown</mat-card-title>
          </mat-card>
        </ng-template>
      </div>
    </div>
</mat-dialog-content>
