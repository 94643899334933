<mat-card appearance="outlined" class="onboarding-card" *ngIf="setupStatus$ | async as setupStatus">

  <div class="header-row">
    <div class="mat-headline-6">Get Started with Kahi</div>
  </div>

  <div class="header-row" *ngIf="(loading$ | async) === false; else loadingBar">
    <mat-progress-bar mode="determinate" [value]="setupStatus.percentage_complete"></mat-progress-bar>
    <span class="setup-percent">
      {{ setupStatus.percentage_complete }}%
    </span>
  </div>

  <ng-template #loadingBar>
    <div class="header-row">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template>

  <div class="header-divider">
    <mat-divider></mat-divider>
  </div>
  <app-onboarding-checklist [items]="setupStatus.setup_status_items" (close)="handleClose()"></app-onboarding-checklist>
</mat-card>
