import { Injectable } from '@angular/core';
import { OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, NEVER, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, tap, catchError, shareReplay } from 'rxjs/operators';
import { UnauthorizedError } from './http.service';

type LoadFunction<E> = (params: ParamMap) => Observable<E>;
type OnLoadFunction<E> = (entity: E) => void;

@Injectable()
export class EntityDetailsPageService<E> implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private refreshToken$ = new BehaviorSubject<null>(null);

  private _loading$ = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean> = this._loading$.asObservable();
  private _authorized$ = new BehaviorSubject<boolean>(true);
  authorized$: Observable<boolean> = this._authorized$.asObservable();

  constructor(
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  /**
   * getItem returns an observable of the entity retruned by the loadFunction.
   * Handles change detection, error handling
   *
   * @param loadFunction Takes route ParamMap as a parameter, should return observable containing entity.
   *    Note: Should be wrapped if using other services or the components 'this' ex. (params) => actualLoadFunction(params)
   * @param onEntityLoad What to run after the entity is loaded, gets the entity as a parameter
   * @returns An observable of the entity
   */
  getItem(
    loadFunction: LoadFunction<E>,
    onEntityLoad?: OnLoadFunction<E>
  ): Observable<E> {
    return this.loadItem(loadFunction).pipe(
      catchError((error: Error) => {
        if (error instanceof UnauthorizedError) {
          this._authorized$.next(false);
        }
        this._loading$.next(false);
        this.cdRef.detectChanges();
        return NEVER;
      }),
      tap((e: E) => this.afterLoad(e, onEntityLoad)),
      shareReplay(1)
    );
  }

  /**
   * Updates the title and loading status, gets the entity from the load function
   *
   * @param loadFunction Takes in parameters and returns an observable containing the entity
   * @returns An observable containing the entity
   */
  private loadItem(loadFunction: LoadFunction<E>): Observable<E> {
    return this.refreshToken$.pipe(
      tap(() => {
        this._loading$.next(true);
        this.cdRef.detectChanges();
      }),
      switchMap(() => this.route.paramMap),
      switchMap((params) => loadFunction(params))
    );
  }

  /**
   * Run after the entity is loaded, calls the onEntityLoad function and updates the loading status
   *
   * @param e The entity that was loaded
   * @param onEntityLoad Passed in function to run after the entity is loaded
   */
  private afterLoad(e: E, onEntityLoad?: OnLoadFunction<E>) {
    onEntityLoad?.(e);
    this._loading$.next(false);
    this.cdRef.detectChanges();
  }

  refreshItem() {
    this.refreshToken$.next(null);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
