import {
  array,
  integer,
  maybe,
  nullable,
  number,
  object,
  string,
} from 'decoders';
import { Entity, numericStringDecoder } from '.';
import { AssetCategory, assetCategoryDecoder } from './asset-category';
import {
  AssetManufacturer,
  assetManufacturerDecoder,
} from './asset-manufacturer';
import { Beacon, beaconDecoder } from './beacon';
import { dateString } from './date-string';
import { Decodify, isodate } from './decoders';
import { HeartbeatStatus, heartbeatStatusDecoder } from './heartbeat-status';
import { Image, imageDecoder } from './image';
import {
  Coordinates,
  Location,
  LocationType,
  coordinateDecoder,
  locationDecoder,
  locationTypeDecoder,
} from './location';

// Asset model for assets, different from asset models recieved separately
interface AssetModel extends Entity {
  name: string;
  model_number: string;
}

export interface PastLocation extends Coordinates {
  event_type?: string;
  device_type?: string;
  gateway_id?: string;
  timestamp: Date;
}

export interface AssetTracking extends Entity {
  heartbeat_timestamp?: Date | null;
  seconds_since_heartbeat?: number | null;
  location?: { id: string; name: string; type: LocationType };
  heartbeat_status?: HeartbeatStatus;
  coordinates?: Coordinates;
  recent_coordinate_history?: PastLocation[];
  time_zone?: string | null;
}

interface ExternalMapping {
  encircle_id?: number;
}

export interface Asset extends Entity {
  unit_id: string;
  asset_category_id?: string;
  name: string;
  asset_model?: AssetModel;
  asset_manufacturer?: AssetManufacturer;
  asset_category?: AssetCategory;
  asset_model_id?: string;
  serial_number?: string;
  beacon?: Beacon;
  home_location?: Location;
  home_location_id?: string;
  images?: Image[];
  discarded_at?: Date;
  manufactured_at?: Date;
  warranty_at?: Date;
  tracking?: AssetTracking;
  external_mapping?: ExternalMapping;
}

export interface AssetPinpoint extends Coordinates {
  timestamp: Date;
}

export const assetPinpointDecoder = object<Decodify<AssetPinpoint>>({
  timestamp: isodate,
  latitude: numericStringDecoder,
  longitude: numericStringDecoder,
});

const assetModelDecoder = object<Decodify<AssetModel>>({
  id: string,
  name: string,
  model_number: string,
});

const historicLocationDecoder = object<Decodify<PastLocation>>({
  timestamp: isodate,
  event_type: maybe(string),
  device_type: maybe(string),
  gateway_id: maybe(string),
  latitude: number,
  longitude: number,
});

export const assetTrackingDecoder = object<Decodify<AssetTracking>>({
  id: string,
  heartbeat_timestamp: maybe(isodate),
  seconds_since_heartbeat: maybe(number),
  location: maybe(
    object({
      id: string,
      name: string,
      type: locationTypeDecoder,
    })
  ),
  heartbeat_status: maybe(heartbeatStatusDecoder),
  coordinates: maybe(coordinateDecoder),
  recent_coordinate_history: maybe(array(historicLocationDecoder)),
  time_zone: maybe(nullable(string)),
});

export interface AssetTrackings extends Entity {
  asset_tracking: AssetTracking;
}

export const assetTrackingsDecoder = array(
  object<Decodify<AssetTrackings>>({
    asset_tracking: assetTrackingDecoder,
  })
);

const externalMappingDecoder = object<Decodify<ExternalMapping>>({
  encircle_id: maybe(integer),
});

export const assetDecoder = object<Decodify<Asset>>({
  id: string,
  unit_id: string,
  name: string,
  asset_model: maybe(assetModelDecoder),
  asset_manufacturer: maybe(assetManufacturerDecoder),
  asset_category: maybe(assetCategoryDecoder),
  serial_number: nullable(string),
  beacon: maybe(beaconDecoder),
  home_location: maybe(locationDecoder),
  images: maybe(array(imageDecoder)),
  discarded_at: maybe(isodate),
  manufactured_at: maybe(dateString),
  warranty_at: maybe(dateString),
  tracking: maybe(assetTrackingDecoder),
  external_mapping: maybe(externalMappingDecoder),
});
