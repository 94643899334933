import {
  array,
  boolean,
  dict,
  either,
  number,
  object,
  oneOf,
  string,
} from 'decoders';
import { Entity } from '.';

export type UserPreferenceType = boolean | number | string;

export const userPreferenceValueDecoder = either(boolean, number, string);

export interface UserPreference extends Entity {
  created_at: string;
  label: string;
  setting: string;
  updated_at: string;
  value: UserPreferenceType;
}

export const userPreferenceDecoder = array(
  object({
    id: string,
    created_at: string,
    label: string,
    setting: string,
    updated_at: string,
    value: userPreferenceValueDecoder,
  })
);

export type PreferencesSettings = {
  [setting: string]: UserPreferenceType;
};

/**
 * Can't decode keys without names so enforcing it's at least an object.
 * Keys/settings can be named anything in current PreferenceSettings model.
 */
export const preferencesSettingsDecoder = dict(string);

export type PreferenceValueType = 'boolean' | 'number' | 'enum';

// Preference templates contain the schema for each preference.
export interface PreferenceTemplate extends Entity {
  slug: string;
  label: string;
  preference_type: PreferenceValueType;
  default_value: UserPreferenceType;
  values: UserPreferenceType[];
}

export const preferenceTemplateDecoder = object({
  id: string,
  slug: string,
  label: string,
  preference_type: oneOf<PreferenceValueType>(['boolean', 'number', 'enum']),
  default_value: userPreferenceValueDecoder,
  values: array(userPreferenceValueDecoder),
});
