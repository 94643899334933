import { SafeUrl } from '@angular/platform-browser';
import { integer, object, string } from 'decoders';

export interface Image {
  id?: string;
  position: number;
  filename: string;
  original_url: SafeUrl;
  small_url: SafeUrl;
  medium_url: SafeUrl;
  large_url: SafeUrl;
  full_url: SafeUrl;
}

export const imageDecoder = object({
  id: string,
  position: integer,
  filename: string,
  download_urls: object({
    original: string,
    small: string,
    medium: string,
    large: string,
    full: string,
  }),
}).transform<Image>((img) => {
  return {
    id: img.id,
    position: img.position,
    filename: img.filename,
    original_url: img.download_urls.original,
    small_url: img.download_urls.small,
    medium_url: img.download_urls.medium,
    large_url: img.download_urls.large,
    full_url: img.download_urls.full,
  };
});
