import { Injectable } from '@angular/core';
import { object } from 'decoders';
import { Agreement, agreementDecoder } from 'src/app/models/agreement';
import { HttpService, HttpServiceBuilder } from 'src/app/util/http.service';
import { SingletonEntityService } from 'src/app/util/singleton-entity.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementService extends SingletonEntityService<Agreement> {
  signatureHttpService: HttpService<{}>;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<Agreement>,
    signatureHttpServiceBuilder: HttpServiceBuilder<{}>
  ) {
    super(httpServiceBuilder, 'agreement', agreementDecoder);

    this.signatureHttpService = signatureHttpServiceBuilder.build({
      route: 'agreement_signature',
      decoder: object({}),
      encoder: (entity: {}) => entity,
    });
  }

  signAgreement() {
    return this.signatureHttpService.create({}).toPromise();
  }
}
