import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AlertsRoutingModule } from './alerts-routing.module';
import { AlertNotificationPanelComponent } from './components/alert-notification-panel/alert-notification-panel.component';
import { AlertTriggerComponent } from './components/alert-trigger/alert-trigger.component';
import { AssetAlertsListComponent } from './components/asset-alerts-list/asset-alerts-list.component';
import { GatewayAlertsListComponent } from './components/gateway-alerts-list/gateway-alerts-list.component';
import { JobsiteSelectDialogComponent } from './components/jobsite-select-dialog/jobsite-select-dialog.component';
import { JobsiteSelectMapComponent } from './components/jobsite-select-map/jobsite-select-map.component';
import { ConfirmLocationDialogComponent } from './components/location-alerts-list/confirm-location-dialog/confirm-location-dialog.component';
import { LocationAlertsListComponent } from './components/location-alerts-list/location-alerts-list.component';
import { AlertsCentreComponent } from './pages/alerts-centre/alerts-centre.component';
import { AlertAssetsComponent } from './pages/assets/assets.component';
import { GatewaysComponent } from './pages/gateways/gateways.component';
import { AlertLocationsComponent } from './pages/locations/locations.component';

@NgModule({
  declarations: [
    AlertAssetsComponent,
    AlertLocationsComponent,
    GatewaysComponent,
    AlertNotificationPanelComponent,
    AlertsCentreComponent,
    AssetAlertsListComponent,
    LocationAlertsListComponent,
    GatewayAlertsListComponent,
    AlertTriggerComponent,
    JobsiteSelectDialogComponent,
    JobsiteSelectMapComponent,
    ConfirmLocationDialogComponent,
  ],
  exports: [AlertTriggerComponent],
  imports: [SharedModule, AlertsRoutingModule],
})
export class AlertsModule {}
