import { maybe, constant, either } from 'decoders';

export enum HeartbeatStatus {
  Offline = 'offline',
  Online = 'online',
  Recent = 'recent',
  Unknown = 'unknown',
}

export const heartbeatStatusDecoder = maybe(
  either(
    constant('online'),
    constant('offline'),
    constant('recent'),
    constant('unknown')
  )
).transform<HeartbeatStatus>((status) => {
  switch (status) {
    case HeartbeatStatus.Offline:
      return HeartbeatStatus.Offline;
    case HeartbeatStatus.Online:
      return HeartbeatStatus.Online;
    case HeartbeatStatus.Recent:
      return HeartbeatStatus.Recent;
    case HeartbeatStatus.Unknown:
    case null:
      return HeartbeatStatus.Unknown;

    default:
      throw new Error(`Unable to decode tracking status "${status}"`);
  }
});
