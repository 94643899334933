import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {

  readonly ELLIPSE_CHAR = '…';

  transform(value: string, truncateTo = 50) {
    if (!value || typeof value !== 'string' || value.length <= truncateTo) {
      return value;
    }

    return value.substr(0, truncateTo - 1) + this.ELLIPSE_CHAR;
  }
}
