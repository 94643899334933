import { Injectable } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PortalService {

  portals$ = new BehaviorSubject<{ [key: string]: Portal<any> }>({});

  portal$(name: string) {
    return this.portals$.pipe(
      map(portals => portals[name] || undefined),
      distinctUntilChanged(),
    );
  }



  unregisterPortal(name: string) {
    const portals = this.portals$.getValue();
    delete portals[name];
    this.portals$.next(portals);
  }

  setPortal(portal: Portal<any>, name: string) {
    this.portals$.next({
      ...this.portals$.getValue(),
      [name]: portal,
    });
  }

  clearPortal(name: string) {
    this.portals$.next({
      ...this.portals$.getValue(),
      [name]: undefined,
    });
  }



}
