import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';
import { OrganizationEditComponent } from './pages/organization-edit/organization-edit.component';
import { OrganizationUsersComponent } from './pages/organization-users/organization-users.component';
import { ApiKeysModule } from '../api-keys/api-keys.module';
import { WebhooksComponentModule } from '../webhooks/webhooks-component.module';
import { RouterModule } from '@angular/router';
import { WebhookSigningKeyComponent } from './components/webhook-signing-key/webhook-signing-key.component';
import { OrganizationFormComponent } from './components/organization-form/organization-form.component';
import { HomesickCategoriesComponent } from './components/homesick-categories/homesick-categories.component';
import { HomesickCategoryFormComponent } from './components/homesick-category-form/homesick-category-form.component';
import { OrganizationWebhooksComponent } from './pages/organization-webhooks/organization-webhooks.component';
import { OrganizationComponent } from './pages/organization/organization.component';
import { OrganizationDocumentsComponent } from './pages/organization-documents/organization-documents.component';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
  imports: [
    SharedModule,
    WebhooksComponentModule,
    ApiKeysModule,
    RouterModule,
    DocumentsModule,
  ],
  declarations: [
    OrganizationDetailsComponent,
    OrganizationEditComponent,
    OrganizationUsersComponent,
    WebhookSigningKeyComponent,
    OrganizationFormComponent,
    HomesickCategoriesComponent,
    HomesickCategoryFormComponent,
    OrganizationWebhooksComponent,
    OrganizationComponent,
    OrganizationDocumentsComponent,
  ],
  exports: [
    OrganizationDetailsComponent,
    OrganizationEditComponent,
    OrganizationUsersComponent,
    WebhookSigningKeyComponent,
    OrganizationFormComponent,
    HomesickCategoriesComponent,
    HomesickCategoryFormComponent,
  ],
})
export class OrganizationComponentModule {}
