import { RelevanceStatus } from '../../../models/relevance-status';
import { ThemeColor } from './theme-color';


export const RELEVANCE_STATUS_COLORS: { [Status in RelevanceStatus]: ThemeColor } = {
  [RelevanceStatus.Active]: 'green',
  [RelevanceStatus.Recent]: 'purple',
  [RelevanceStatus.Inactive]: 'bright-blue',
  [RelevanceStatus.Unconfirmed]: 'orange',
  [RelevanceStatus.Ignored]: 'blue',
};


export const getRelevanceStatusColor = (relevanceStatus: RelevanceStatus) => RELEVANCE_STATUS_COLORS[relevanceStatus];
