import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  DefaultFilteredEntityListStore,
  FilteredEntityListComponent,
  defaultFilteredEntityListStoreProvider,
} from 'src/app/util/entity-list/filtered-entity-list.base';
import { STORE_SERVICE_TOKEN } from 'src/app/util/store.service';
import {
  AssetsFilter,
  AssetsService,
} from '../../../assets/services/assets.service';
import { LocationContextService } from '../../../layout/location-context/location-context.service';
import {
  Asset,
  AssetTracking,
  assetTrackingDecoder,
} from '../../../models/asset';
import {
  EventsService,
  EventsServiceManager,
} from '../../../util/events.service';

const DEFAULT_COLUMNS = [
  'image',
  'name',
  'heartbeat_timestamp',
  'home_location',
];

@Component({
  selector: 'app-alert-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
  providers: [
    defaultFilteredEntityListStoreProvider<AssetsFilter>(
      'alert-assets.component'
    ),
  ],
})
export class AlertAssetsComponent
  extends FilteredEntityListComponent<Asset, AssetsFilter>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() alertType?: string;

  private assetEventsService: EventsService<AssetTracking>;
  displayedColumns: string[] = [];
  customRoute: string;
  homeLocationId: string;

  constructor(
    protected assetsService: AssetsService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultFilteredEntityListStore<AssetsFilter>,
    eventsServiceManager: EventsServiceManager,
    protected breakpointObserver: BreakpointObserver,
    protected locationContextService: LocationContextService,
    route: ActivatedRoute
  ) {
    super(assetsService, store, {});
    // Get the alert type from params, if there is a type update the alert type
    route.paramMap.subscribe((params: ParamMap) => {
      const type = params.get('alert_type');
      if (type) {
        this.updateAlertType(type);
      }
    });
    this.assetEventsService = eventsServiceManager.get(
      'assets.changes',
      assetTrackingDecoder
    );

    this.addSubscription(
      this.locationContextService.locationId$.subscribe((locationId) => {
        this.homeLocationId = locationId;
        this.onFilterChange();
      })
    );
  }

  protected getEntityListFilter(): AssetsFilter {
    return {
      homeLocationId: this.homeLocationId,
      customRoute: this.customRoute,
    };
  }

  getTableId(): string {
    return 'asset-alerts-table';
  }

  updateAlertType(alertType: string) {
    // Update the route to use the alert type
    this.customRoute = `assets/${alertType}`;
    this.filter.customRoute = this.customRoute;
    let columns;
    // Update the displayed columns
    if (alertType === 'orphaned') {
      columns = ['coordinates', 'orphaned'];
    } else if (alertType === 'unidentified') {
      columns = ['location', 'edit'];
    } else {
      columns = ['location', 'heartbeat_status', 'view'];
    }
    this.displayedColumns = [...DEFAULT_COLUMNS, ...columns];
    // Update the entities for the new alert type
    this.entityList.loading = true;
    this.onFilterChange();
  }

  ngOnChanges(): void {
    if (this.alertType) {
      this.updateAlertType(this.alertType);
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.addSubscription(
      this.assetEventsService.subscribe((event: AssetTracking) => {
        const matchingAsset = this.entityList.entities.find(
          (asset) => asset.id === event.id
        );

        if (matchingAsset && matchingAsset.tracking) {
          if (event.location) {
            if (!matchingAsset.tracking.location) {
              matchingAsset.tracking.location = { ...event.location };
            } else {
              Object.assign(matchingAsset.tracking.location, event.location);
            }
          } else {
            matchingAsset.tracking.location = event.location;
          }

          if (event.heartbeat_timestamp) {
            matchingAsset.tracking.heartbeat_timestamp =
              event.heartbeat_timestamp;
          }
          if (event.seconds_since_heartbeat) {
            matchingAsset.tracking.seconds_since_heartbeat =
              event.seconds_since_heartbeat;
          }
          if (event.heartbeat_status) {
            matchingAsset.tracking.heartbeat_status = event.heartbeat_status;
          }
        }
      })
    );
  }
}
