<app-loading-guard [loading]="loading$ | async">
  <div *ngIf="item$ | async as item">
    <mat-card appearance="outlined" class="details-card">
      <dl class="details-list">
        <dt>Context</dt>
        <dd>
          <a [routerLink]="getContextRouterLink(item)">{{
            item.contextName
          }}</a>
        </dd>
        <dt>Name</dt>
        <dd>{{ item.name }}</dd>
        <dt>URL</dt>
        <dd>{{ item.url }}</dd>
        <dt>Enabled</dt>
        <dd>
          <mat-checkbox
            [disabled]="true"
            aria-label="Enabled"
            [checked]="item.enabled"
          ></mat-checkbox>
        </dd>
        <dt>Code</dt>
        <dd>{{ getEventTypesFormatted(item) }}</dd>
        <dt>Failures</dt>
        <dd>{{ item.failures }}</dd>
      </dl>
    </mat-card>

    <mat-card appearance="outlined" class="details-card">
      <div *ngIf="item.recent_results.length === 0">No recent results</div>
      <mat-accordion *ngIf="item.recent_results.length > 0">
        <mat-expansion-panel
          *ngFor="let result of item.recent_results"
          hideToggle
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ result.request.timestamp }}
            </mat-panel-title>

            <mat-panel-description class="justify-content-end">
              Status: {{ getResultStatus(result) }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-tab-group>
            <mat-tab label="Request Headers">
              <div
                class="header-list"
                *ngFor="let header of result.request.headers | keyvalue"
              >
                <div class="header-key">{{ header.key }}</div>
                <div class="header-value">{{ header.value }}</div>
              </div>
            </mat-tab>
            <mat-tab label="Request Body">
              <div class="entity-form">
                <mat-form-field class="form-field">
                  <textarea matInput rows="15">{{
                    getRequestBody(result)
                  }}</textarea>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Response Headers" *ngIf="result.response">
              <div
                class="header-list"
                *ngFor="let header of result.response.headers | keyvalue"
              >
                <div class="header-key">{{ header.key }}</div>
                <div class="header-value">{{ header.value }}</div>
              </div>
            </mat-tab>
            <mat-tab label="Response Body" *ngIf="result.response">
              <div class="entity-form">
                <mat-form-field class="form-field">
                  <textarea matInput rows="15">{{
                    getResponseBody(result)
                  }}</textarea>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="Error Details" *ngIf="result.error">
              <div class="header-list">
                <div class="header-key small-hide">Message</div>
                <div class="header-value">{{ result.error.message }}</div>
              </div>

              <div class="entity-form">
                <mat-form-field class="form-field">
                  <mat-label>Stack trace</mat-label>
                  <textarea matInput rows="15" readonly>{{
                    result.error.backtrace.join('\n')
                  }}</textarea>
                </mat-form-field>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>
</app-loading-guard>
