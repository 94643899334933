import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '../../util/user-data.service';
import { map } from 'rxjs/operators';

/**
 * hasPrivilege checks the privilege map to see if it contains the resouce & action
 * @param privileges User's privilege map
 * @param privilege The concatenated string of the rousource, a slash and the action
 * @returns True if the map contains the specified privilege
 */
function hasPrivilege(privileges: Map<string, string[]>, privilege: string) {
  const [resource, action] = privilege.split('/');
  return privileges?.get(resource)?.includes(action);
}

@Injectable()
export class PrivilegeGuard {
  constructor(
    private userDataService: UserDataService,
    private router: Router
  ) {}

  /**
   * canActivate returns true if the users privileges contains the privilege in the data of the route.
   * If user doesn't have the privilege return a url of the redirectTo string from the route data
   * (returns url to root if no route given).
   *
   * @returns true if user has the privilege, redirect route otherwise
   */
  canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
    if (!hasPrivilege(this.userDataService.privileges, route.data.privilege)) {
      return this.router.parseUrl(route.data.redirectTo || '');
    }
    return true;
  }
}
