import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { SvgIcon } from './svg-icon';

@Injectable({
  providedIn: 'root',
})
export class IconInitializerService {

  trust = url => this.sanitizer.bypassSecurityTrustResourceUrl(url);

  constructor(
    private registry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  initializeSvgIcons(icons: SvgIcon[]) {
    icons.forEach(({iconName, url, options }) => this.registry.addSvgIcon(iconName, this.trust(url), options));
  }
}
