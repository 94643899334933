import { Alert, alertDecoder } from '../../../models/alert';
import {
  HttpServiceBuilder,
  PaginationOptions,
} from '../../../util/http.service';
import { EntityService } from '../../../util/entity.service';
import { Injectable } from '@angular/core';
import { EntityList, Identified } from 'src/app/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertsListCacheService } from './alerts-cache.service';
import { FormattedAlert } from 'src/app/map/overlays/map-page-overlay/formatted-alert';
import { map, switchMap } from 'rxjs/operators';

export interface AlertsFilter {
  locationId: string;
}

export type AlertColor = 'info' | 'warn' | 'alert';

@Injectable({ providedIn: 'root' })
export class AlertsService extends EntityService<Alert, AlertsFilter> {
  /* Cache the alert list for 5 minutes to avoid calls from various entity pages */
  private refreshToken$ = new BehaviorSubject<boolean>(false);

  constructor(
    httpServiceBuilder: HttpServiceBuilder<Alert>,
    private alertsListCache: AlertsListCacheService
  ) {
    super(httpServiceBuilder, 'alerts', alertDecoder);
  }

  getEntityList(
    filter: AlertsFilter,
    pageOptions: PaginationOptions,
    refreshCache: boolean = false
  ): Observable<EntityList<Identified<Alert>>> {
    const context = JSON.stringify({ ...filter, ...pageOptions });
    return this.alertsListCache.getValue(
      context,
      () => super.getEntityList(filter, pageOptions),
      refreshCache
    );
  }

  getEntityListQuery(filter: AlertsFilter): {} {
    const query = {};

    if (filter.locationId) {
      query['location_id'] = filter.locationId;
    }

    return query;
  }

  getAlertColor(alert: Alert): AlertColor {
    switch (alert.alert_type) {
      case 'warning':
        return 'warn';
      case 'info':
        return 'info';

      default:
        return 'alert';
    }
  }

  getAlertIcon(alert: Alert): string {
    if (alert.entity_type === 'asset') {
      if (alert.status === 'critical' || alert.status === 'low') {
        return 'battery_alert';
      } else if (alert.status === 'unidentified') {
        return 'search';
      } else if (alert.status === 'homesick') {
        return 'warning';
      }
    } else if (alert.entity_type.includes('location')) {
      if (alert.status === 'unconfirmed') {
        return 'where_to_vote';
      } else if (alert.status === 'recently_created') {
        return 'add_circle';
      }
    } else if (alert.entity_type.includes('gateway')) {
      if (alert.status === 'offline') {
        // this is the name of the icon and does not have anything
        // to do with the old wifi feature
        return 'signal_wifi_off';
      }
    }

    // handle unknown cases by alert type
    if (alert.alert_type === 'info') {
      return 'info';
    }
    return 'warning';
  }

  // getFormattedAlerts maps the list of alerts to map alerts
  getFormattedAlerts(locationId): Observable<EntityList<FormattedAlert>> {
    return this.refreshToken$.pipe(
      switchMap((refresh) =>
        this.getEntityList(
          { locationId },
          { page: 0, perPage: 100 },
          refresh
        ).pipe(
          map((alerts) => ({
            ...alerts,
            entities: alerts.entities.map((alert) => ({
              id: alert.id,
              message: alert.label,
              value: alert.entity_count,
              color: this.getAlertColor(alert),
              icon: this.getAlertIcon(alert),
              route: `/notifications${alert.url}`,
              // Url Format: (assets|locations|fixed_gateways|mobile_gateways)/:alert_type
              // Same URL used in the alerts router
              alert_type: alert.url.split('/').pop(),
              entity_type: alert.entity_type,
            })),
          }))
        )
      )
    );
  }

  refreshAlerts() {
    this.refreshToken$.next(true);
  }
}
