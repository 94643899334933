<mat-dialog-content class="dialog-content">
  <div class="header">
    <h1 class="webhook-name">{{ data.webhook.name }}</h1>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>
  <form class="entity-form">
    <ng-template [ngIf]="data.global">
      <div class="context">
        <span class="item-label">Context:</span>
        <a
          *hasPrivilege="'organization/read'; else noPriv"
          (click)="openContext(data.webhook)"
        >
          {{ data.webhook.contextName }}
        </a>
        <ng-template #noPriv>
          <span>
            {{ data.webhook.contextName }}
          </span>
        </ng-template>
      </div>
    </ng-template>
    <div class="webhook-code">
      <span class="item-label">Codes:</span>
      <span>
        {{ getEventTypesFormatted(data.webhook) }}
      </span>
    </div>
    <span>URL</span>
    <mat-form-field
      class="form-field kh-no-padding kh-dense-input"
      appFormField
    >
      <input
        [id]="'url-' + data.webhook.id"
        matInput
        type="text"
        [value]="data.webhook.url"
        (change)="handleUrlChange($event, data.webhook)"
        [disabled]="data.readonly"
      />
    </mat-form-field>
    <span>Name</span>
    <mat-form-field
      class="form-field kh-no-padding kh-dense-input"
      appFormField
    >
      <input
        [id]="'name-' + data.webhook.id"
        matInput
        [value]="data.webhook.name"
        (change)="handleNameChange($event, data.webhook)"
        [disabled]="data.readonly"
      />
    </mat-form-field>
    <mat-checkbox
      [id]="'enabled-' + data.webhook.id"
      aria-label="Enabled"
      color="primary"
      [checked]="data.webhook.enabled"
      (change)="handleEnabledToggle($event, data.webhook)"
      [disabled]="data.readonly"
      >Enabled</mat-checkbox
    >
  </form>
  <mat-dialog-actions class="dialog-actions">
    <div class="left-buttons">
      <a mat-flat-button color="primary" (click)="moreDetails()"
        >More Details</a
      >
      <span *ngIf="data.webhook.failures > 0" class="failures-warning">
        <mat-icon
          svgIcon="report_problem"
          [title]="getFailuresLabel(data.webhook)"
          [aria-label]="getFailuresLabel(data.webhook)"
          style="vertical-align: middle"
        ></mat-icon>
        {{ getFailuresLabel(data.webhook) }}
      </span>
    </div>
    <div class="form-buttons">
      <a mat-dialog-close class="cancel-button">Cancel</a>
      <a
        mat-raised-button
        (click)="handleSaveClick($event, data.webhook)"
        [disabled]="
          modifiedWebhook === null || unauthorized_edit[data.webhook.id]
        "
        color="primary"
        >Save</a
      >
    </div>
  </mat-dialog-actions>
</mat-dialog-content>
