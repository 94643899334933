import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AlertsService } from 'src/app/core/services/api/alerts.service';
import { LocationContextService } from 'src/app/layout/location-context/location-context.service';
import { FormattedAlert } from 'src/app/map/overlays/map-page-overlay/formatted-alert';
import { EntityList } from 'src/app/models';

@Component({
  selector: 'app-alerts-centre',
  templateUrl: './alerts-centre.component.html',
  styleUrls: ['./alerts-centre.component.scss'],
})
export class AlertsCentreComponent implements OnInit {
  alerts$: Observable<FormattedAlert[]>;

  constructor(
    private alertsService: AlertsService,
    private locationContext: LocationContextService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Gets the different types of alerts to make navigation buttons
    this.alerts$ = this.locationContext.locationId$.pipe(
      switchMap((locationId) =>
        this.alertsService.getFormattedAlerts(locationId)
      ),
      tap((alerts: EntityList<FormattedAlert>) => {
        if (!alerts.loading && alerts.total === 0) {
          this.router.navigateByUrl('/');
        }
      }),
      map((entityList) => entityList.entities),
      catchError((err) => {
        if (err.message !== 'Unauthorized') {
          throw err;
        }
        this.router.navigateByUrl('/');
        return [];
      })
    );
  }
}
