
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserType } from '../models/user';
import { UserDataService } from '../util/user-data.service';

@Component({
  selector: 'app-payment-required',
  templateUrl: './payment-required.component.html',
  styleUrls: ['./payment-required.component.scss']
})
export class PaymentRequiredComponent implements OnInit {
  
  hasSubscriptionPrivilege: boolean = false;

  constructor(
    private titleService: Title,
    private userDataService: UserDataService
  ) {}
  
  ngOnInit() {
    this.titleService.setTitle('No Subscription | Kahi');
    this.setSubscriptionPrivilege();
  }

  setSubscriptionPrivilege() {
    if(this.userDataService.user.type === UserType.Owner || this.userDataService.user.type === UserType.Manager) {
      this.hasSubscriptionPrivilege = true;
    } else {
      this.hasSubscriptionPrivilege = false;
    }
  }
}
