<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav"
>
  <div class="sidenav-toolbar">
    <div class="brand">
      <img class="logo" src="../../../assets/images/logo.svg" />
      <img class="title" src="../../../assets/images/brand.svg" />
    </div>

    <button
      mat-icon-button
      class="collapse-toggle"
      (click)="toggleCollapsed()"
      *ngIf="isDesktop$ | async"
    >
      <mat-icon>{{
        collapsed ? 'radio_button_unchecked' : 'radio_button_checked '
      }}</mat-icon>
    </button>
  </div>

  <div class="sidenav-items">
    <ng-container *ngFor="let item of navigationItems">
      <app-sidenav-item
        [level]="0"
        [item]="item"
        [collapsed]="collapsed"
      ></app-sidenav-item>
    </ng-container>
    <ng-container *hasPlanTier="'tracking'; invertCheck: true">
      <app-sidenav-item
        *ngFor="let item of inventoryItems"
        [level]="0"
        [item]="item"
        [collapsed]="collapsed"
      ></app-sidenav-item>
    </ng-container>
  </div>

  <ng-container *hasPlanTier="'tracking'; invertCheck: true">
    <ng-container *ngIf="!collapsed || (collapsedOpen$ | async)">
      <div class="upgrade-plan">
        <mat-chip class="new-chip">NEW</mat-chip>
        <span>
          Gain deeper insights on your equipment when you upgrade to
          <b>Smart Asset Tracking </b>
        </span>
        <a
          mat-flat-button
          class="upgrade-plan-btn"
          href="https://kahi.io/pricing"
          target="_blank"
        >
          <mat-icon>sensors</mat-icon> Upgrade Plan
          <mat-icon>arrow_outward</mat-icon>
        </a>
      </div>
    </ng-container>
  </ng-container>

  <a class="support-link" href="https://support.kahi.io/" target="_blank">
    <mat-icon>help</mat-icon>
    <span class="item-label">Support</span>
    <mat-icon>open_in_new</mat-icon>
  </a>

  <div class="sidenav-footer">
    <app-settings-menu [user]="user">
      <mat-icon class="item-icon" svgIcon="settings"></mat-icon>
      <span class="item-label">Settings</span>
    </app-settings-menu>
    <a (click)="onSignOut()">
      <mat-icon class="item-icon" svgIcon="sign_out"></mat-icon>
      <span class="item-label">Sign Out</span>
    </a>
  </div>
</div>
