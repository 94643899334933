import { Component, HostListener, Inject } from '@angular/core';
import { GalleryImages } from '../image-gallery-overlay.service';
import { ImageGalleryOverlayRef } from '../image-gallery-overlay-ref';
import { Image } from '../../../models/image';
import { IMAGE_GALLERY_DIALOG_DATA } from '../image-gallery-overlay.tokens';

@Component({
  selector: 'app-image-gallery-overlay',
  templateUrl: './image-gallery-overlay.component.html',
  styleUrls: ['./image-gallery-overlay.component.scss'],
})
export class ImageGalleryOverlayComponent {
  index: number;
  images: Image[];

  @HostListener('click') onClick = () => {
    this.closeOverlay();
  };

  constructor(
    private overlayRef: ImageGalleryOverlayRef,
    @Inject(IMAGE_GALLERY_DIALOG_DATA) public data: GalleryImages
  ) {
    this.index = data.index;
    this.images = data.images;
    this.preloadImages();
  }

  private preloadImages() {
    this.images.forEach((img) => (new Image().src = <string>img.full_url));
  }
  closeOverlay() {
    this.overlayRef.close();
  }

  previousImage(event) {
    if (this.index > 0) {
      this.index--;
    }

    event.stopPropagation();
  }

  nextImage(event) {
    if (this.index < this.images.length - 1) {
      this.index++;
    }

    event.stopPropagation();
  }

  deleteImage() {
    this.overlayRef.close({ delete: this.images[this.index].id });
  }
}
