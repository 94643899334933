import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImageGalleryOverlayComponent } from './image-gallery-overlay/image-gallery-overlay.component';
import { ImageGalleryOverlayRef } from './image-gallery-overlay-ref';
import { Image } from '../../models/image';
import { IMAGE_GALLERY_DIALOG_DATA } from './image-gallery-overlay.tokens';

export interface GalleryImages {
  images: Image[];
  index: number;
  allow_delete?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ImageGalleryOverlayService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open(data: GalleryImages): ImageGalleryOverlayRef {
    const overlayRef = this.createOverlay();
    const dialogRef = new ImageGalleryOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, data, dialogRef);

    return dialogRef;
  }

  private createOverlay(): OverlayRef {
    const overlayConfig = this.getOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: true,
      positionStrategy,
      width: '100%',
      height: '100%',
    });
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    data: GalleryImages,
    dialogRef: ImageGalleryOverlayRef
  ) {
    const injector = this.createInjector(dialogRef, data);

    const containerPortal = new ComponentPortal(
      ImageGalleryOverlayComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<ImageGalleryOverlayComponent> =
      overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(
    dialogRef: ImageGalleryOverlayRef,
    data: GalleryImages
  ) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: ImageGalleryOverlayRef, useValue: dialogRef },
        { provide: IMAGE_GALLERY_DIALOG_DATA, useValue: data },
      ],
    });
  }
}
