import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Plan } from '../models/plan';
import {
  DefaultEntityListStore,
  EntityListComponent,
  defaultEntityListStoreProvider,
} from '../util/entity-list/entity-list.base';
import { STORE_SERVICE_TOKEN } from '../util/store.service';
import { PlansService } from './plans.service';

@Component({
  selector: 'app-plans-chooser',
  templateUrl: './plans-chooser.component.html',
  styleUrls: ['./plans-chooser.component.scss'],
  providers: [defaultEntityListStoreProvider('plans-chooser.component')],
})
export class PlansChooserComponent
  extends EntityListComponent<Plan, {}>
  implements OnInit, OnDestroy
{
  // The active plan ID
  @Input()
  active: string;

  // Emits events when a plan is chosen
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  select = new EventEmitter();

  // set pending to disabe all buttons
  @Input()
  pending = false;

  constructor(
    plansService: PlansService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultEntityListStore
  ) {
    super(plansService, store);
  }

  getTableId(): string {
    return '';
  }

  handlePlanClick(plan: Plan) {
    this.select.emit(plan);
  }
}
