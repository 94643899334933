import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeletionConfirmationService } from 'src/app/util/confirmation/confirmation.service';
import { STORE_SERVICE_TOKEN } from 'src/app/util/store.service';
import { Webhook, WebhookContext } from '../../../models/webhook';
import {
  DefaultEntityListStore,
  EntityListComponent,
  defaultEntityListStoreProvider,
} from '../../../util/entity-list/entity-list.base';
import { EventsServiceManager } from '../../../util/events.service';
import {
  WebhooksFilter,
  WebhooksService,
  typeSegmentMap,
} from '../../services/webhooks.service';

class WebhookDeletionConfirmationService extends DeletionConfirmationService<Webhook> {
  getConfirmDeleteEntityMessage(webhook: Webhook): string {
    return `Are you sure you want to delete webhook '${webhook.name}'?`;
  }

  getEntityDeletedMessage(webhook: Webhook): string {
    return `Webhook ${webhook.name} deleted`;
  }
}

@Component({
  selector: 'app-webhooks-tab',
  templateUrl: './webhooks-tab.component.html',
  styleUrls: ['./webhooks-tab.component.scss'],
  providers: [defaultEntityListStoreProvider('webhooks-tab.component')],
})
export class WebhooksTabComponent
  extends EntityListComponent<Webhook, WebhooksFilter>
  implements OnInit, OnDestroy
{
  modifiedWebhooks: { string?: Webhook } = {};

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('webhook-type')
  webhookType: WebhookContext;

  // context for the webhooks, e.g. location ID
  @Input()
  context?: string;

  unauthorized_edit: { [key: string]: boolean } = {};

  constructor(
    webhooksService: WebhooksService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultEntityListStore,
    eventsServiceManager: EventsServiceManager,
    dialog: MatDialog
  ) {
    super(
      webhooksService,
      store,
      new WebhookDeletionConfirmationService(
        webhooksService,
        dialog,
        eventsServiceManager
      )
    );
  }

  getCreateRouterLink(): string[] {
    const routePath = typeSegmentMap[this.webhookType].routePath;
    const ctx = this.context ? [routePath, this.context] : [routePath];
    return ['/', ...ctx.flat(), 'webhooks', 'create'];
  }

  getStoreEntityId(): string {
    return `${this.webhookType}${this.context ? `-${this.context}` : ''}`;
  }

  getEntityListFilter(): WebhooksFilter {
    return { type: this.webhookType, entityId: this.context };
  }

  getTableId(): string {
    return 'webhooks-table';
  }
}
