import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WebhookDetailsComponent } from './pages/webhook-details/webhook-details.component';
import { WebhookListComponent } from './components/webhook-list/webhook-list.component';
import { WebhookCreateComponent } from './components/webhook-create/webhook-create.component';
import { WebhooksTabComponent } from './components/webhook-tab/webhooks-tab.component';
import { WebhooksComponent } from './pages/webhooks/webhooks.component';
import { RouterModule } from '@angular/router';
import { WebhookEditComponent } from './components/webhook-edit/webhook-edit.component';

const WEBHOOK_COMPONENTS = [
  WebhookCreateComponent,
  WebhookDetailsComponent,
  WebhookListComponent,
  WebhooksComponent,
  WebhooksTabComponent,
  WebhookEditComponent,
];
@NgModule({
  imports: [RouterModule, SharedModule],
  declarations: [...WEBHOOK_COMPONENTS],
  exports: [...WEBHOOK_COMPONENTS],
})
export class WebhooksComponentModule {}
