import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlanTier, PlanTiers } from '../../models/organization';
import { UserDataService } from '../../util/user-data.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPlanTier]',
})
export class hasPlanTierDirective {
  _planTier: PlanTier;
  _invertCheck: boolean;
  userPlanTiers: PlanTiers;

  hasView = false;

  @Input()
  set hasPlanTier(planTier: PlanTier) {
    this._planTier = planTier;
    this.updateView();
  }

  @Input()
  set hasPlanTierInvertCheck(invertCheck: boolean) {
    this._invertCheck = invertCheck;
    this.updateView();
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    userDataService: UserDataService
  ) {
    this.userPlanTiers = userDataService.organization?.plan_tiers || [];
  }

  updateView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    if (this.checkPlanTiers() && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  hasPlan() {
    return this.userPlanTiers.includes(this._planTier);
  }

  checkPlanTiers(): boolean {
    if (!this._planTier) {
      return false;
    }

    return this.hasPlan() != this._invertCheck;
  }
}
