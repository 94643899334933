<div class="toolbar">
  <div class="label">{{ index + 1 }} / {{ images.length }}</div>
  <div class="actions">
    <button
      *ngIf="data.allow_delete"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <button mat-icon-button (click)="closeOverlay()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-menu #menu>
      <button mat-menu-item (click)="deleteImage()">
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>
</div>

<div class="carousel">
  <div class="button">
    <button mat-icon-button (click)="previousImage($event)" *ngIf="index > 0">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <img [src]="images[index].full_url" (click)="$event.stopPropagation()" />
  <div class="button">
    <button
      mat-icon-button
      *ngIf="index < images.length - 1"
      (click)="nextImage($event)"
    >
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
