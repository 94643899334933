import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Decoder } from 'decoders';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../core/services/api/auth.service';
import { EntityList, Identified } from '../models';
import { EventsService, EventsServiceManager } from './events.service';
import { httpOptions } from './http.util';

export const BASE_URL = environment['base_url'];

// keys that will be ignored if deprecated
const IGNORED_DEPRECATED_KEYS = ['requesting_user', 'time_zone'];

export interface HttpServiceConfig<E, R = E> {
  route: string;
  decoder: Decoder<R>;
  encoder: (entity: E) => {};
}

export interface PaginationOptions {
  page: number;
  perPage: number;
}

export class UnauthorizedError extends Error {
  constructor(m: string) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class UnprocessableError extends Error {
  errors: { [key: string]: string };
  constructor(m: string, errors: { [key: string]: string }) {
    super(m);

    this.errors = errors;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnprocessableError.prototype);
  }
}

export function errorHandler(
  router: Router,
  authService: AuthService,
  errorsService: EventsService<Error>
) {
  return function handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errorsService.pushEvent(new Error(error.error.message));
      return EMPTY;
    }

    if (error.status === 401) {
      console.warn('Authentication error during request, redirecting to login');
      authService.logout();
      authService.setRedirect(router.url);
      router.navigate(['/login']);
      return EMPTY;
    } else if (error.status === 402) {
      errorsService.pushEvent(new Error('Payment required'));
      return EMPTY;
    } else if (error.status === 403) {
      return throwError(new UnauthorizedError('Unauthorized'));
    } else if (error.status === 404) {
      router.navigate(['/notfound']);
      return EMPTY;
    } else if (error.status === 422) {
      return throwError(
        new UnprocessableError(error.message, error.error.errors)
      );
    } else if (error.status && error.error) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    } else if (error.status === 0) {
      errorsService.pushEvent(
        new Error('Error retrieving data, please check internet connection')
      );
      return EMPTY;
    }

    // return an observable with a user-facing error message
    console.error('unknown error: ', error);
    return throwError('Something bad happened; please try again later.');
  };
}

export class HttpService<E, R = E> {
  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected authService: AuthService,
    protected errorsService: EventsService<Error>,
    private config: HttpServiceConfig<E, R>
  ) {}

  private getOne(
    url: string,
    config: HttpServiceConfig<E, R>,
    params = new HttpParams()
  ): Observable<R> {
    return this.http.get(url, { ...httpOptions(), params }).pipe(
      tap(this.checkResponse),
      map((response) => {
        return config.decoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  create(
    entity?: E,
    config?: Partial<HttpServiceConfig<E, R>>,
    params = new HttpParams()
  ): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };
    const data = entity ? c.encoder(entity) : null;
    const url = `${BASE_URL}/${c.route}`;
    return this.http.post(url, data, { ...httpOptions(), params }).pipe(
      tap(this.checkResponse),
      map((response) => {
        return c.decoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  deleteById(
    id: string,
    config?: Partial<HttpServiceConfig<E, R>>,
    query = {}
  ): Observable<boolean> {
    const c = {
      ...this.config,
      ...config,
    };
    const url = `${BASE_URL}/${c.route}/${id}`;
    const params = new HttpParams({
      fromObject: query,
    });

    return this.http
      .delete(url, {
        ...httpOptions(),
        params,
      })
      .pipe(
        map(() => true),
        catchError(
          errorHandler(this.router, this.authService, this.errorsService)
        )
      );
  }

  deleteSingleton(
    config?: Partial<HttpServiceConfig<E, R>>,
    query = {}
  ): Observable<boolean> {
    const c = {
      ...this.config,
      ...config,
    };
    const url = `${BASE_URL}/${c.route}`;
    const params = new HttpParams({
      fromObject: query,
    });

    return this.http
      .delete(url, {
        ...httpOptions(),
        params,
      })
      .pipe(
        map(() => true),
        catchError(
          errorHandler(this.router, this.authService, this.errorsService)
        )
      );
  }

  getById(
    id: string,
    config?: Partial<HttpServiceConfig<E, R>>,
    params?: HttpParams
  ): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };
    return this.getOne(`${BASE_URL}/${c.route}/${id}`, c, params);
  }

  get(config?: Partial<HttpServiceConfig<E, R>>): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };
    return this.getOne(`${BASE_URL}/${c.route}`, c);
  }

  getConfig(): HttpServiceConfig<E, R> {
    return this.config;
  }

  getSingleton(
    config?: Partial<HttpServiceConfig<E, R>>,
    query = {}
  ): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };

    const params = new HttpParams({ fromObject: query });
    return this.getOne(`${BASE_URL}/${c.route}`, c, params);
  }

  search(
    query: {},
    pgOpts?: PaginationOptions,
    config?: Partial<HttpServiceConfig<E, R>>
  ): Observable<EntityList<Identified<E>>> {
    const c = {
      ...this.config,
      ...config,
    };
    const url = `${BASE_URL}/${c.route}`;
    const params = new HttpParams({ fromObject: query });

    const opts = httpOptions();
    if (pgOpts) {
      opts.headers = opts.headers
        .append('X-Page', pgOpts.page.toString())
        .append('X-Per-Page', pgOpts.perPage.toString());
    }

    return this.http
      .get(url, {
        params,
        observe: 'response',
        ...opts,
      })
      .pipe(
        map((response) => {
          const xPerPage = parseInt(response.headers.get('X-Per-Page'), 10);
          const xPage = parseInt(response.headers.get('X-Page'), 10);
          const xTotal = parseInt(response.headers.get('X-Total'), 10);

          if (!response.body.hasOwnProperty('data')) {
            console.error('body must hava a "data" attribute');
          }

          this.checkResponse(response.body);

          const data = response.body['data'] || [];

          return {
            entities: data.map((asset: any) => c.decoder.verify(asset)),
            perPage: xPerPage,
            page: xPage,
            total: xTotal,
            loading: false,
          };
        }),
        catchError(
          errorHandler(this.router, this.authService, this.errorsService)
        )
      );
  }

  private patch(
    url: string,
    entity: E,
    config: HttpServiceConfig<E, R>
  ): Observable<R> {
    const data = config.encoder(entity);
    return this.http.patch(url, data, httpOptions()).pipe(
      tap(this.checkResponse),
      map((response) => {
        return config.decoder.verify(response['data']);
      }),
      catchError(
        errorHandler(this.router, this.authService, this.errorsService)
      )
    );
  }

  /**
   * patchMultiple sends a patch request without requiring an entity with given query and params.
   *
   * @param query parameters to be sent in the request
   * @param config settings for the request such as route and decoders
   * @returns Observable of entitylist of patched assets
   */
  patchMultiple(
    query?: Record<string, any>,
    config?: Partial<HttpServiceConfig<E, R>>
  ): Observable<R[]> {
    const params = new HttpParams({ fromObject: query });
    const c = {
      ...this.config,
      ...config,
    };
    return this.http
      .patch(`${BASE_URL}/${c.route}`, null, { ...httpOptions(), params })
      .pipe(
        tap(this.checkResponse),
        map((response) => {
          const data = response['data'] || [];

          return data.map((asset: any) => c.decoder.verify(asset));
        }),
        catchError(
          errorHandler(this.router, this.authService, this.errorsService)
        )
      );
  }

  /**
   * postMultiple sends a post request without requiring an entity with given query and params.
   *
   * @param query parameters to be sent in the request
   * @param config settings for the request such as route and decoders
   * @returns Observable of entitylist of posted assets
   */
  postMultiple(
    query?: Record<string, any>,
    config?: Partial<HttpServiceConfig<E, R>>
  ): Observable<R[]> {
    const params = new HttpParams({ fromObject: query });
    const c = {
      ...this.config,
      ...config,
    };
    return this.http
      .post(`${BASE_URL}/${c.route}`, null, { ...httpOptions(), params })
      .pipe(
        tap(this.checkResponse),
        map((response) => {
          const data = response['data'] || [];

          return data.map((asset: any) => c.decoder.verify(asset));
        }),
        catchError(
          errorHandler(this.router, this.authService, this.errorsService)
        )
      );
  }

  update(
    id: string,
    entity: E,
    config?: Partial<HttpServiceConfig<E, R>>
  ): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };
    const url = `${BASE_URL}/${c.route}/${id}`;
    return this.patch(url, entity, c);
  }

  updateSingleton(
    entity: E,
    config?: Partial<HttpServiceConfig<E, R>>
  ): Observable<R> {
    const c = {
      ...this.config,
      ...config,
    };
    const url = `${BASE_URL}/${c.route}`;
    return this.patch(url, entity, c);
  }

  checkResponse(response: any) {
    // don't check unless we are in dev mode
    if (!isDevMode()) return;

    const highlightDeprecatedKeys = (deprecated_keys: string[]) => {
      // only highlight keys that are not being ignored
      deprecated_keys = deprecated_keys.filter(
        (key) => !IGNORED_DEPRECATED_KEYS.includes(key)
      );

      if (deprecated_keys.length > 0) {
        const error = new Error(
          `[DEV] Deprecated keys: ${deprecated_keys.join(', ')}`
        );

        if (this.errorsService) {
          this.errorsService.pushEvent(error);
        } else {
          console.error(error);
        }
      }
    };

    if (response && 'data' in response) {
      let item;
      if (Array.isArray(response.data)) {
        item = response.data[0];
      } else if (typeof response.data === 'object') {
        item = response.data;
      }

      if (item && Array.isArray(item.deprecated_keys)) {
        highlightDeprecatedKeys(item.deprecated_keys);
      }
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpServiceBuilder<E, R = E> {
  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private eventsServiceManager: EventsServiceManager
  ) {}

  build(config: HttpServiceConfig<E, R>): HttpService<E, R> {
    return new HttpService<E, R>(
      this.router,
      this.http,
      this.authService,
      this.eventsServiceManager.get('content.errors', new Error()),
      config
    );
  }
}
