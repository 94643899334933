<app-loading-guard [loading]="entityList.loading">
  <div class="asset-list-container">
    <app-asset-alerts-list
      [entityList]="entityList"
      [displayedColumns]="displayedColumns"
      (pageEvent)="onPageEvent($event)"
      (refreshEvent)="onFilterChange()"
    ></app-asset-alerts-list>
  </div>
</app-loading-guard>
