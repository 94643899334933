<mat-toolbar class="header-toolbar">
  <span>
    <button
      mat-icon-button
      *ngIf="(isDesktop$ | async) === false"
      (click)="openSidenav()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    {{ title$ | async }}
    <app-location-context-select></app-location-context-select>
  </span>

  <div *ngIf="user; else loggedOut" class="header-actions">
    <ng-container *hasPlanTier="'tracking'; invertCheck: true">
      <a
        mat-flat-button
        class="upgrade-plan"
        href="https://kahi.io/pricing"
        target="_blank"
      >
        Upgrade Plan <mat-icon>sensors</mat-icon>
      </a>
    </ng-container>
    <app-onboarding-trigger
      *hasPrivilege="'setup_items/show'"
    ></app-onboarding-trigger>
    <app-portal-outlet name="header-action"></app-portal-outlet>

    <app-alert-trigger *hasPrivilege="'alert/read'"></app-alert-trigger>
  </div>
  <ng-template #loggedOut>
    <a mat-button routerLink="/login">Login</a>
  </ng-template>
</mat-toolbar>
