import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gateway, gatewayDecoder } from '../models/gateway';
import { LocationType } from '../models/location';
import { EntityService } from '../util/entity.service';
import { HttpServiceBuilder, HttpServiceConfig } from '../util/http.service';

export type GatewayLocationType = Extract<
  LocationType,
  'fixed' | 'mobile' | 'roving'
>;

export interface GatewayFilter {
  location?: {
    id: string;
    type: GatewayLocationType;
  };
  filter?: 'uninstalled';
  customRoute?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GatewaysService extends EntityService<Gateway, GatewayFilter> {
  constructor(httpServiceBuilder: HttpServiceBuilder<Gateway>) {
    super(httpServiceBuilder, 'gateways', gatewayDecoder);
  }

  getEntityListConfig(filter: GatewayFilter): HttpServiceConfig<Gateway> {
    const config = { ...super.getEntityListConfig(filter) };
    if (filter.customRoute) {
      config.route = filter.customRoute;
    } else if (filter.location) {
      config.route = `${filter.location.type}_locations/${filter.location.id}/gateways`;
    } else if (filter.filter) {
      config.route = config.route + '/uninstalled';
    }

    return config;
  }

  deleteImage(imageId: string): Observable<Boolean> {
    return this.httpService.deleteById(imageId, {
      route: 'gateway_install_images',
    });
  }
}
