import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from 'src/app/models/location';
import { UsersService } from 'src/app/users/users.service';
import { UserDataService } from 'src/app/util/user-data.service';

@Component({
  selector: 'app-location-link',
  templateUrl: './location-link.component.html',
  styleUrls: ['./location-link.component.scss'],
})
export class LocationLinkComponent implements OnChanges {
  @Input() location: Location;

  hasPrivilege: boolean;

  constructor(private userDataService: UserDataService) {}

  getLocationRoute() {
    if (this.location.type === 'fixed') {
      return ['/map', 'warehouses', this.location.id];
    }
    if (this.location.type === 'mobile') {
      return ['/map', 'vehicles', this.location.id];
    }
    return ['/map', 'locations', this.location.id];
  }

  ngOnChanges(): void {
    const privileges = this.userDataService.privileges;
    if (privileges && typeof privileges.has === 'function') {
      const priv = `${this.location.type}_location`;
      this.hasPrivilege =
        privileges.has(priv) && privileges.get(priv).includes('read');
    }
  }
}
