import { Injectable } from '@angular/core';
import {
  HttpService,
  HttpServiceBuilder,
  PaginationOptions,
} from '../../util/http.service';
import { EntityList, identityEncoder } from '../../models';
import { Observable } from 'rxjs';
import {
  webhookDefinitionDecoder,
  WebhookDefinition,
} from '../../models/webhook-definition';

@Injectable({
  providedIn: 'root',
})
export class WebhookDefinitionsService {
  httpService: HttpService<WebhookDefinition>;

  constructor(httpServiceBuilder: HttpServiceBuilder<WebhookDefinition>) {
    this.httpService = httpServiceBuilder.build({
      route: 'webhook_definitions',
      decoder: webhookDefinitionDecoder,
      encoder: identityEncoder,
    });
  }

  getWebhookDefinitions(): Observable<EntityList<WebhookDefinition>> {
    const pageOptions: PaginationOptions = {
      page: 1,
      perPage: 1000,
    };
    return this.httpService.search({}, pageOptions);
  }
}
