import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OnboardingTriggerComponent } from './onboarding-trigger/onboarding-trigger.component';
import { OnboardingChecklistItemComponent } from './onboarding-checklist-item/onboarding-checklist-item.component';
import { OnboardingChecklistComponent } from './onboarding-checklist/onboarding-checklist.component';
import { OnboardingOverlayComponent } from './onboarding-overlay/onboarding-overlay.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    OnboardingChecklistComponent,
    OnboardingChecklistItemComponent,
    OnboardingOverlayComponent,
    OnboardingTriggerComponent,
  ],
  exports: [OnboardingTriggerComponent],
})
export class OnboardingModule {}
