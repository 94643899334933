import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';
import { HasFeatureFlagDirective } from './has-feature-flag.directive';
import { hasPlanTierDirective } from './has-plan-tier.directive';
import { HasPrivilegeDirective } from './has-privilege.directive';
import { RippleOnHoverDirective } from './ripple-on-hover.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AutofocusDirective,
    HasPrivilegeDirective,
    RippleOnHoverDirective,
    HasFeatureFlagDirective,
    hasPlanTierDirective,
  ],
  exports: [
    AutofocusDirective,
    HasPrivilegeDirective,
    HasFeatureFlagDirective,
    RippleOnHoverDirective,
    hasPlanTierDirective,
  ],
})
export class SharedCommonDirectivesModule {}
