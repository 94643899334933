import { Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EntityList, Identified } from 'src/app/models';
import { Alert } from 'src/app/models/alert';

// Time in ms that the cache will expire in
const EXPIRY_TIME = 300 * 1000;

@Injectable({ providedIn: 'root' })
export class AlertsListCacheService {
  currentContext: string;
  expiresAt: number = Date.now() + EXPIRY_TIME;

  alertsListSubj = new BehaviorSubject<EntityList<Identified<Alert>>>({
    entities: [],
    total: 0,
    loading: true,
    perPage: 0,
    page: 0,
  });
  alerts$ = this.alertsListSubj.asObservable();

  /**
   * getValue retrieves the entityList of alerts from cache if all of; the context matches, it is before the expiry time and
   * there is no forced refresh. Otherwise the list is returned from the fallback.
   *
   * @param context Used between calls to determine if the cache needs to be refreshed, should be representation of filters and pageoptions
   * @param fallback Function that is used to update the cache with new alerts if needed
   * @param forceRefresh Force calls the fallback and updates the cache if set to true
   * @returns An observable with the entity list of alerts
   */
  getValue(
    context: string,
    fallback: () => Observable<EntityList<Identified<Alert>>>,
    forceRefresh: boolean
  ): Observable<EntityList<Identified<Alert>>> {
    const isExpired = Date.now() > this.expiresAt;
    if (this.currentContext === context && !forceRefresh && !isExpired) {
      return this.alerts$;
    }

    this.currentContext = context;
    this.expiresAt = Date.now() + EXPIRY_TIME;
    fallback()
      .pipe(
        catchError((error) => {
          this.alertsListSubj.error(error);
          return NEVER;
        })
      )
      .subscribe((list) => {
        this.alertsListSubj.next(list);
      });
    return this.alerts$;
  }

  /**
   * setExpired expires the current cache forcing refresh on next getValue
   */
  setExpired() {
    this.expiresAt = Date.now();
  }
}
