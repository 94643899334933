import { ɵDomSanitizerImpl } from '@angular/platform-browser';
import { Decoder, either, nullable, number, regex, string } from 'decoders';

export type Decodify<X> = {
  [Property in keyof X]: Decoder<X[Property]>;
};

export const isodate = nullable(string).transform((value: string) => {
  if (!value) {
    return null;
  }

  try {
    return new Date(Date.parse(value));
  } catch (err) {
    throw new Error(`Unable to parse date '${value}'`);
  }
});

export const safehtml = nullable(string).transform((value) => {
  if (!value) {
    return null;
  }

  const sanitizer = new ɵDomSanitizerImpl(null);
  return sanitizer.bypassSecurityTrustHtml(value);
});

export const safeurl = nullable(string).transform((value) => {
  if (!value) {
    return null;
  }

  const sanitizer = new ɵDomSanitizerImpl(null);
  return sanitizer.bypassSecurityTrustUrl(value);
});

// Converts numbers wrapped in a string (0 or 1 negative sign, must have 1 digit between 0-9, then 0 or 1 decimals then 0 or more digits between 0-9)
const stringNumber = regex(
  /^-?[0-9]+(\.[0-9]+)?$/,
  'Must be numeric'
).transform((value) => +value);

export const safeNumber = either(number, stringNumber);
