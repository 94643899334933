<div
  [class.is-mobile]="(isDesktop$ | async) === false"
  [class.sidenav-collapsed]="collapsed$ | async"
  class="page-container"
>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      [disableClose]="isDesktop$ | async"
      [fixedInViewport]="(isDesktop$ | async) === false"
      [mode]="(isDesktop$ | async) === false ? 'over' : 'side'"
      [opened]="isDesktop$ | async"
      class="sidenav"
    >
      <app-sidenav
        [collapsed]="collapsed$ | async"
        (signOut)="signOut()"
      ></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <app-header></app-header>
      <app-impersonating-banner></app-impersonating-banner>
      <app-notification-banner
        *hasPrivilege="'alert/read'"
      ></app-notification-banner>
      <main class="content">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
