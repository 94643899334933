import {
  always,
  array,
  maybe,
  nullable,
  number,
  object,
  string,
} from 'decoders';
import { AssetTracking, assetTrackingDecoder } from './asset';
import { Decodify } from './decoders';
import {
  FixedLocationTracking,
  fixedLocationTrackingDecoder,
} from './fixed-location';
import {
  Coordinates,
  LocationTracking,
  LocationType,
  coordinateDecoder,
  locationTypeDecoder,
} from './location';
import {
  MobileLocationTracking,
  mobileLocationTrackingDecoder,
} from './mobile-location';
import { RelevanceStatus, relevanceStatusDecoder } from './relevance-status';
import {
  RemoteLocationTracking,
  remoteLocationTrackingDecoder,
} from './remote-location';
import {
  RovingLocationTracking,
  rovingLocationTrackingDecoder,
} from './roving-location';

export type ArtifactType = LocationType | 'asset';

export interface AbstractArtifact {
  id: string;
  name: string;
  type: ArtifactType;
}

export interface AssetArtifact extends AbstractArtifact {
  tracking?: AssetTracking;
  type: 'asset';
}

export interface LocationArtifact extends AbstractArtifact {
  address?: string;
  num_assets?: number;
  time_zone?: string | null;
  geofence_radius?: number;
  tracking?: LocationTracking;
  relevance_status?: RelevanceStatus;
}

export interface FixedLocationArtifact extends LocationArtifact {
  tracking?: FixedLocationTracking;
  coordinates?: Coordinates;
  type: 'fixed';
}

export interface MobileLocationArtifact extends LocationArtifact {
  tracking?: MobileLocationTracking;
  type: 'mobile';
}

export interface RemoteLocationArtifact extends LocationArtifact {
  tracking?: RemoteLocationTracking;
  coordinates?: Coordinates;
  type: 'remote';
}

export interface RovingLocationArtifact extends LocationArtifact {
  tracking?: RovingLocationTracking;
  coordinates?: Coordinates;
  type: 'roving';
}

export type TypedLocationArtifact =
  | FixedLocationArtifact
  | MobileLocationArtifact
  | RemoteLocationArtifact
  | RovingLocationArtifact;

export type MapArtifact = AssetArtifact | TypedLocationArtifact;

export interface MapArtifacts {
  orphaned_assets: AssetArtifact[];
  fixed_locations: FixedLocationArtifact[];
  remote_locations: RemoteLocationArtifact[];
  mobile_locations: MobileLocationArtifact[];
  roving_locations: RovingLocationArtifact[];
}

export const assetArtifactDecoder = object<Decodify<AssetArtifact>>({
  id: string,
  name: string,
  tracking: maybe(assetTrackingDecoder),
  type: always('asset'),
});

const locationArtifactDecoderObject = {
  id: string,
  name: string,
  address: maybe(string),
  num_assets: maybe(number),
  time_zone: maybe(nullable(string)),
  relevance_status: maybe(relevanceStatusDecoder),
  type: maybe(locationTypeDecoder),
  geofence_radius: maybe(number),
};

export const fixedLocationArtifactDecoder = object<
  Decodify<FixedLocationArtifact>
>({
  ...locationArtifactDecoderObject,
  tracking: maybe(fixedLocationTrackingDecoder),
  coordinates: maybe(coordinateDecoder),
  type: always('fixed'),
});

export const mobileLocationArtifactDecoder = object<
  Decodify<MobileLocationArtifact>
>({
  ...locationArtifactDecoderObject,
  tracking: maybe(mobileLocationTrackingDecoder),
  type: always('mobile'),
});

export const rovingLocationArtifactDecoder = object<
  Decodify<RovingLocationArtifact>
>({
  ...locationArtifactDecoderObject,
  tracking: maybe(rovingLocationTrackingDecoder),
  coordinates: maybe(coordinateDecoder),
  type: always('roving'),
});

export const remoteLocationArtifactDecoder = object<
  Decodify<RemoteLocationArtifact>
>({
  ...locationArtifactDecoderObject,
  tracking: maybe(remoteLocationTrackingDecoder),
  coordinates: maybe(coordinateDecoder),
  type: always('remote'),
});

export const mapArtifactsDecoder = object({
  orphaned_assets: array(assetArtifactDecoder),
  fixed_locations: array(fixedLocationArtifactDecoder),
  mobile_locations: array(mobileLocationArtifactDecoder),
  remote_locations: array(remoteLocationArtifactDecoder),
  roving_locations: array(rovingLocationArtifactDecoder),
});
