import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AlertColor,
  AlertsService,
} from 'src/app/core/services/api/alerts.service';
import { LocationContextService } from 'src/app/layout/location-context/location-context.service';
import { FormattedAlert } from 'src/app/map/overlays/map-page-overlay/formatted-alert';

@Component({
  selector: 'app-alert-trigger',
  templateUrl: './alert-trigger.component.html',
  styleUrls: ['./alert-trigger.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertTriggerComponent implements OnInit {
  alerts$: Observable<FormattedAlert[]>;
  isOpen = false;
  alertLevel: AlertColor = 'info';

  constructor(
    private cdRef: ChangeDetectorRef,
    private locationContext: LocationContextService,
    private alertsService: AlertsService
  ) {}

  toggleOpen(event?: Event) {
    this.isOpen = !this.isOpen;
    event?.stopPropagation();
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    // Gets list of notifications
    this.alerts$ = this.locationContext.locationId$.pipe(
      switchMap((locationId) =>
        this.alertsService.getFormattedAlerts(locationId)
      ),
      map((entityList) => entityList.entities)
    );
    this.alerts$.subscribe((alerts) => {
      // Finds the highest priority alert type
      const alertLevels: AlertColor[] = ['alert', 'warn', 'info'];
      this.alertLevel = alertLevels.find((alertType) =>
        alerts.find((formattedAlert) => formattedAlert.color === alertType)
      );
    });
  }
}
