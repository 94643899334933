import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SubscribedComponent } from './subscribed.component';
import { SubscriptionDetailsComponent } from './subscription-details.component';
import { RouterModule } from '@angular/router';
import { PlansChooserModule } from '../plans/plans-chooser.module';
import { ChangePlanComponent } from './change-plan.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { OrganizationComponentModule } from '../organization/organization-component.module';
import { SubscriptionCreateComponent } from './subscription-create.component';
import { MaterialModule } from '../shared/material/material.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    PlansChooserModule,
    OrganizationComponentModule,
    MaterialModule,
  ],
  declarations: [
    ChangePlanComponent,
    SubscribedComponent,
    SubscriptionDetailsComponent,
    SubscribeComponent,
    SubscriptionCreateComponent,
  ],
  exports: [
    ChangePlanComponent,
    SubscribedComponent,
    SubscriptionDetailsComponent,
    SubscribeComponent,
    SubscriptionCreateComponent,
  ],
})
export class SubscriptionComponentModule {}
