import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { EntityList } from 'src/app/models';
import { Gateway } from 'src/app/models/gateway';

@Component({
  selector: 'app-gateway-alerts-list',
  templateUrl: './gateway-alerts-list.component.html',
  styleUrls: ['./gateway-alerts-list.component.scss'],
})
export class GatewayAlertsListComponent implements OnChanges {
  @Input() entityList: EntityList<Gateway>;
  @Input() displayedColumns: string[];

  @Output() pageEvent = new EventEmitter<any>();

  constructor(private router: Router) {}

  onPageEvent(event) {
    this.pageEvent.emit(event);
  }

  ngOnChanges(): void {
    // If alerts list is empty redirct to the alerts centre page
    if (this.entityList.loading === false && this.entityList.total === 0) {
      this.router.navigateByUrl('/notifications');
    }
  }
}
