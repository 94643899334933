import { Injectable } from '@angular/core';
import {
  Coordinates,
  locationDecoder,
  LocationType,
} from 'src/app/models/location';
import { RelevanceStatus } from 'src/app/models/relevance-status';
import {
  RemoteLocation,
  remoteLocationDecoder,
} from 'src/app/models/remote-location';
import { EntityService } from 'src/app/util/entity.service';
import { HttpServiceBuilder } from 'src/app/util/http.service';

export interface NearestLocationFilter {
  type?: LocationType;
  coordinates?: Coordinates;
  relevanceStatus?: readonly RelevanceStatus[];
  geofenceRadius?: number;
}

@Injectable()
export class NearestLocationService extends EntityService<
  RemoteLocation,
  NearestLocationFilter
> {
  constructor(httpServiceBuilder: HttpServiceBuilder<RemoteLocation>) {
    super(httpServiceBuilder, 'nearest_locations', remoteLocationDecoder);
  }

  getEntityListQuery(filter: NearestLocationFilter): {} {
    const query = {};

    if (filter.relevanceStatus) {
      query['filter[relevance_status][]'] = filter.relevanceStatus;
    }

    if (filter.type) {
      query['filter[type]'] = filter.type;
    }

    if (filter.coordinates) {
      query['reference_latitude'] = filter.coordinates.latitude;
      query['reference_longitude'] = filter.coordinates.longitude;
    }

    if (filter.geofenceRadius) {
      query['geofence_radius_meters'] = filter.geofenceRadius;
    }

    return query;
  }
}
