import {
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify,
} from '@angular/core';
import { UserDataService } from '../../util/user-data.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPrivilege]',
})
export class HasPrivilegeDirective {
  @Input()
  set hasPrivilege(privilegeString: string) {
    this.privilegeString = privilegeString;
    this.updateView();
  }

  @Input()
  set hasPrivilegeElse(templateRef: TemplateRef<any> | null) {
    assertTemplate(' hasPrivilegeElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null;
    this.updateView();
  }

  _privilegedTemplateRef: TemplateRef<any>;
  _privilegedViewRef: EmbeddedViewRef<any>;
  _elseTemplateRef: TemplateRef<any>;
  _elseViewRef: EmbeddedViewRef<any>;

  private privilegeString: string;
  private userPrivileges: Map<string, string[]>;

  constructor(
    private viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    userService: UserDataService
  ) {
    this._privilegedTemplateRef = templateRef;
    this.userPrivileges = userService.privileges;
    this.updateView();
  }

  updateView() {
    if (this.checkPrivilege()) {
      // Occasionally when logging out/forced logged out viewContainer can be unloaded causing a tryerror when calling createEmbeddedView
      if (!this._privilegedViewRef) {
        this._elseViewRef = null;
        try {
          this._privilegedViewRef = this.viewContainer.createEmbeddedView(
            this._privilegedTemplateRef
          );
        } catch (e) {
          if (!(e instanceof TypeError)) {
            throw e;
          }
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._privilegedViewRef = null;
        this.viewContainer.clear();

        if (this._elseTemplateRef) {
          try {
            this._elseViewRef = this.viewContainer.createEmbeddedView(
              this._elseTemplateRef
            );
          } catch (e) {
            if (!(e instanceof TypeError)) {
              throw e;
            }
          }
        }
      }
    }
  }

  checkPrivilege(): boolean {
    if (!this.privilegeString) {
      return true;
    }

    if (
      !this.userPrivileges ||
      !(typeof this.userPrivileges.has === 'function')
    ) {
      return false;
    }
    const [privilege, action] = this.privilegeString.split('/');
    return (
      this.userPrivileges.has(privilege) &&
      this.userPrivileges.get(privilege).includes(action)
    );
  }
}

export function assertTemplate(
  property: string,
  templateRef: TemplateRef<any> | null
): void {
  const isTemplateRefOrNull = !!(
    !templateRef || templateRef.createEmbeddedView
  );
  if (!isTemplateRefOrNull) {
    throw new Error(
      `${property} must be a TemplateRef, but received '${stringify(
        templateRef
      )}'.`
    );
  }
}
