import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format.pipe';
import { PercentageFormatPipe } from './percentage-format.pipe';
import { LocalTimePipe } from './local-time.pipe';
import { NomenclaturePipe } from './nomenclature.pipe';
import { TemperatureFormatPipe } from './temperature-format.pipe';
import { TimeSinceInWordsPipe } from './time-since-in-words.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  imports: [],
  declarations: [
    TimeSinceInWordsPipe,
    TruncatePipe,
    LocalTimePipe,
    DateFormatPipe,
    TemperatureFormatPipe,
    PercentageFormatPipe,
    NomenclaturePipe,
  ],
  exports: [
    TimeSinceInWordsPipe,
    TruncatePipe,
    LocalTimePipe,
    DateFormatPipe,
    TemperatureFormatPipe,
    PercentageFormatPipe,
    NomenclaturePipe,
  ],
})
export class SharedPipeModule {}
