import { constant, either, maybe } from 'decoders';

export enum RelevanceStatus {
  Active = 'active',
  Recent = 'recent',
  Inactive = 'inactive',
  Unconfirmed = 'unconfirmed',
  Ignored = 'ignored',
}

export const ActiveRecentInactive = Object.freeze([
  RelevanceStatus.Active,
  RelevanceStatus.Recent,
  RelevanceStatus.Inactive,
]);

export const relevanceStatusDecoder = maybe(
  either(
    constant('active'),
    constant('recent'),
    constant('inactive'),
    constant('unconfirmed'),
    constant('ignored')
  )
).transform<RelevanceStatus>((status) => {
  switch (status) {
    case RelevanceStatus.Active:
      return RelevanceStatus.Active;
    case RelevanceStatus.Inactive:
      return RelevanceStatus.Inactive;
    case RelevanceStatus.Recent:
      return RelevanceStatus.Recent;
    case RelevanceStatus.Unconfirmed:
      return RelevanceStatus.Unconfirmed;
    case RelevanceStatus.Ignored:
      return RelevanceStatus.Ignored;
    default:
      throw new Error(`Unable to decode relevance status "${status}"`);
  }
});
