import { MapExpressionBuilders } from './map-util';

export const Colors = {
  recent: '#8B89FB',
  active: '#4CC14C',
  inactive: '#47A3F8',
  unconfirmed: '#F5BB61',
  ignored: '#214D7D',
  cluster: '#336894',
  clusterText: '#ffffff',
  asset: '#E25241',
};

export const Icons = {
  Asset: { name: 'asset', url: 'assets/images/icon-track-changes.png' },
  Fixed: { name: 'fixed', url: 'assets/images/icon-home.png' },
  Mobile: { name: 'mobile', url: 'assets/images/icon-local-shipping.png' },
  Roving: { name: 'roving', url: 'assets/images/trailer.png' },
  Remote: { name: 'remote', url: 'assets/images/icon-radio-button.png' },
};

export const Expressions = {
  isCluster: MapExpressionBuilders.propertyEquals('cluster', true),
  isNotCluster: MapExpressionBuilders.propertyNotEquals('cluster', true),
  isActive: MapExpressionBuilders.dataPropertyEquals('asset_status', 'active'),
  isRecent: MapExpressionBuilders.dataPropertyEquals('asset_status', 'recent'),
  isInactive: MapExpressionBuilders.dataPropertyEquals(
    'asset_status',
    'inactive'
  ),
  isIgnored: MapExpressionBuilders.dataPropertyEquals(
    'asset_status',
    'ignored'
  ),
  isUnconfirmed: MapExpressionBuilders.dataPropertyEquals(
    'asset_status',
    'unconfirmed'
  ),
  isFixed: MapExpressionBuilders.dataPropertyEquals('type', 'fixed'),
  isMobile: MapExpressionBuilders.dataPropertyEquals('type', 'mobile'),
  isRoving: MapExpressionBuilders.dataPropertyEquals('type', 'roving'),
  isAssets: MapExpressionBuilders.propertyEquals('type', 'assets'),
};

export const LayerNames = {
  ClusterMarker: 'cluster-markers',
  ClusterMarkerLabel: 'cluster-marker-labels',
  IndividualMarker: 'individual-markers',
};

export const SourceNames = {
  Cluster: 'cluster',
};
