<ng-container *ngIf="gatewayAlert">
  <div class="banner" (click)="goToSupport()">
    <div class="text">
      <span class="icon">🎉</span>
      <span class="label"
        >The new gateways are here! Upgrade your Kahi Hale Warehouse Gateway
        before October 30th. Learn More —>
      </span>
    </div>
    <button (click)="closeBanner()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>
