import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarNotificationComponent } from './snackbar-notification/snackbar-notification.component';


export interface SnackbarNotificationData {
  message: string;
  status: SnackbarStatus;
  actionIcon?: SnackbarIcon;
  actionTitle?: string;
  duration?: number;
}

export type SnackbarStatus = 'error' | 'warn' | 'info' | 'success';

export type SnackbarIcon = 'close' | 'refresh';

@Injectable()
export class SnackbarNotificationService {

  defaultConfig: MatSnackBarConfig<SnackbarNotificationData> = {
    verticalPosition: 'top',
    horizontalPosition: 'end'
  };

  constructor(
    private snackbarService: MatSnackBar,
  ) {}

  show(
    notificationData:SnackbarNotificationData,
    configOverrides?: Omit<MatSnackBarConfig, "data" | "panelClass">
      ): MatSnackBarRef<SnackbarNotificationComponent> {

    const config: MatSnackBarConfig<SnackbarNotificationData> = {
      ...this.defaultConfig,
      ...configOverrides,
      panelClass: ['snackbar-notification-container', `snackbar-notification-${notificationData.status}`],
      data: {
        message: notificationData.message,
        status: notificationData.status,
        actionIcon: notificationData.actionIcon || 'close',
        actionTitle: notificationData.actionIcon === 'refresh' ? 'Click to refresh' : 'Click to close this message',
        duration: configOverrides?.duration
      }
    };

    return this.snackbarService.openFromComponent(SnackbarNotificationComponent, config);
  }
}
