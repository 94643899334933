import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-control-error',
  template: `{{ message }}`,
  styleUrls: []
})
export class DynamicControlErrorComponent {
    @Input() message: string;
}
