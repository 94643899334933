<div>
  <app-loading-guard [loading]="entityList.loading">
    <mat-table
      id="gateway-alerts-table"
      class="kahi-table"
      [dataSource]="entityList.entities"
    >
      <ng-container matColumnDef="external_uid">
        <mat-header-cell *matHeaderCellDef>External ID</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a [routerLink]="'/gateways/' + item.id">{{ item.external_uid }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="platform">
        <mat-header-cell *matHeaderCellDef>Platform</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span *ngIf="item.platform">{{ item.platform }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <app-location-link *ngIf="item.location" [location]="item.location">
            {{ item.location.name }}
          </app-location-link>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="heartbeat_timestamp">
        <mat-header-cell *matHeaderCellDef>Last Seen</mat-header-cell>
        <mat-cell *matCellDef="let item">
          <span
            *ngIf="item.tracking"
            [title]="item.tracking.heartbeat_timestamp"
          >
            {{ item.tracking.heartbeat_timestamp | timeSinceInWords }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item">
          <div class="actions">
            <button
              mat-flat-button
              color="primary"
              [routerLink]="'/gateways/' + item.id"
            >
              View
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        class="header-row"
        *matHeaderRowDef="displayedColumns"
      ></mat-header-row>
      <mat-row
        class="row"
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </app-loading-guard>
  <mat-paginator
    [length]="entityList.total"
    [pageIndex]="entityList.page - 1"
    [pageSize]="entityList.perPage"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onPageEvent($event)"
  ></mat-paginator>
</div>
