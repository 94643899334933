import { CommonModule } from '@angular/common';
import { ControlErrorsComponent } from './control-errors.component';
import { ControlErrorDirective } from './control-error.directive';
import { NgModule } from '@angular/core';
import { ControlErrorOutletDirective } from './control-error-outlet.directive';
import { DynamicControlErrorComponent } from './dynamic-control-error.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ControlErrorsComponent,
    ControlErrorDirective,
    ControlErrorOutletDirective,
    DynamicControlErrorComponent,
  ],
  exports: [ControlErrorsComponent, ControlErrorDirective],
})
export class ControlErrorsModule {}
