import { object, string } from 'decoders';
import { Decodify } from './decoders';

export interface TicketHolder {
  request_id: string;
  ticket_id: string;
}

export const ticketHolderDecoder = object<Decodify<TicketHolder>>({
  request_id: string,
  ticket_id: string,
});
