import { hardcoded, maybe, nullable, number, object, string } from 'decoders';
import { Decodify, isodate } from './decoders';
import { HeartbeatStatus, heartbeatStatusDecoder } from './heartbeat-status';
import {
  Location,
  LocationTracking,
  coordinateDecoder,
  locationObject,
  locationTrackingDecoderObject,
} from './location';
import { FixedLocation, fixedLocationDecoder } from './fixed-location';

export interface RovingLocationTracking extends LocationTracking {
  heartbeat_status?: HeartbeatStatus;
  heartbeat_timestamp?: Date;
  time_zone?: string | null;
  asset_count?: number;
}

export const rovingLocationTrackingDecoder = object<
  Decodify<RovingLocationTracking>
>({
  ...locationTrackingDecoderObject,
  heartbeat_status: maybe(heartbeatStatusDecoder),
  heartbeat_timestamp: maybe(isodate),
  coordinates: maybe(coordinateDecoder),
  time_zone: maybe(nullable(string)),
  asset_count: number,
});

export interface RovingLocation extends Location {
  type: 'roving';
  tracking?: RovingLocationTracking;
  license_plate?: string;
  home_location_id?: string;
  home_location?: FixedLocation;
}

export const rovingLocationDecoder = object<Decodify<RovingLocation>>({
  ...locationObject,
  type: hardcoded('roving'),
  license_plate: maybe(string),
  tracking: maybe(rovingLocationTrackingDecoder),
  home_location: maybe(fixedLocationDecoder),
});
