import { Injectable } from '@angular/core';
import { EventsService, EventsServiceManager } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorEventService {

  private errorService: EventsService<Error>;

  constructor(
    eventsServiceManager: EventsServiceManager,
  ) {
    this.errorService = eventsServiceManager.get('content.errors', new Error());
  }

  pushEvent(error: Error) {
    this.errorService.pushEvent(error);
  }

}
