import { HttpHeaders } from '@angular/common/http';

export function httpOptions(headers = {}) {
  return {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'X-Kahi-Api-Scope': 'tenant',
      'Content-Type': 'application/json',
      Audience: 'kahi-web-app',
      ...headers
    }),
    withCredentials: true
  };
}
