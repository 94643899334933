import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../services/api/auth.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserDataService } from '../../util/user-data.service';
import { ErrorEventService } from '../../util/error-event.service';

@Injectable()
export class AuthGuard  {

  constructor(
    protected authService: AuthService,
    protected userService: UserDataService,
    protected errorService: ErrorEventService,
    protected router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkLogin(state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLogin(state);
  }

  protected checkLogin(state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$.pipe(
      take(1),
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          // Store the attempted URL for redirecting
          this.authService.setRedirect(state.url);

          // Prompt for login
          this.errorService.pushEvent(new Error('Unauthorized: Please log in to continue'));

          // Navigate to the login page with extras
          console.log('navigating to login');
          this.router.navigate(['/login']);
        }
      }),
    );
  }

}
