import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleChartsModule } from 'angular-google-charts';
import { PortalModule } from '../core/portal/portal.module';
import { SharedComponentModule } from './components/shared-component.module';
import { ControlErrorsModule } from './control-errors/control-errors.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { ImageGalleryModule } from './image-gallery/image-gallery.module';
import { MaterialCommonModule } from './material/material-common.module';
import { MaterialModule } from './material/material.module';
import { NotificationModule } from './notification/notification.module';
import { SharedPipeModule } from './pipes/shared-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MaterialCommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentModule,
  ],
  declarations: [],
  exports: [
    // Common Modules
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleChartsModule,
    PortalModule,
    NotificationModule,
    MaterialModule,
    MaterialCommonModule,

    // Shared components and directives
    SharedComponentModule,
    ImageGalleryModule,
    ControlErrorsModule,
    SharedDirectivesModule,
    SharedPipeModule,
  ],
})
export class SharedModule {}
