import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../../environments/environment';
import { IsSubscribedService } from '../../../core/services/api/is-subscribed.service';
import { ImpersonateDataService } from '../../../impersonate/impersonate-data.service';
import { User, userDecoder } from '../../../models/user';
import { httpOptions } from '../../../util/http.util';
import { UserDataService } from '../../../util/user-data.service';
import { AlertsListCacheService } from './alerts-cache.service';

export interface AuthEvent {
  type: string;
  isLoggedIn: boolean;
}

interface LoginDetail {
  user: User;
  impersonation?: ImpersonationDetail;
}

interface ImpersonationDetail {
  impersonatedUserEmail: string;
  impersonatingUserEmail: string;
}

const BASE_URL = environment['base_url'];
const LOGIN_SYNC_KEY = 'kahi-login-sync';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedInId?: string = null;

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  // store the URL so we can redirect after logging in
  private redirectUrl: string;

  constructor(
    private http: HttpClient,
    private userDataService: UserDataService,
    private impersonateData: ImpersonateDataService,
    private router: Router,
    private alertsListCache: AlertsListCacheService,
    private isSubscribedService: IsSubscribedService
  ) {
    // Since there can only be one active login session at a time, logged in user must be synchronized across tabs
    window.addEventListener('storage', (event) => {
      if (event.key === LOGIN_SYNC_KEY) {
        if (event.newValue === null) {
          console.log('Logout detected from other tab');
          this.clearLoggedInUser();
          this.redirectLogout();
        } else {
          console.log('Login detected from other tab');
          const detail = JSON.parse(event.newValue);
          if (this.loggedInId !== detail.user.id) {
            console.log('Authenticated user changed');
            this.initializeLogin(detail);
            // location.replace ensures any on screen data will be reloaded
            location.replace('/');
          }
        }
      }
    });
  }

  initializeAuth(): Observable<boolean> {
    return this.http
      .get(`${BASE_URL}/auth/settings`, {
        ...httpOptions(),
        observe: 'response',
      })
      .pipe(
        map((res) => {
          const loginDetail = {
            user: userDecoder.verify(res['body']['data']),
            impersonation: this.getImpersonationDetail(res),
          };

          this.initializeLogin(loginDetail);
          this.synchronizeLogin(loginDetail);
          return true;
        }),
        catchError((err) => {
          console.error(err);
          this.clearLoggedInUser();
          return of(false);
        })
      );
  }

  private getImpersonationDetail(res): ImpersonationDetail {
    const impersonatingUserEmail = res.headers.get(
      'x-kahi-impersonating-user-email'
    );
    const impersonatedUserEmail = res.headers.get(
      'x-kahi-impersonated-user-email'
    );

    if (impersonatingUserEmail && impersonatedUserEmail) {
      return { impersonatingUserEmail, impersonatedUserEmail };
    }
    return null;
  }

  login(email: string, password: string): Observable<boolean> {
    return this.http
      .post<User>(
        `${BASE_URL}/auth/login`,
        { email, password },
        {
          observe: 'response',
          ...httpOptions(),
        }
      )
      .pipe(
        switchMap((res) => {
          if (res['status'] === 201 || res['status'] === 200) {
            return this.initializeAuth().pipe(
              tap((loggedin) => {
                if (loggedin) {
                  this.redirectLogin();
                }
              })
            );
          }
          return of(false);
        })
      );
  }

  logout(endImpersonation = false): void {
    this.isLoggedIn$
      .pipe(
        take(1),
        switchMap((isLoggedIn) =>
          isLoggedIn
            ? this.http.delete(`${BASE_URL}/auth/logout`, httpOptions())
            : EMPTY
        ),
        switchMap(() => this.initializeAuth()),
        catchError((err) => {
          console.error(err);
          return of(false);
        })
      )
      .subscribe((remainingLogin) => {
        if (remainingLogin) {
          // location.replace ensures all data is reloaded
          location.replace('/');
        } else {
          this.clearLoggedInUser();
          this.synchronizeLogout();
          this.redirectLogout();
        }

        if (endImpersonation) {
          window.close();
        }
      });
  }

  private initializeLogin(detail: LoginDetail) {
    this.updateLoggedInUser(detail.user);
    if (detail.impersonation) {
      this.impersonateData.setImpersonation(
        detail.impersonation.impersonatingUserEmail,
        detail.impersonation.impersonatedUserEmail
      );
    } else {
      this.impersonateData.clearImpersonation();
    }

    const sentryUser: any = {
      id: detail.user.id,
      username: detail.user.email,
      email: detail.user.email,
      ip_address: '{{auto}}',
      organization_name: detail.user.organization
        ? detail.user.organization.name
        : 'N/A',
      organization_id: detail.user.organization
        ? detail.user.organization.id
        : 'N/A',
    };

    if (detail.impersonation) {
      sentryUser.impersonating_user_email =
        detail.impersonation.impersonatingUserEmail;
      sentryUser.impersonated_user_email =
        detail.impersonation.impersonatedUserEmail;
    }

    Sentry.setUser(sentryUser);
  }

  private synchronizeLogin(detail: LoginDetail) {
    window.localStorage.setItem(LOGIN_SYNC_KEY, JSON.stringify(detail));
  }

  private synchronizeLogout() {
    window.localStorage.removeItem(LOGIN_SYNC_KEY);
  }

  private updateLoggedInUser(user: User) {
    this.loggedInId = user.id;
    this.isLoggedInSubject.next(true);
    this.userDataService.setUser(user);
    this.isSubscribedService.setIsSubscribed(
      user.organization.subscription_state
    );
  }

  private clearLoggedInUser() {
    this.loggedInId = null;
    this.userDataService.clearUser();
    this.alertsListCache.setExpired();
    this.isLoggedInSubject.next(false);
    this.impersonateData.clearImpersonation();
    Sentry.setUser(null);
    this.isSubscribedService.setIsSubscribed(null);
  }

  private redirectLogout() {
    this.router.navigateByUrl('/login');
  }

  redirectLogin() {
    // Get the redirect URL from our auth service
    // If no redirect has been set, use the root
    const redirect =
      this.redirectUrl && this.redirectUrl !== '/login'
        ? this.redirectUrl
        : '/';

    this.redirectUrl = null;

    this.router.navigate([redirect]);
  }

  setRedirect(redirectUrl: string): void {
    this.redirectUrl = redirectUrl;
  }
}
