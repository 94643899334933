import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { UserDataService } from '../../util/user-data.service';

@Injectable()
export class FeatureFlagGuard {
  constructor(
    private userDataService: UserDataService,
    private router: Router
  ) {}

  /**
   * If the feature flag is enable (Or disabled if the flag is negated) allow user to access route, otherwise
   * returns the redirect route (Or an home route if no redirect route is provided)
   *
   * @returns True if feature flag is enabled, otherwise returns the redirect route
   */
  canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
    if (!route.data.featureFlag) return true;
    // Get the feature flag slug from the route data, if negated remove the negation and set enabled to false
    const enabled = !route.data.featureFlag?.startsWith('!');
    const featureFlagSlug = enabled
      ? route.data.featureFlag
      : route.data.featureFlag?.slice(1);

    const featureFlag = this.userDataService.featureFlags.find(
      (flag) => flag.slug === featureFlagSlug
    );
    const shouldAllow = !!featureFlag && featureFlag.enabled === enabled;
    return shouldAllow || this.router.parseUrl(route.data.redirectTo || '');
  }
}
