import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../core/services/api/auth.service';
import { ImpersonateDataService } from '../impersonate/impersonate-data.service';
import { assetTrackingDecoder } from '../models/asset';
import { gatewayTrackingDecoder } from '../models/gateway';
import { locationTrackingDecoder } from '../models/location';
import { ProfileService } from '../profile/profile.service';
import { ChannelSubscriberService } from '../util/channel-subscriber.service';
import { UserDataService } from '../util/user-data.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
  isImpersonating$ = this.impersonationDataService.isImpersonating$;
  hasUnsignedAgreement = !!this.userDataService.organization.unsigned_agreement;

  subscriptionState = this.userDataService.organization.subscription_state;

  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private profileService: ProfileService,
    private impersonationDataService: ImpersonateDataService,
    private channelSubscriberService: ChannelSubscriberService
  ) {}

  onAgreementSigned() {
    this.profileService.getEntity().subscribe((user) => {
      this.userDataService.setUser(user);
    });
  }

  onSignOut() {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.channelSubscriberService.initializeWebsocket(
      assetTrackingDecoder,
      '{"channel":"AssetTrackingChangesChannel"}',
      'assets.changes'
    );
    this.channelSubscriberService.initializeWebsocket(
      gatewayTrackingDecoder,
      '{"channel":"GatewayTrackingChangesChannel"}',
      'gateways.changes'
    );
    this.channelSubscriberService.initializeWebsocket(
      locationTrackingDecoder,
      '{"channel":"LocationTrackingChangesChannel"}',
      'locations.changes'
    );
  }

  ngOnDestroy(): void {
    this.channelSubscriberService.destroyWebsockets();
  }

  allowIntoApp() {
    if (this.subscriptionState === 'subscriber') {
      return true;
    } else if (this.subscriptionState === 'past_due') {
      return true;
    }

    return false;
  }
}
