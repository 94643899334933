import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { SegmentModule } from 'ngx-segment-analytics';
import { FeatureFlagGuard } from 'src/app/core/guards/featureFlag.guard';
import { PrivilegeGuard } from 'src/app/core/guards/privilege.guard';
import { environment } from 'src/environments/environment';
import { AgreementComponent } from './agreement/agreement.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentComponent } from './content/content.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SubscriptionGuard } from './core/guards/subscription.guard';
import { AuthService } from './core/services/api/auth.service';
import { LayoutModule } from './layout/layout.module';
import { NotfoundComponent } from './notfound/notfound.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { OrganizationComponentModule } from './organization/organization-component.module';
import { PaymentRequiredComponent } from './payment-required/payment-required.component';
import { SharedModule } from './shared/shared.module';
import { SubscriptionComponentModule } from './subscription/subscription-component.module';

function authInitializerFactory(authService: AuthService) {
  console.log('initializing auth');
  return () => authService.initializeAuth().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    NotfoundComponent,
    PaymentRequiredComponent,
    AgreementComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    OrganizationComponentModule,
    SubscriptionComponentModule,
    OnboardingModule,
    LayoutModule,
    SegmentModule.forRoot({
      loadOnInitialization: false,
      segmentHost: 'https://cdn.segment.com',
    }),
  ],
  providers: [
    AuthGuard,
    SubscriptionGuard,
    FeatureFlagGuard,
    PrivilegeGuard,
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.show_crash_dialog,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializerFactory,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
