<a
  *ngIf="item && item.type === 'link'"
  class="item"
  [routerLink]="item.route"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: item.ignoreChildren }"
  matRipple
  (click)="onLinkClicked()"
>
  <div class="item-icon-wrapper">
    <mat-icon *ngIf="level === 0" class="item-icon" [svgIcon]="item.svgIcon">{{
      item.icon
    }}</mat-icon>
  </div>

  <span class="item-label">{{ getLabel(item) }}</span>
</a>

<ng-container *ngIf="item && item.type === 'dropdown'">
  <div
    (click)="toggleOpen()"
    class="item"
    [class.active]="isActive"
    [class.open]="isOpen"
  >
    <div class="item-dropdown">
      <div class="item-icon-wrapper">
        <mat-icon class="item-icon">{{
          level === 0 ? item.icon : ''
        }}</mat-icon>
      </div>

      <span class="item-label">{{ getLabel(item) }}</span>

      <mat-icon class="item-dropdown-icon">keyboard_arrow_right</mat-icon>
    </div>
  </div>

  <div class="dropdown" [class.open]="isOpen" *ngIf="showChildren$ | async">
    <ng-container *ngFor="let child of item.children">
      <app-sidenav-item
        *hasPrivilege="child.privilege"
        [level]="level + 1"
        [item]="child"
        [collapsed]="collapsed"
      ></app-sidenav-item>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="item && item.type === 'inventory'">
  <div
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    (mouseenter)="openMenu(menuTrigger)"
    (mouseleave)="closeMenu(menuTrigger)"
    class="item inventory-item"
    matRipple
  >
    <div class="item-icon-wrapper inventory-icon">
      <mat-icon
        *ngIf="level === 0"
        class="item-icon"
        [svgIcon]="item.svgIcon"
        >{{ item.icon }}</mat-icon
      >
    </div>

    <span class="item-label">{{ getLabel(item) }}</span>
  </div>
  <mat-menu
    #menu="matMenu"
    [hasBackdrop]="false"
    class="menu-remove-styles menu-show-overflow mat-elevation-z4"
  >
    <div
      (mouseleave)="closeMenu(menuTrigger)"
      (mouseenter)="openMenu(menuTrigger)"
      class="upgrade-plan-menu"
    >
      <div class="top-diamond"></div>
      <span>
        Gain deeper insights on your equipment when you upgrade to Smart Asset
        Tracking
      </span>
      <div class="actions">
        <button mat-button (click)="closeMenu(menuTrigger)">Dismiss</button>
        <a
          mat-flat-button
          href="https://kahi.io/pricing"
          target="_blank"
          rippleOnHover
        >
          Upgrade
        </a>
      </div>
    </div>
  </mat-menu>
</ng-container>
