import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';

@NgModule({
  imports: [SharedModule],
  declarations: [DocumentsListComponent],
  exports: [DocumentsListComponent],
})
export class DocumentsModule {}
