import { HttpServiceBuilder, HttpServiceConfig } from './http.service';
import { Decoder } from 'decoders';
import { Observable } from 'rxjs';
import { EntityService } from './entity.service';

export class SingletonEntityService<E> extends EntityService<E, {}> {
  constructor(
    httpServiceBuilder: HttpServiceBuilder<E>,
    route: string,
    decoder: Decoder<E>
  ) {
    super(httpServiceBuilder, route, decoder);
  }

  getEntity(): Observable<E> {
    const config = this.getGetConfig();
    return this.httpService.getSingleton(config);
  }

  getGetConfig(): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  updateEntity(entity: E): Observable<E> {
    const config = this.getUpdateConfig(entity);
    return this.httpService.updateSingleton(entity, config);
  }

  getUpdateConfig(entity: E): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }
}
