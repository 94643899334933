import { Entity } from './index';
import { number, object, string } from 'decoders';
import { Decodify } from './decoders';

export interface Alert extends Entity {
  label: string;
  alert_type: string;
  entity_type: string;
  status: string;
  entity_count: number;
  url: string;
}

export const alertDecoder = object<Decodify<Alert>>({
  label: string,
  alert_type: string,
  entity_type: string,
  status: string,
  entity_count: number,
  url: string,
});
