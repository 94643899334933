import { NavigationConfig } from './layout/navigation/navigation-config';
import { NavigationItem } from './layout/navigation/navigation-item';
import { FeatureFlag } from './models/user';

export const AppNavigationConfig: NavigationConfig = {
  items: [
    {
      type: 'link',
      label: 'Dashboard',
      route: '/dashboard',
      privilege: 'vital/read',
      featureFlag: 'vitals-dashboard',
      icon: 'space_dashboard',
      ignoreChildren: true,
    },
    {
      type: 'link',
      label: 'Map',
      route: '/map',
      privilege: 'map/read',
      icon: 'language',
      ignoreChildren: true,
    },
    {
      type: 'link',
      label: 'Assets',
      route: '/assets',
      privilege: 'asset/read',
      svgIcon: 'assets',
      featureFlag: '!inventory-asset-view',
    },
    {
      type: 'link',
      label: 'Assets',
      route: '/inventory',
      featureFlag: 'inventory-asset-view',
      svgIcon: 'assets',
    },
    {
      type: 'dropdown',
      label: 'Locations',
      apiLabel: 'remote_location:plural',
      icon: 'radio_button_checked',
      privilege: 'remote_location/read',
      children: [
        {
          type: 'link',
          label: 'Active',
          privilege: 'remote_location/read',
          route: '/map/locations/active',
        },
        {
          type: 'link',
          label: 'Recent',
          privilege: 'remote_location/read',
          route: '/map/locations/recent',
        },
        {
          type: 'link',
          label: 'Unconfirmed',
          privilege: 'remote_location/read',
          route: 'map/locations/unconfirmed',
        },
      ],
    },
    {
      type: 'link',
      label: 'Warehouses',
      apiLabel: 'fixed_location:plural',
      route: '/map/warehouses/active',
      privilege: 'fixed_location/read',
      icon: 'home',
    },
    {
      type: 'link',
      label: 'Vehicles',
      apiLabel: 'mobile_location:plural',
      route: '/map/vehicles/tracked',
      privilege: 'mobile_location/read',
      icon: 'local_shipping',
    },
    {
      type: 'link',
      label: 'Trailers',
      apiLabel: 'roving_location:plural',
      route: '/map/trailers/tracked',
      privilege: 'roving_location/read',
      icon: 'assistant_navigation',
      featureFlag: 'trailers-view',
    },
    {
      type: 'link',
      label: 'Reports',
      privilege: 'report/read',
      icon: 'description',
      route: '/reports',
    },
  ],
  inventoryItems: [
    {
      type: 'inventory',
      label: 'Vehicles',
      icon: 'local_shipping',
    },
  ],
};

export const filterNavigationItems = (
  items: NavigationItem[],
  privileges: Map<string, string[]>,
  featureFlags: FeatureFlag[]
): NavigationItem[] =>
  (items || []).filter((link) => {
    let hasPrivilege = true;
    if (link.privilege) {
      const [privilege, action] = link.privilege.split('/');
      hasPrivilege = privileges?.get(privilege)?.includes(action);
    }

    let hasFeatureFlag = true;
    if (link.featureFlag) {
      const enabled = !link.featureFlag.startsWith('!');
      const featureFlagSlug = enabled
        ? link.featureFlag
        : link.featureFlag.slice(1);
      hasFeatureFlag = featureFlags?.some((flag) => {
        return flag.enabled === enabled && flag.slug === featureFlagSlug;
      });
    }

    return hasPrivilege && hasFeatureFlag;
  });
