<ng-container *ngIf="alerts$ | async as alerts">
  <button
    mat-icon-button
    [matMenuTriggerFor]="notificationPanel"
    (click)="toggleOpen($event)"
    *ngIf="alerts.length > 0"
  >
    <mat-icon
      [class]="alertLevel"
      matBadge="1"
      matBadgeSize="small"
      matBadgeColor="primary"
      >notifications</mat-icon
    >
  </button>

  <mat-menu
    #notificationPanel="matMenu"
    xPosition="before"
    yPosition="below"
    class="notification-menu"
  >
    <app-alert-notification-panel
      [alerts]="alerts"
      (closeEvent)="toggleOpen()"
    ></app-alert-notification-panel>
  </mat-menu>
</ng-container>
