import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-loading-guard',
  templateUrl: './loading-guard.component.html',
  styleUrls: ['loading-guard.component.scss'],
})
export class LoadingGuardComponent {

  @Input() loading: boolean;
  @Input() loaderTemplate: TemplateRef<any>;

}
