import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MessageEventService } from 'src/app/util/message-event.service';
import { ReactiveEntityCreatePageComponent } from 'src/app/util/reactive-entity-create-page.base';
import { EntityList } from '../../../models';
import { Webhook, WebhookContext } from '../../../models/webhook';
import { WebhookDefinition } from '../../../models/webhook-definition';
import { WebhookDefinitionsService } from '../../services/webhook-definitions.service';
import {
  WebhookForm,
  WebhookFormService,
} from '../../services/webhooks-form.service';
import { WebhooksService } from '../../services/webhooks.service';

@Component({
  selector: 'app-webhook-create',
  templateUrl: './webhook-create.component.html',
  styleUrls: ['./webhook-create.component.scss'],
  providers: [WebhookFormService],
})
export class WebhookCreateComponent
  extends ReactiveEntityCreatePageComponent<Webhook, WebhookForm>
  implements OnInit, OnDestroy
{
  definitions: WebhookDefinition[] = [];

  webhookType: WebhookContext;
  webhookEntityId: string;

  constructor(
    private route: ActivatedRoute,
    webhookService: WebhooksService,
    private webhookDefinitionsService: WebhookDefinitionsService,
    private webhookFormService: WebhookFormService,
    messageService: MessageEventService,
    router: Router,
    titleService: Title
  ) {
    super(
      webhookService,
      messageService,
      router,
      titleService,
      webhookFormService
    );
  }

  getEntityCreatedMessage(createdWebhook: Webhook): string {
    return `Webhook '${createdWebhook.name}' created`;
  }

  getPostCreateRoute(): string[] {
    return this.getReturnPath();
  }

  getTitle(): string {
    return 'Create Webhook';
  }

  getReturnPath(): string[] {
    switch (this.webhookType) {
      case 'Asset':
        return ['/', 'assets', this.webhookEntityId];
      case 'FixedLocation':
        return ['/', 'locations', 'warehouse', this.webhookEntityId];
      case 'Gateway':
        return ['/', 'gateways', this.webhookEntityId];
      case 'Location':
      case 'RemoteLocation':
        return ['/', 'locations', this.webhookEntityId];
      case 'MobileLocation':
        return ['/', 'locations', 'vehicle', this.webhookEntityId];
      case 'Organization':
        return ['/', 'organization', 'webhooks'];
      case 'Beacon':
      default:
        return ['/'];
    }
  }
  protected cancelCreate() {
    this.router.navigate(this.getReturnPath());
  }

  onUpdateEnabled(event: MatSlideToggleChange) {
    this.form.controls.enabled.setValue(event.checked);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscription.add(
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.webhookEntityId = params.get('id');

        this.webhookFormService.setWebhookEntityId(this.webhookEntityId);
      })
    );
    this.subscription.add(
      this.route.data.subscribe((v) => {
        this.webhookType = v.type;

        this.webhookFormService.setWebhookType(this.webhookType);

        this.titleService.setTitle(`Create ${v.type} Webhook | Kahi`);

        this.subscription.add(
          this.webhookDefinitionsService
            .getWebhookDefinitions()
            .subscribe((webhookDefinitions: EntityList<WebhookDefinition>) => {
              webhookDefinitions.entities.forEach((definition) => {
                if (definition.context.includes(v.type)) {
                  this.definitions.push(definition);
                }
              });
            })
        );
      })
    );
  }
}
