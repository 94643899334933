import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { EventsService, EventsServiceManager } from './events.service';

export interface MessageEvent {
  message: string;
  status: MessageEventStatus;
  snackBarConfig?: MatSnackBarConfig;
}

export type MessageEventStatus = 'error' | 'warn' | 'info' | 'success';
@Injectable({
  providedIn: 'root',
})
export class MessageEventService {
  private messageService: EventsService<MessageEvent>;

  constructor(eventsServiceManager: EventsServiceManager) {
    this.messageService = eventsServiceManager.get<MessageEvent>(
      'content.messages',
      {
        message: '',
        status: 'success',
      }
    );
  }

  pushEvent(event: MessageEvent) {
    if (event.status === 'success') {
      event.snackBarConfig = {
        ...event.snackBarConfig,
        duration: 5000,
      };
    }
    this.messageService.pushEvent(event);
  }
}
