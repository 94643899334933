import { Entity, Identified } from '../../models/index';

type Constructor = new (...args: any[]) => {};

/**
 * Tracks the state of a row.
 */
export type Row = {
  hover: boolean;
  clicked: boolean;
};

export function actionsTableMixin<TBase extends Constructor>(Base: TBase) {
  return class ActionTabling extends Base {
    /**
     * A map of report id to row state.
     */
    rows: Record<string, Row>;

    /**
     * Determines if a given row is hovered, or if any row is hover if no report is given.
     * @param entity The entity that is shown in the row
     * @returns true if a given row is hovered, or if any row is hover if no report is given
     */
    isRowHovered(entity?: Identified<Entity>) {
      if (entity) {
        const row = this.rows[entity.id];
        return row.hover || row.clicked;
      }
      return Object.values(this.rows).some((row) => row.hover || row.clicked);
    }

    /**
     * Handles a click on the actions button.
     * @param event The mouse event
     * @param entity The entity that is shown in the row
     */
    handleActionsClick(event: MouseEvent, entity: Identified<Entity>) {
      // prevent bubbling to avoid selecting the row
      event.stopPropagation();

      this.rows[entity.id].clicked = true;
    }

    /**
     * When the mouse is over a row, set its hover state to true
     * @param entity The entity that is shown in the row
     */
    handleRowMouseOver(entity: Identified<Entity>) {
      this.rows[entity.id].hover = true;
    }

    /**
     * When the mouse is over a row, set its hover state to true
     * @param entity The entity that is shown in the row
     */
    handleRowMouseOut(entity: Identified<Entity>) {
      this.rows[entity.id].hover = false;
    }

    /**
     * Reset the clicked state when a menu is closed
     * @param entity The entity that is shown in the row
     */
    handleMenuClose(entity: Identified<Entity>) {
      this.rows[entity.id].clicked = false;
    }

    /** Update the rows using the given entities */
    updateRowsFromEntityList(entities: Identified<Entity>[]) {
      this.rows = entities.reduce((prev, curr) => {
        prev[curr.id] = {
          hover: false,
          clicked: false,
        };
        return prev;
      }, {} as Record<string, Row>);
    }
  };
}
