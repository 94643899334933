import { hardcoded, maybe, object } from 'decoders';
import { Decodify, isodate } from './decoders';
import {
  coordinateDecoder,
  Coordinates,
  Location,
  locationObject,
  LocationTracking,
  locationTrackingDecoderObject,
} from './location';
import { HeartbeatStatus, heartbeatStatusDecoder } from './heartbeat-status';

export interface FixedLocationTracking extends LocationTracking {
  heartbeat_status?: HeartbeatStatus;
  heartbeat_timestamp?: Date;
}

export const fixedLocationTrackingDecoder = object<
  Decodify<FixedLocationTracking>
>({
  ...locationTrackingDecoderObject,
  heartbeat_status: maybe(heartbeatStatusDecoder),
  heartbeat_timestamp: maybe(isodate),
});

export interface FixedLocation extends Location {
  type: 'fixed';
  tracking?: FixedLocationTracking;
  coordinates?: Coordinates;
}

export const fixedLocationDecoder = object<Decodify<FixedLocation>>({
  ...locationObject,
  type: hardcoded('fixed'),
  tracking: maybe(fixedLocationTrackingDecoder),
  coordinates: maybe(coordinateDecoder),
});
