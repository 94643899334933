<mat-card appearance="outlined" class="details-card">
  <ng-template #noRead>
    <app-authorized-guard
      [authorized]="false"
      message="You are not authorized to view this data"
    ></app-authorized-guard>
  </ng-template>
  <mat-card-content *hasPrivilege="'webhook/read'; else noRead">
    <div class="table-wrapper">
      <app-loading-guard [loading]="entityList.loading">
        <app-portal
          name="organization-actions"
          *ngIf="webhookType === 'Organization'; else header"
        >
          <div class="webhook-actions">
            <button
              *hasPrivilege="'webhook/read'"
              mat-raised-button
              [routerLink]="['/organization', 'webhooks', 'signing_key']"
              color="primary"
              class="webhook-action"
            >
            Webhook Signing Key
            </button>
            <button
              *hasPrivilege="'webhook/create'"
              mat-raised-button
              [routerLink]="getCreateRouterLink()"
              color="accent"
              class="webhook-action"
            >
            NEW WEBHOOK
            </button>
          </div>
        </app-portal>
        <ng-template #header>
          <mat-toolbar class="page-header">
            <div class="button-bar">
              <a
                *hasPrivilege="'webhook/create'"
                mat-raised-button
                [routerLink]="getCreateRouterLink()"
              >
                Create Webhook
              </a>
              <a
                *hasPrivilege="'webhook/read'"
                mat-raised-button
                [routerLink]="['/organization', 'webhooks', 'signing_key']"
              >
                Webhook Signing Key
              </a>
            </div>
          </mat-toolbar>
        </ng-template>

        <app-authorized-guard [authorized]="!unauthorized">
          <app-webhook-list
            [webhooks]="entityList"
            (delete)="confirmDeleteEntity($event)"
            [unauthorized_delete]="unauthorized_delete"
          ></app-webhook-list>

          <mat-paginator
            [length]="entityList.total"
            [pageIndex]="entityList.page - 1"
            [pageSize]="entityList.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            (page)="onPageEvent($event)"
          ></mat-paginator>
        </app-authorized-guard>
      </app-loading-guard>
    </div>
  </mat-card-content>
</mat-card>
