import { Injectable } from '@angular/core';
import { HttpServiceBuilder } from '../../util/http.service';
import {
  MapArtifact,
  MapArtifacts,
  mapArtifactsDecoder,
} from '../../models/map-artifacts';
import { FilteredSingletonEntityService } from '../../util/filtered-singleton-entity.service';
import { Coordinates } from 'src/app/models/location';
import { RelevanceStatus } from 'src/app/models/relevance-status';

export interface MapArtifactsFilter {
  relevance_statuses: readonly RelevanceStatus[];
  geofence?: MapArtifactsGeofence;
}
export interface MapArtifactsGeofence {
  sw: Coordinates;
  ne: Coordinates;
}

@Injectable({ providedIn: 'root' })
export class MapArtifactsService extends FilteredSingletonEntityService<
  MapArtifacts,
  MapArtifactsFilter
> {
  constructor(httpServiceBuilder: HttpServiceBuilder<MapArtifacts>) {
    super(httpServiceBuilder, 'map_artifacts', mapArtifactsDecoder);
  }

  // getEntityQuery converts the MapArtifactsFilter to a query to be sent to the api
  getEntityQuery(filter: MapArtifactsFilter): {} {
    const query = {};

    if (filter.relevance_statuses) {
      query['location_relevance_status[]'] = filter.relevance_statuses;
    }
    if (filter.geofence) {
      query[
        'geofence[sw_corner]'
      ] = `${filter.geofence.sw.latitude},${filter.geofence.sw.longitude}`;
      query[
        'geofence[ne_corner]'
      ] = `${filter.geofence.ne.latitude},${filter.geofence.ne.longitude}`;
    }

    return query;
  }
}

export function getArtifactCoordinates(
  artifact: MapArtifact
): Coordinates | undefined {
  if ('coordinates' in artifact) {
    return artifact.coordinates;
  }
  if ('tracking' in artifact) {
    return artifact.tracking.coordinates;
  }

  return undefined;
}
