import { Pipe, PipeTransform } from '@angular/core';
import { Nomenclature } from 'src/app/models/organization';
import { UserDataService } from 'src/app/util/user-data.service';

@Pipe({
  name: 'nomenclature',
})
export class NomenclaturePipe implements PipeTransform {
  constructor(private userDataService: UserDataService) {}

  transform(key: string, plurality?: keyof Nomenclature): string {
    if (plurality) {
      return this.userDataService.nomenclature?.[plurality]?.[key] || '';
    }
    // If not plurality given, assume singular
    return this.userDataService.nomenclature?.singular?.[key] || '';
  }
}
