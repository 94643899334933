import { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, Subscription, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

class BuildDetails {
  version: string;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Continually checks the server to see if there's a new version of the app
 */
export class AppUpToDateService {
  private isUpToDateSubject = new BehaviorSubject<boolean>(true);
  private timerSubsciption: Subscription;

  constructor(private httpClient: HttpClient) {
    this.restartPolling();
  }

  /**
   * Used by the consumer of this service to get notified
   * when there's a new version.
   *
   * true: the app is up to date
   * false: there's a new version and the user should refresh
   *
   */
  public get isUpToDate(): Observable<boolean> {
    return this.isUpToDateSubject;
  }

  private startPolling() {
    if (this.timerSubsciption) {
      throw new Error('Make sure you stop the timer before starting it');
    }

    // poll every 5 minutes
    const pollInterval = 300000;

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    this.timerSubsciption = timer(pollInterval, pollInterval).subscribe(() => {
      this.httpClient
        .get<BuildDetails>('assets/build-info.json', httpOptions)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 0) {
              return NEVER;
            }
            throw error;
          })
        )
        .subscribe((response) => {
          if (!response || environment.version !== response.version) {
            this.stopPolling();
            this.isUpToDateSubject.next(false);
          }
        });
    });
  }

  private stopPolling() {
    if (this.timerSubsciption) {
      this.timerSubsciption.unsubscribe();
      this.timerSubsciption = null;
    }
  }

  public restartPolling() {
    this.stopPolling();
    this.startPolling();
  }
}
