<ng-container>
  <section class="agreement-content" [innerHtml]="content"></section>
  <div class="agreement-form">
    <section>
      <mat-checkbox [(ngModel)]="agreed"
        >I accept the terms of the License Agreement</mat-checkbox
      >
    </section>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!agreed"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</ng-container>
