import { Injectable } from '@angular/core';
import { AuthService } from '../core/services/api/auth.service';
import { HttpServiceBuilder, HttpService } from '../util/http.service';
import { v4 as uuid } from 'uuid';
import { TicketHolder, ticketHolderDecoder } from '../models/ticket-holder';
import { EventsService, EventsServiceManager } from '../util/events.service';
import { Decoder } from 'decoders';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { identityEncoder } from '../models';

interface WebsocketConnection {
  requestId: string;
  ticket: string | null;
  consumer: WebSocket;
}

function isWebSocketSupported(): boolean {
  return !!WebSocket;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelSubscriberService {
  private subscription: Subscription = new Subscription();
  private connections = new Map<string, WebsocketConnection>();

  constructor(
    private authService: AuthService,
    private httpServiceBuilder: HttpServiceBuilder<TicketHolder>,
    private eventsServiceManager: EventsServiceManager
  ) {}

  private createWSTicket<T>(
    isLoggedIn: boolean,
    identifier: string,
    decoder: Decoder<T>,
    eventsService?: EventsService<T>
  ) {
    if (isLoggedIn) {
      const [requestId, httpService] = this.initHttpService();
      this.subscription.add(
        httpService.create().subscribe((ticketHolder) => {
          if (requestId === ticketHolder.request_id) {
            const ticket = ticketHolder.ticket_id;

            const wsUrl = `${environment.ws_url}/${requestId}/${ticket}`;
            const consumer = new WebSocket(wsUrl);
            consumer.onopen = () => {
              console.log('WebSocket open: ' + identifier);
              consumer.send(
                JSON.stringify({
                  command: 'subscribe',
                  identifier: identifier,
                })
              );
            };
            consumer.onmessage = (event: MessageEvent) => {
              const data = JSON.parse(event.data);
              if (
                data['identifier'] === identifier &&
                data['message'] &&
                data['message']['data']
              ) {
                const update = decoder.verify(data['message']['data']);
                eventsService?.pushEvent(update);
              }
            };
            this.connections.set(identifier, { requestId, consumer, ticket });
          } else {
            console.warn(
              `Unexpected request ID ${ticketHolder.request_id}, expecting ${requestId}`
            );
          }
        })
      );
    } else {
      const connection = this.connections.get(identifier);
      if (connection?.consumer) {
        connection.consumer.close(1000, 'Logged out');
      }
      this.connections.delete(identifier);
    }
  }

  private initHttpService(): [string, HttpService<TicketHolder>] {
    const requestId = uuid();
    const httpService = this.httpServiceBuilder.build({
      route: `auth/tickets/${requestId}`,
      decoder: ticketHolderDecoder,
      encoder: identityEncoder,
    });
    return [requestId, httpService];
  }

  initializeWebsocket<T>(
    decoder: Decoder<T>,
    identifier: string,
    eventChannel?: string
  ) {
    if (isWebSocketSupported()) {
      const eventsService = eventChannel
        ? this.eventsServiceManager.get(eventChannel, decoder)
        : null;
      this.subscription.add(
        this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
          this.createWSTicket(isLoggedIn, identifier, decoder, eventsService);
        })
      );
    }
  }

  destroyWebsockets() {
    for (let [_, connection] of this.connections) {
      connection.consumer?.close(1000, 'Component destroyed');
    }
    this.subscription.unsubscribe();
  }
}
