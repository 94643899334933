import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { Entity } from '../models';

export type Form<X> = {
  [Property in keyof X]: AbstractControl<X[Property], X[Property]>;
};

@Injectable()
export abstract class EntityFormService<
  E extends Entity,
  F extends Form<any>,
  D = any
> implements OnDestroy
{
  public form: FormGroup<F>;

  protected subscriptions = new Subscription();

  additionalFormDataSubj = new ReplaySubject<D>(1);
  public additionalFormData$: Observable<D> =
    this.additionalFormDataSubj.asObservable();

  protected initialize() {
    this.initializeForm();
    this.loadAdditionalFormData().subscribe((data) => {
      this.additionalFormDataSubj.next(data);
    });
  }
  protected abstract initializeForm();

  protected loadAdditionalFormData(): Observable<D> {
    return of({} as D);
  }

  public abstract buildEntity(entity?: Partial<E>): E;

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
