import { HeartbeatStatus } from 'src/app/models/heartbeat-status';
import { ThemeColor } from './theme-color';

export const HEARTBEAT_STATUS_COLORS: {
  [Status in HeartbeatStatus]: ThemeColor;
} = {
  [HeartbeatStatus.Offline]: 'bright-blue',
  [HeartbeatStatus.Online]: 'green',
  [HeartbeatStatus.Recent]: 'purple',
  [HeartbeatStatus.Unknown]: 'blue',
};

export const getHeartbeatStatusColor = (heartbeatStatus: HeartbeatStatus) =>
  HEARTBEAT_STATUS_COLORS[heartbeatStatus];
