import { Component, Input } from '@angular/core';
import { ThemeColor } from '../../../core/ui/theme/theme-color';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {

  @Input()
  color: ThemeColor;

}
