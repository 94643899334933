import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';


@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  isDesktop$ = this.breakpointObserver.observe(`(min-width: 1024px)`).pipe(
    map(state => state.matches),
  );


  // Mobile/overlay open state
  private _sidenavOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavOpen$ = this._sidenavOpenSubject.asObservable();

  // Pinned open state
  private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();

  // Hover open state
  private _sidenavCollapsedOpenSubject = new BehaviorSubject<boolean>(false);
  sidenavCollapsedOpen$ = this._sidenavCollapsedOpenSubject.asObservable();

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {}

  openSidenav() {
    this._sidenavOpenSubject.next(true);
  }

  closeSidenav() {
    this._sidenavOpenSubject.next(false);
  }
  collapseSidenav() {
    this._sidenavCollapsedSubject.next(true);
  }

  expandSidenav() {
    this._sidenavCollapsedSubject.next(false);
  }

  collapseOpenSidenav() {
    if (this._sidenavCollapsedSubject.getValue()) {
      this._sidenavCollapsedOpenSubject.next(true);
    }
  }

  collapseCloseSidenav() {
    if (this._sidenavCollapsedSubject.getValue()) {
      this._sidenavCollapsedOpenSubject.next(false);
    }
  }
}
