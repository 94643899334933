import { Injectable } from '@angular/core';
import {
  LocationsFilter,
  LocationsService,
} from '../../locations/services/locations.service';
import { Location, locationDecoder } from '../../models/location';
import { HttpServiceBuilder, HttpServiceConfig } from '../../util/http.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsLocationService extends LocationsService<
  Location,
  LocationsFilter
> {
  constructor(httpServiceBuilder: HttpServiceBuilder<Location>) {
    super(httpServiceBuilder, 'locations', locationDecoder);
  }

  getEntityListConfig(filter: LocationsFilter): HttpServiceConfig<Location> {
    const route = filter.customRoute ? filter.customRoute : 'locations';
    return {
      ...super.getEntityListConfig(filter),
      route,
    };
  }

  confirmLocation(entity: Location) {
    const config = { ...super.getCreateConfig(entity) };
    config.route = `remote_locations/${entity.id}/confirm`;
    return this.httpService.create(entity, config);
  }

  ignoreLocation(entity: Location) {
    const config = { ...super.getCreateConfig(entity) };
    config.route = `remote_locations/${entity.id}/ignore`;
    return this.httpService.create(entity, config);
  }

  confirmMultipleLocations(locationIds: string[]) {
    const query = {
      'id[]': locationIds,
    };
    return this.httpService.postMultiple(query, {
      route: 'remote_locations/confirm',
    });
  }

  ignoreMultipleLocations(locationIds: string[]) {
    const query = {
      'id[]': locationIds,
    };
    return this.httpService.postMultiple(query, {
      route: 'remote_locations/ignore',
    });
  }
}
