import { NgModule } from '@angular/core';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { ImpersonatingBannerComponent } from './impersonating-banner/impersonating-banner.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { LocationContextSelectComponent } from './location-context/location-context-select.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { AlertsModule } from '../alerts/alerts.module';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';

@NgModule({
  imports: [SharedModule, RouterModule, OnboardingModule, AlertsModule],
  declarations: [
    HeaderComponent,
    ImpersonatingBannerComponent,
    LayoutComponent,
    LocationContextSelectComponent,
    SettingsMenuComponent,
    SidenavComponent,
    SidenavItemComponent,
    NotificationBannerComponent,
  ],
  exports: [SidenavComponent],
})
export class LayoutModule {}
