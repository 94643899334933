import {
  ElementRef,
  Injectable,
  OnDestroy,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import {
  GeolocateControl,
  LngLatLike,
  Map,
  MapboxOptions,
  NavigationControl,
  Popup,
  PopupOptions,
} from 'mapbox-gl';
import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HeartbeatStatus } from 'src/app/models/heartbeat-status';
import { environment } from 'src/environments/environment';
import { Coordinates } from '../../models/location';
import {
  AssetArtifact,
  LocationArtifact,
  MapArtifact,
  MapArtifacts,
  TypedLocationArtifact,
} from '../../models/map-artifacts';
import { RelevanceStatus } from '../../models/relevance-status';
import { MapPopupComponent } from '../components/map-popup/map-popup.component';
import { MapArtifactIcons, MapPopupArtifacts } from '../util/MapArtifactIcons';
import { MapGeofence } from '../util/MapGeofence';
import {
  getPopupOffsetByRadius,
  loadImages,
  toLngLatLike,
} from '../util/map-util';
import { Colors, Icons } from '../util/map.constants';
import { getArtifactCoordinates } from './map-artifacts.service';
import { PopupRenderer } from './popup-renderer';

export interface MapView {
  center: Coordinates;
  zoom: number;
  sw: Coordinates;
  ne: Coordinates;
}

type MapArtifactClickEvent = {
  type: 'assets' | 'location';
  data: MapArtifact | MapArtifact[] | MapPopupArtifacts;
  coordinates: LngLatLike;
};

@Injectable()
export class MapRenderService implements OnDestroy {
  private loadedSubject = new ReplaySubject(1);
  public loaded$ = this.loadedSubject.asObservable();

  private viewChangesSubject = new Subject<MapView>();
  viewChanges$ = this.viewChangesSubject.asObservable();

  private readonly defaultMapOpts: Partial<MapboxOptions> = {
    accessToken: environment.mapbox_access_token,
    style: 'mapbox://styles/mapbox/streets-v12',
    minZoom: 3,
    maxZoom: 19,
    fadeDuration: 0,
  };

  protected map: Map;
  protected geofence: MapGeofence;
  private mapArtifacts: MapArtifactIcons;

  private viewContainerRef: ViewContainerRef;
  private popupInstance: Popup;

  constructor(private popupRenderer: PopupRenderer, private router: Router) {}

  resize() {
    setTimeout(() => {
      if (this.map) {
        this.map.resize();
      }
    });
  }

  centerOn(coordinates: Coordinates) {
    this.loaded$.pipe(take(1)).subscribe(() => {
      this.map?.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 18,
        maxDuration: 5000,
      });
    });
  }

  setTarget(
    element: ElementRef,
    viewContainerRef: ViewContainerRef,
    coordinates?: Coordinates,
    zoom?
  ) {
    this.viewContainerRef = viewContainerRef;
    let initalView = {};
    if (coordinates) {
      initalView = { center: toLngLatLike(coordinates) };
    }
    if (zoom) {
      initalView = { ...initalView, zoom };
    }
    this.map = this.initializeMap(element.nativeElement, initalView);
  }

  updateSelection(artifact?: LocationArtifact) {
    if (this.geofence) {
      this.geofence.remove();
      this.geofence = null;
    }

    if (artifact) {
      const artifactCoordinates = getArtifactCoordinates(artifact);

      if (artifactCoordinates) {
        this.centerOn(artifactCoordinates);
        if (artifact.tracking?.asset_geofence_radius) {
          this.geofence = new MapGeofence(
            artifactCoordinates,
            artifact.tracking?.asset_geofence_radius
          );
          this.geofence.addTo(this.map);
        }
      }
    }
  }

  private initializeMap(
    container: HTMLElement,
    initialView: { center?: LngLatLike; zoom?: number }
  ): Map {
    const map = new Map({
      ...this.defaultMapOpts,
      ...initialView,
      container,
    });

    // Hide the canvas until styles/tiles are ready (on 'load')
    map.getCanvas().hidden = true;
    map.dragRotate.disable();

    const geolocateControl = new GeolocateControl({
      trackUserLocation: false,
      showUserLocation: false,
      showAccuracyCircle: false,
    });

    map.addControl(
      new MapboxGeocoder({
        accessToken: environment.mapbox_access_token,
        mapboxgl,
        localGeocoder: (query) => this.forwardGeocoder(query),
        marker: false,
        placeholder: 'Search an address, known location or asset',
        render: (item: MapboxGeocoder.Result): string => {
          const placeName = item.place_name.split(',');
          let icon = '';
          if (item.properties?.kahi_type === 'asset') {
            icon = '/assets/images/icon-assets.png';
          } else if (item.properties?.kahi_type === 'fixed') {
            icon = '/assets/images/icon-home.png';
          } else if (item.properties?.kahi_type === 'mobile') {
            icon = '/assets/images/icon-local-shipping.png';
          } else if (item.properties?.kahi_type === 'remote') {
            icon = '/assets/images/icon-radio-button.png';
          }

          if (icon) {
            icon = `<img src="${icon}" class="kh-mapboxgl-ctrl-geocoder--icon" />`;
          }

          return `
            <div class="mapboxgl-ctrl-geocoder--suggestion">
              <div class="mapboxgl-ctrl-geocoder--suggestion-title">
                ${icon} ${placeName[0]}
              </div>
              <div class="mapboxgl-ctrl-geocoder--suggestion-address">
                ${placeName.splice(1, placeName.length).join(',')}
              </div>
            </div>
          `;
        },
      }),
      'bottom-right'
    );
    map.addControl(new NavigationControl(), 'bottom-right');
    map.addControl(geolocateControl, 'bottom-right');

    map.on('load', () => {
      // On safari if a user doesn't agree to share location the map can load after they navigate away from the page
      // Or if the page loads too slow (its safari so its slower).
      if (map) {
        loadImages(map, Object.values(Icons)).then(() => {
          // If user navigates away from map page before this promise is fulfilled then the map no longer exists and nothing else needs to be done
          if (!map.getCanvas()) {
            return;
          }
          map.getCanvas().hidden = false;

          this.mapArtifacts = new MapArtifactIcons().addTo(
            map,
            this.getTopLayer()
          );

          // Initialize event handlers
          map.on('moveend', () => this.handleViewChange());
          map.on('zoomend', () => this.handleViewChange());
          map.on('zoom', () => this.clearPopup());

          this.mapArtifacts.on('artifactclick', (event) =>
            this.handleArtifactClick(event)
          );
          this.mapArtifacts.on('clusterclick', (event) =>
            this.handleClusterClick(event)
          );

          // Broadcast initial position
          this.updateView();
          this.loadedSubject.next(true);
        });
      }
    });

    return map;
  }

  /** Runs whenever the map view changes (re-positioned, zoomed, etc) */
  private handleViewChange() {
    this.updateView();
  }

  /** Emit the current view state */
  private updateView() {
    this.viewChangesSubject.next(this.getView());
  }

  // This will get the SouthWest and NorthEast coordinates
  // of the current bounds of the MapView
  // This is required because MapBox sometimes gives invalid coordinates
  // i.e. longitude > 180 and < -180
  private getBounds(bounds) {
    let minLon = bounds.getNorthEast().lng;
    let maxLon = bounds.getSouthWest().lng;
    if (minLon > maxLon) {
      maxLon = minLon;
      minLon = bounds.getSouthWest().lng;
    }
    let minLat = bounds.getNorthEast().lat;
    let maxLat = bounds.getSouthWest().lat;
    if (minLat > maxLat) {
      maxLat = minLat;
      minLat = bounds.getSouthWest().lat;
    }
    if (minLon >= -180 && maxLon <= 180) {
      return {
        sw: {
          longitude: minLon,
          latitude: minLat,
        },
        ne: {
          longitude: maxLon,
          latitude: maxLat,
        },
      };
    }

    return {
      ne: null,
      sw: null,
    };
  }

  private getView(): MapView {
    const { lng, lat } = this.map.getCenter();
    const bounds = this.getBounds(this.map.getBounds());

    return {
      center: { longitude: lng, latitude: lat },
      ne: bounds.ne,
      sw: bounds.sw,
      zoom: this.map.getZoom(),
    };
  }

  protected handleArtifactClick(event: MapArtifactClickEvent) {
    console.log(event);
    this.handleLocationOrAssetClick(event);
  }

  protected handleLocationClick(event: MapArtifactClickEvent) {
    let url;

    const data = event.data as TypedLocationArtifact;

    if (data.type === 'fixed') {
      url = `/map/warehouses/${data.id}`;
    } else if (data.type === 'mobile') {
      url = `/map/vehicles/${data.id}`;
    } else if (data.type === 'roving') {
      url = `/map/trailers/${data.id}`;
    } else if (data.type === 'remote') {
      url =
        data.relevance_status === RelevanceStatus.Unconfirmed
          ? `/map/locations/unconfirmed/${data.id}`
          : `/map/locations/${data.id}`;
    }
    this.router.navigateByUrl(url);
  }

  private handleLocationOrAssetClick(event: MapArtifactClickEvent) {
    if (event.type === 'location') {
      const locations: LocationArtifact[] = Array.isArray(event.data)
        ? event.data
        : ([event.data] as MapArtifact[]);
      this.addClusterPopup(
        {
          locations,
        },
        event.coordinates
      );
    } else if (event.type === 'assets') {
      console.log(event);
      this.addClusterPopup(
        {
          orphaned_assets: event.data as AssetArtifact[],
        },
        event.coordinates
      );
    }
  }

  protected handleClusterClick(event: MapArtifactClickEvent) {
    this.addClusterPopup(event.data as MapPopupArtifacts, event.coordinates);
  }

  private addClusterPopup(
    artifacts: MapPopupArtifacts,
    coordinates: LngLatLike
  ) {
    const { popup, componentRef } = this.createPopup(MapPopupComponent, {
      offset: getPopupOffsetByRadius(15),
    });

    componentRef.instance['artifacts'] = artifacts;

    this.addPopupAtCoordinates(popup, coordinates);
  }

  private createPopup<C>(
    componentType: Type<C>,
    opts: Partial<PopupOptions> = {}
  ) {
    return this.popupRenderer.renderPopup(
      componentType,
      this.viewContainerRef,
      {
        className: 'mapbox-popup-container-wide',
        maxWidth: 'inherit',
        ...opts,
      }
    );
  }

  private addPopupAtCoordinates(popup: Popup, lngLat: LngLatLike) {
    popup.setLngLat(lngLat);
    popup.addTo(this.map);
    this.popupInstance = popup;
    this.popupInstance.once('close', () => this.clearPopup());
  }

  private clearPopup() {
    if (this.popupInstance) {
      this.popupInstance.remove();
      this.popupInstance = null;
    }
  }

  /** Replaces the map features representing assets and locations */
  updateFeatures(data: MapArtifacts) {
    this.mapArtifacts.updateArtifacts(data);
  }

  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }

  addPopupMarker(
    coordinates: Coordinates,
    color: string,
    popup: Popup
  ): mapboxgl.Marker {
    return this.addCustomMarker(
      coordinates,
      {
        draggable: false,
        color,
      },
      (m: mapboxgl.Marker) => {
        return m.setPopup(popup);
      }
    );
  }

  // addMarker adds a mapbox marker at the given location
  addMarker(
    coordinates: Coordinates,
    heartbeat_status?: HeartbeatStatus
  ): mapboxgl.Marker {
    let color = Colors.inactive;
    switch (heartbeat_status) {
      case HeartbeatStatus.Online:
        color = Colors.active;
        break;
      case HeartbeatStatus.Recent:
        color = Colors.recent;
        break;
      case HeartbeatStatus.Unknown:
        color = Colors.ignored;
        break;
    }
    return this.addCustomMarker(coordinates, {
      draggable: false,
      color,
    });
  }

  private addCustomMarker(
    coordinates: Coordinates,
    opts: mapboxgl.MarkerOptions,
    modifier?: (m: mapboxgl.Marker) => mapboxgl.Marker
  ): mapboxgl.Marker {
    const marker = new mapboxgl.Marker(opts).setLngLat(
      toLngLatLike(coordinates)
    );
    return (modifier ? modifier(marker) : marker).addTo(this.map);
  }

  private forwardGeocoder(query: string): MapboxGeocoder.Result[] {
    if (!this.mapArtifacts?.artifacts) return [];

    // Get artifacts from artifact store
    const currentArtifacts = this.mapArtifacts
      ? [
          ...this.mapArtifacts.artifacts.fixed_locations,
          ...this.mapArtifacts.artifacts.mobile_locations,
          ...this.mapArtifacts.artifacts.remote_locations,
          ...this.mapArtifacts.artifacts.roving_locations,
          ...this.mapArtifacts.artifacts.orphaned_assets,
        ]
      : [];

    // Get queried artifacts
    const matchingArtifacts = currentArtifacts.filter((artifact) => {
      return (
        artifact.name.toLowerCase().includes(query.toLowerCase()) &&
        getArtifactCoordinates(artifact)
      );
    });

    // Return matching artifacts mapped to fit on map
    return matchingArtifacts.map((artifact) => {
      const coords = getArtifactCoordinates(artifact);
      return {
        place_name: artifact.name,
        center: toLngLatLike(coords),
        place_type: ['poi'],
        address: '',
        bbox: [
          coords.longitude,
          coords.latitude,
          coords.longitude,
          coords.latitude,
        ],
        context: null,
        properties: {
          kahi_type: artifact.type,
        },
        relevance: 0,
        text: '',
        type: 'Feature',
        geometry: null,
      };
    });
  }

  protected getTopLayer(): string {
    return '';
  }
}
