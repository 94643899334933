import {
  array,
  boolean,
  constant,
  either,
  email,
  mapping,
  maybe,
  object,
  string,
} from 'decoders';
import { Organization, organizationDecoder } from '../models/organization';
import { Decodify, isodate } from './decoders';
import { FixedLocation, fixedLocationDecoder } from './fixed-location';
import { RovingLocation } from './roving-location';
import {
  PreferencesSettings,
  UserPreference,
  preferencesSettingsDecoder,
  userPreferenceDecoder,
} from './user-preference';

export enum UserType {
  Member = 'member',
  Owner = 'owner',
  Manager = 'manager',
  Technical = 'technical',
  LocationManager = 'location_manager',
}

export const userTypeDecoder = either(
  constant(UserType.Member),
  constant(UserType.Owner),
  constant(UserType.Manager),
  constant(UserType.Technical),
  constant(UserType.LocationManager)
);

export interface FeatureFlag {
  id: string;
  feature_flag_id: string;
  slug: string;
  name: string;
  enabled: boolean;
}

export interface User {
  id?: string;
  type: UserType;
  name: string;
  email: string;
  organization?: Organization;
  last_sign_in_at?: Date;
  locations?: (FixedLocation | RovingLocation)[];
  privileges?: Map<string, string[]>;
  feature_flags?: FeatureFlag[];
  preferences?: UserPreference[];
  preferences_settings?: PreferencesSettings;
}

const featureFlagDecoder = object<Decodify<FeatureFlag>>({
  id: string,
  feature_flag_id: string,
  slug: string,
  name: string,
  enabled: boolean,
});

export const userDecoder = object<Decodify<User>>({
  id: string,
  type: userTypeDecoder,
  name: string,
  email: email,
  organization: maybe(organizationDecoder),
  last_sign_in_at: maybe(isodate),
  locations: maybe(array(fixedLocationDecoder)),
  privileges: maybe(mapping(array(string))),
  feature_flags: maybe(array(featureFlagDecoder)),
  preferences: maybe(userPreferenceDecoder),
  preferences_settings: maybe(preferencesSettingsDecoder),
});

export const userUpdateEncoder = (user: User) => ({
  id: user.id,
  name: user.name,
  type: user.type,
  email: user.email,
  location_ids: user.locations.map(({ id }) => id),
});

export const userCreateEncoder = (user: User) => ({
  name: user.name,
  type: user.type,
  email: user.email,
  location_ids: user.locations.map(({ id }) => id),
});

export const userProfileUpdateEncoder = (user: User) => {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    preferences_settings: user.preferences_settings,
  };
};
