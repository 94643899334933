import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { Marker } from 'mapbox-gl';
import { Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ResizeObserverService } from '../core/services/resize-observer/resize-observer.service';
import { MapArtifactsStore } from '../map/services/map-artifacts-store.service';
import { MapRenderService } from '../map/services/map-render.service';
import { PopupRenderer } from '../map/services/popup-renderer';
import { Colors } from '../map/util/map.constants';
import { PastLocation } from '../models/asset';
import { HeartbeatStatus } from '../models/heartbeat-status';
import { Coordinates } from '../models/location';
import { ActiveRecentInactive } from '../models/relevance-status';
import { DateFormatPipe } from '../shared/pipes/date-format.pipe';

@Component({
  selector: 'app-mapbox',
  templateUrl: 'mapbox.component.html',
  styleUrls: ['mapbox.component.scss'],
  providers: [
    MapRenderService,
    PopupRenderer,
    ResizeObserverService,
    MapArtifactsStore,
  ],
})
export class MapboxComponent implements AfterViewInit, OnChanges {
  @Input()
  coordinates: Coordinates;

  @Input()
  heartbeatStatus?: HeartbeatStatus;

  /** The time zone to use for dates on the map. */
  @Input()
  timeZone?: string;

  /** A list of coordinates with times sorted from least to most recent */
  @Input()
  otherCoordinates?: PastLocation[];

  @ViewChild('map')
  private mapEl: ElementRef;

  pending$ = this.artifactsStore.loading$;

  private subscriptions = new Subscription();

  private marker: Marker;
  private initialized = false;

  /** All type of known locations are available on this map */
  protected visibility = {
    orphanedAssets: false,
    remote: ActiveRecentInactive,
    fixed: ActiveRecentInactive,
    mobile: ActiveRecentInactive,
    roving: ActiveRecentInactive,
  };

  constructor(
    private mapService: MapRenderService,
    private viewContainerRef: ViewContainerRef,
    private resizeObserver: ResizeObserverService,
    private artifactsStore: MapArtifactsStore
  ) {}

  ngAfterViewInit() {
    if (this.mapEl) {
      // Sets up the map and fixes the map size with the window
      this.mapService.setTarget(
        this.mapEl,
        this.viewContainerRef,
        this.coordinates,
        18
      );
      this.subscriptions.add(
        this.resizeObserver
          .observe(this.mapEl.nativeElement)
          .subscribe(() => this.mapService.resize())
      );

      // When the map is loaded
      this.subscriptions.add(
        this.mapService.loaded$
          .pipe(
            // Adds the marker to the map
            tap(() => {
              this.initialized = true;
              this.updateMarker();
            }),
            // Adds artifacts to the mapService
            switchMap(() =>
              this.artifactsStore.filteredArtifacts$.pipe(
                tap((artifacts) => this.mapService.updateFeatures(artifacts))
              )
            )
          )
          .subscribe()
      );

      // Updates the artifact store when the user moves the map around
      this.subscriptions.add(
        this.mapService.viewChanges$
          .pipe(
            debounceTime(200),
            tap((mapView) => {
              // Only patch the Params if we got new geofence
              // values for the mapview
              if (mapView.sw && mapView.ne) {
                this.artifactsStore.patchParams({
                  visibility: this.visibility,
                  geofence: { sw: mapView.sw, ne: mapView.ne },
                });
              }
            })
          )
          .subscribe()
      );
    }
  }

  // When the coordinates or the tracking status for the asset changes, update the marker on the map.
  ngOnChanges(changes: SimpleChanges) {
    if (this.initialized) {
      this.updateMarker();
    }
  }

  updateMarker() {
    // Remove old marker
    if (this.marker) {
      this.marker.remove();
      this.marker = null;
    }
    // Add new marker
    if (this.coordinates) {
      this.mapService.centerOn(this.coordinates);
      if (this.heartbeatStatus) {
        this.marker = this.mapService.addMarker(
          this.coordinates,
          this.heartbeatStatus
        );
      }
    }
    if (this.otherCoordinates) {
      let opacity = 0.25;
      const step = 0.5 / this.otherCoordinates.length;
      const timeZone =
        this.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

      // add markers for each coordinate with each becoming more opaque
      this.otherCoordinates.forEach((coords) => {
        const hexOpacity = Math.ceil(opacity * 255).toString(16);
        const date = new DateFormatPipe().transform(coords.timestamp, timeZone);
        this.mapService.addPopupMarker(
          coords,
          `${Colors.recent}${hexOpacity}`,
          new mapboxgl.Popup().setHTML(`<div>${date}</div>`)
        );

        opacity += step;
      });
    }
  }
}
