import { Directive, Input } from '@angular/core';
import { IndicatorComponent } from './indicator.component';
import { RelevanceStatus } from '../../../models/relevance-status';
import { getRelevanceStatusColor } from '../../../core/ui/theme/relevance-status-colors';

// Sets indicator color based on RelevanceStatus
@Directive({
  selector: 'app-indicator[appRelevanceIndicator]',
})
export class RelevanceIndicatorDirective {
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('appRelevanceIndicator')
  set status(status: RelevanceStatus) {
    this.host.color = getRelevanceStatusColor(status);
  }

  constructor(private host: IndicatorComponent) {}
}
