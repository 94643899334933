import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarNotificationData } from '../snackbar-notification.service';


@Component({
  selector: 'app-snackbar-notification',
  templateUrl: './snackbar-notification.component.html',
  styleUrls: ['./snackbar-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarNotificationComponent {

  readonly iconMap = {
    info: 'info_outline',
    success: 'task_alt',
    warn: 'report_gmailerrorred',
    error: 'error_outline',
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarNotificationData,
    private snackbar: MatSnackBarRef<SnackbarNotificationComponent>,
  ) {}

  actionButtonClick() {
    this.snackbar.dismissWithAction();
  }
}
