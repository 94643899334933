import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../layout.service';
import { AuthService } from '../../core/services/api/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  collapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;

  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;

  private subscriptions = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router
  ) {}

  signOut() {
    this.authService.logout();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.isDesktop$
        .pipe(
          filter((matches) => !matches),
          tap(() => console.log('expand'))
        )
        .subscribe(() => this.layoutService.expandSidenav())
    );

    this.subscriptions.add(
      this.layoutService.sidenavOpen$.subscribe((open) =>
        open ? this.sidenav.open() : this.sidenav.close()
      )
    );

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter<NavigationEnd>((event) => event instanceof NavigationEnd),
          withLatestFrom(this.isDesktop$),
          filter(([event, matches]) => !matches)
        )
        .subscribe(() => this.sidenav.close())
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
