import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { InventoryFilter } from 'src/app/inventory/services/inventory-filter.service';
import { Asset, assetDecoder } from '../../models/asset';
import { dateStringEncoder } from '../../models/date-string';
import { HeartbeatStatus } from '../../models/heartbeat-status';
import { EntityService, SortFilter } from '../../util/entity.service';
import { HttpServiceBuilder, HttpServiceConfig } from '../../util/http.service';

export enum AssetType {
  Cataloged = 'cataloged',
  Uncataloged = 'uncataloged',
  IndividualCataloged = 'individual_cataloged',
  IndividualUncataloged = 'individual_uncataloged',
}

export enum TrackingType {
  Tracked = 'tracked',
  Untracked = 'untracked',
  All = 'all',
}

type AssetSortColumn =
  | 'name'
  | 'manufacturer_name'
  | 'asset_model_name'
  | 'asset_model_number'
  | 'category_name';

export type AssetsSortFilter = SortFilter<AssetSortColumn>;

export interface AssetsFilter extends InventoryFilter<AssetSortColumn> {
  locationId?: string;
  status?: HeartbeatStatus;
  categoryIds?: string[];
  deleted?: boolean;
  batteryStatus?: 'low' | 'critical';
  deploymentStatus?: 'homesick';
  homeLocationId?: string;
  customRoute?: string;
  manufacturerIds?: string[];
  size?: string;
  assetModelId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsService extends EntityService<Asset, AssetsFilter> {
  constructor(httpServiceBuilder: HttpServiceBuilder<Asset>) {
    super(httpServiceBuilder, 'assets', assetDecoder);
  }

  getEntityListConfig(filter: AssetsFilter): HttpServiceConfig<Asset> {
    const config = this.httpService.getConfig();
    let route = filter.customRoute || config.route;
    if (filter.cataloged) {
      route = 'assets/cataloged';
    } else if (filter.uncataloged) {
      route = 'assets/uncataloged';
    }
    return {
      ...config,
      route,
    };
  }

  getEntityListQuery(filter: AssetsFilter): {} {
    const query = {};

    if (filter.locationId) {
      query['location_id'] = filter.locationId;
    }
    if (filter.status) {
      query['filter[heartbeat_status]'] = filter.status;
    }
    if (filter.search) {
      query['search'] = filter.search;
    }
    if (filter.categoryIds && filter.categoryIds.length > 0) {
      query['asset_category_id[]'] = filter.categoryIds;
    }
    if (filter.deleted === true) {
      query['deleted'] = true;
    }
    if (filter.batteryStatus) {
      query[`${filter.batteryStatus}_battery`] = true;
    }
    if (filter.deploymentStatus) {
      query[filter.deploymentStatus] = true;
    }
    if (filter.homeLocationId) {
      query['home_location_id'] = filter.homeLocationId;
    }
    if (typeof filter.tracked === 'boolean') {
      query['tracked'] = filter.tracked;
    }

    if (filter.manufacturerIds && filter.manufacturerIds.length > 0) {
      query['asset_manufacturer_id[]'] = filter.manufacturerIds;
    }

    if (filter.size) {
      query['filter[size]'] = filter.size;
    }

    if (filter.assetModelId) {
      query['filter[asset_model_id]'] = filter.assetModelId;
    }

    if (filter.categories && filter.categories.length > 0) {
      query['asset_category_id[]'] = filter.categories;
    }

    if (filter.catalogedCategories && filter.catalogedCategories.length > 0) {
      query['asset_category_id[]'] = filter.catalogedCategories;
    }

    if (filter.models && filter.models.length > 0) {
      query['asset_model_id[]'] = filter.models;
    }

    if (filter.manufacturers && filter.manufacturers.length > 0) {
      query['asset_manufacturer_id[]'] = filter.manufacturers;
    }

    if (filter.purchaseDateStart) {
      const endDate = filter.purchaseDateEnd || new Date();
      query['purchase_date[]'] = [filter.purchaseDateStart, endDate];
    }

    if (filter.warrantyDateStart) {
      const endDate = filter.warrantyDateEnd || new Date();
      query['warranty_date[]'] = [filter.warrantyDateStart, endDate];
    }

    if (filter.sort) {
      Object.entries(filter.sort).forEach(([key, value]) => {
        if (value) {
          query[`sort[${key}]`] = value;
        }
      });
    }

    if (filter.location) {
      query['location_id'] = filter.location;
    }

    if (filter.homeLocation) {
      query['home_location_id'] = filter.homeLocation;
    }

    return query;
  }

  getEncoder(): (entity: Asset) => {} {
    return (entity) => ({
      ...entity,
      manufactured_at: dateStringEncoder(entity.manufactured_at),
      warranty_at: dateStringEncoder(entity.warranty_at),
    });
  }

  rehomeAssets(
    assetIds: string[],
    homeLocationId: string
  ): Observable<Asset[]> {
    const query = {
      home_location_id: homeLocationId,
      'id[]': assetIds,
    };
    return this.httpService.patchMultiple(query, {
      route: 'assets/reassign_home',
    });
  }

  deleteImage(imageId: string): Observable<boolean> {
    return this.httpService.deleteById(imageId, {
      route: 'asset_images',
    });
  }

  validateAsset(asset: Asset): Observable<Asset> {
    return this.httpService.create(asset, {
      route: 'assets/validate',
    });
  }
}
