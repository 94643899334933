import { object, string } from 'decoders';
import { Decodify } from './decoders';
import { Entity } from './index';

export interface AssetManufacturer extends Entity {
  name: string;
}

export const assetManufacturerDecoder = object<Decodify<AssetManufacturer>>({
  id: string,
  name: string,
});
