import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MapRenderService } from 'src/app/map/services/map-render.service';
import { MapDraggablePoint } from 'src/app/map/util/MapDraggablePoint';
import { Asset } from 'src/app/models/asset';
import { Coordinates } from 'src/app/models/location';
import { RemoteLocation } from 'src/app/models/remote-location';

@Injectable()
export class OrphanedAssetMapService extends MapRenderService {
  private point: MapDraggablePoint;

  private coordinateChangesSubj = new Subject<Coordinates>();
  coordinateChanges$ = this.coordinateChangesSubj.asObservable();

  // initialize adds a point for the asset that can be dragged, dragging updates the coordinate changes
  initialize(asset: Asset) {
    this.map.getCanvas().hidden = false;

    this.point = new MapDraggablePoint(
      asset.tracking.coordinates,
      'asset',
      'asset'
    ).addTo(this.map);

    this.point.on('drag', () => {});
    this.point.on('dragend', () => {
      this.coordinateChangesSubj.next(this.point.getCoordinates());
    });
  }

  // updateCoordinates centers the point and the map on the coordinates
  updateCoordinates(coordinates: Coordinates) {
    this.point.setCoordinates(coordinates);
    this.centerOn(coordinates);
  }

  protected handleArtifactClick(event: any): void {}

  protected handleClusterClick(event: any): void {}

  protected handleLocationClick(event: any): void {}

  protected getTopLayer(): string {
    return this.point.layerId;
  }

  // findDistanceBetween returns the distance between the 2 coordinates in km
  // Formula found at https://en.wikipedia.org/wiki/Great-circle_distance
  private findDistanceBetween(
    point1: Coordinates,
    point2: Coordinates
  ): number {
    const radius = 6371000;
    const lat1 = (point1.latitude * Math.PI) / 180;
    const lat2 = (point2.latitude * Math.PI) / 180;
    const delta = ((point1.longitude - point2.longitude) * Math.PI) / 180;
    return (
      Math.acos(
        Math.sin(lat1) * Math.sin(lat2) +
          Math.cos(lat1) * Math.cos(lat2) * Math.cos(delta)
      ) * radius
    );
  }

  // findClosestJobsite returns the jobsite that is closest to the coordinates along with the distance in metres
  findClosestJobsite(
    coordinates: Coordinates,
    jobsites: RemoteLocation[]
  ): { jobsite: RemoteLocation; distance: number } {
    let jobsite: RemoteLocation;
    let distance: number;
    jobsites.forEach((location: RemoteLocation) => {
      const currDistance = this.findDistanceBetween(
        coordinates,
        location.coordinates
      );
      if (!distance || distance > currDistance) {
        distance = currDistance;
        jobsite = location;
      }
    });
    return {
      jobsite,
      distance,
    };
  }

  // findPixelDistance finds how far the given distance is in pixels given the latitdue of the point and the zoom of the map.
  // Equation from https://wiki.openstreetmap.org/wiki/Zoom_levels
  findPixelDistance(distance: number, latitude: number, zoom: number) {
    const radius = 2 * Math.PI * 6378137;
    const distance_per_pixel =
      (radius * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom + 8);
    return distance / distance_per_pixel;
  }
}
