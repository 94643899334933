import { Component, Input } from '@angular/core';
import { Location } from '../../../models/location';
import { RelevanceStatus } from '../../../models/relevance-status';
import { MapPopupArtifacts } from '../../util/MapArtifactIcons';

@Component({
  selector: 'app-map-popup',
  templateUrl: 'map-popup.component.html',
  styleUrls: ['map-popup.component.scss'],
})
export class MapPopupComponent {
  @Input() artifacts: MapPopupArtifacts;

  getLocationLink(location: Location) {
    if (location.tracking?.asset_status === RelevanceStatus.Unconfirmed) {
      return `/map/locations/unconfirmed/${location.id}`;
    } else {
      if (location.type === 'fixed') {
        return `/map/warehouses/${location.id}`;
      }
      if (location.type === 'mobile') {
        return `/map/vehicles/${location.id}`;
      }
      return `/map/locations/${location.id}`;
    }
  }
}
