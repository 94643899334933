<mat-dialog-content>
  <app-loading-guard [loading]="jobsites.loading">
    <div class="dialog-container">
      <div class="details">
        <div class="header">
          <h1>
            Assign Orphaned Asset to {{ 'remote_location' | nomenclature }}
          </h1>
          <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="column">
          <label>Asset</label>
          <h3 class="name">{{ data.asset.name }}</h3>
        </div>
      </div>
      <div class="form-container">
        <div class="form">
          <div class="column jobsites">
            <label>Assign to {{ 'remote_location' | nomenclature }}</label>
            <mat-form-field appearance="outline" [floatLabel]="'always'">
              <mat-label>{{ 'remote_location' | nomenclature }} Name</mat-label>
              <mat-select
                [(ngModel)]="selectedJobsite"
                (selectionChange)="onJobsiteChange($event)"
              >
                <mat-option
                  *ngFor="let jobsite of jobsites.entities"
                  [value]="jobsite.id"
                  >{{ jobsite.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="column dropoff">
            <label>Drop Off Date (optional)</label>
            <div>
              <mat-form-field
                appearance="outline"
                class="dropoff-date"
                [floatLabel]="'always'"
              >
                <mat-label>Day</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [(ngModel)]="selectedDate"
                  [max]="today"
                  (dateChange)="onDateChange()"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Time</mat-label>
                <input
                  matInput
                  type="time"
                  step="60"
                  [(ngModel)]="selectedTime"
                />
                <mat-hint>HH:MM</mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="map">
          <app-jobsite-select-map [asset]="data.asset"></app-jobsite-select-map>
        </div>
      </div>
    </div>
  </app-loading-guard>
  <mat-dialog-actions class="footer">
    <label
      >Do you need help?
      <a href="https://support.kahi.io/hc/en-us" target="_blank">Contact us</a
      >.</label
    >
    <div class="actions">
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="assignJobsite()">
        Assign Asset
      </button>
    </div>
  </mat-dialog-actions>
</mat-dialog-content>
