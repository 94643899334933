import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Nomenclature } from 'src/app/models/organization';
import { UserDataService } from 'src/app/util/user-data.service';
import { StateService } from '../../core/services/state/state.service';

// Get the lowest level active child route
const getChild = (route: ActivatedRoute) =>
  route.firstChild ? getChild(route.firstChild) : route;

// Gets the title from the data for the given route (or the closest parent route that has a title)
const getTitle = (route: ActivatedRoute, nomenclature: Nomenclature) => {
  let title: string = route?.snapshot?.data?.headerTitle;
  if (title) {
    // Check if any part of header title should be replaced with api label (Starts with @ and surrounded by parenthesis)
    const regex = /@\(([^:)]+)(?::([^)]+))?\)/g;
    const match = regex.exec(title);
    if (match) {
      const fullCapture = match[0];
      const nomenclatureKey = match[1];
      const plurality = match[2];
      if (plurality) {
        title = title.replace(
          fullCapture,
          nomenclature[plurality][nomenclatureKey]
        );
      } else {
        // If plurality is not specified assume its singular
        title = title.replace(
          fullCapture,
          nomenclature.singular[nomenclatureKey]
        );
      }
    }

    return title;
  }

  if (route.parent) {
    return getTitle(route.parent, nomenclature);
  }

  return '';
};

interface HeaderState {
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends StateService<HeaderState> {
  routeTitle$ = this.router.events.pipe(
    filter(
      (event) =>
        event instanceof NavigationEnd || event instanceof ActivationEnd
    ),
    distinctUntilChanged(),
    startWith(() => {}), // Provides a value immediately on subscribe, rather than waiting for the next event
    map(() =>
      getTitle(getChild(this.activatedRoute), this.userDataService.nomenclature)
    )
  );

  title$ = this.select((state) => state.title);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userDataService: UserDataService
  ) {
    super({ title: null });

    this.addSubscription(
      this.routeTitle$.subscribe((title) => {
        this.patchState({
          title,
        });
      })
    );
  }

  setTitle(title: string) {
    this.patchState({
      title,
    });
  }
}
