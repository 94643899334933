import { Component, OnInit } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { AlertsService } from 'src/app/core/services/api/alerts.service';
import { Alert } from 'src/app/models/alert';
import { LocationContextService } from '../location-context/location-context.service';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {
  gatewayAlert: Alert;

  constructor(
    private alertService: AlertsService,
    private locationContext: LocationContextService
  ) {}

  ngOnInit(): void {
    this.locationContext.locationId$
      .pipe(
        switchMap((locationId) =>
          this.alertService.getEntityList(
            { locationId },
            { page: 0, perPage: 100 }
          )
        ),
        map((entityList) => entityList.entities)
      )
      .subscribe((alerts) => {
        this.gatewayAlert = alerts.find(
          (alert) =>
            alert.entity_type === 'fixed_gateway' && alert.status === 'replace'
        );
      });
  }

  closeBanner() {
    this.gatewayAlert = null;
  }

  goToSupport() {
    window
      .open(
        'https://support.kahi.io/hc/en-us/articles/7595390333851-Installing-the-Kahi-Hale-Particle-Gateway-REPLACEMENT-PROCESS',
        '_blank'
      )
      .focus();
  }
}
