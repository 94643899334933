import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ErrorEventService } from 'src/app/util/error-event.service';
import { IsSubscribedService } from '../services/api/is-subscribed.service';

@Injectable()
export class SubscriptionGuard {
  constructor(
    protected isSubscribedService: IsSubscribedService,
    protected errorService: ErrorEventService,
    protected router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkSubscription();
  }

  canActivateChild(): Observable<boolean> {
    return this.checkSubscription();
  }

  checkSubscription(): Observable<boolean> {
    return this.isSubscribedService.isSubscribed$.pipe(
      take(1),
      tap((isSubscribed) => {
        if (!isSubscribed) {
          this.errorService.pushEvent(new Error('Payment required'));
          this.router.navigate(['/payment_required']);
        }
      })
    );
  }
}
