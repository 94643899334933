import {
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { withLatestFrom } from 'rxjs/operators';
import { AlertsService } from 'src/app/core/services/api/alerts.service';
import { LocationContextService } from 'src/app/layout/location-context/location-context.service';
import { LocationsFilter } from 'src/app/locations/services/locations.service';
import {
  Location,
  LocationTracking,
  locationTrackingDecoder,
} from 'src/app/models/location';
import {
  DefaultFilteredEntityListStore,
  FilteredEntityListComponent,
  defaultFilteredEntityListStoreProvider,
} from 'src/app/util/entity-list/filtered-entity-list.base';
import {
  EventsService,
  EventsServiceManager,
} from 'src/app/util/events.service';
import { STORE_SERVICE_TOKEN } from 'src/app/util/store.service';
import { AlertsLocationService } from '../../services/alerts-location.service';

const DEFAULT_COLUMNS = ['name', 'address'];

@Component({
  selector: 'app-alert-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  providers: [
    defaultFilteredEntityListStoreProvider<LocationsFilter>(
      'alert-locations.component'
    ),
  ],
})
export class AlertLocationsComponent
  extends FilteredEntityListComponent<Location, LocationsFilter>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() alertType?: string;
  @Input() locationType?: string;

  protected locationEventsService: EventsService<LocationTracking>;

  customRoute: string;
  homeLocationId: string;
  displayedColumns: string[] = [];

  constructor(
    entityService: AlertsLocationService,
    @Inject(STORE_SERVICE_TOKEN)
    store: DefaultFilteredEntityListStore<LocationsFilter>,
    eventsServiceManager: EventsServiceManager,
    protected locationContextService: LocationContextService,
    route: ActivatedRoute,
    private alertsService: AlertsService
  ) {
    super(entityService, store, {});
    // Get the alert type and the location type from the route
    route.paramMap
      .pipe(withLatestFrom(route.data))
      .subscribe(([params, data]) => {
        const type = params.get('alert_type');
        if (type) {
          this.updateAlertType(type, data.locationType);
        }
      });
    this.locationEventsService = eventsServiceManager.get(
      'locations.changes',
      locationTrackingDecoder
    );
  }

  protected getEntityListFilter(): LocationsFilter {
    return {
      homeLocationId: this.homeLocationId,
      customRoute: this.customRoute,
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.addSubscription(
      this.locationContextService.locationId$.subscribe((locationId) => {
        this.homeLocationId = locationId;
        this.onFilterChange();
      })
    );

    this.addSubscription(
      this.locationEventsService.subscribe((event: LocationTracking) => {
        const matchingLocation = this.entityList.entities.find(
          (location) => location.id === event.id
        );

        if (matchingLocation && matchingLocation.tracking) {
          matchingLocation.tracking.synchronized_at = event.synchronized_at;
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.alertType) {
      this.updateAlertType(this.alertType, this.locationType);
    }
  }

  getTableId() {
    return `alert-locations-table`;
  }

  updateAlertType(alertType: string, locationType?: string) {
    this.customRoute = locationType
      ? `${locationType}_locations/${alertType}`
      : `locations/${alertType}`;
    this.filter.customRoute = this.customRoute;
    let prependColumns = [];
    let columns = [];
    if (alertType === 'unconfirmed') {
      prependColumns = ['select'];
      columns = ['actions'];
    }
    this.displayedColumns = [...prependColumns, ...DEFAULT_COLUMNS, ...columns];
    this.entityList.loading = true;
    this.onFilterChange();
  }

  @HostListener('window:RefreshEntitiesEvent')
  refreshLocations() {
    this.onFilterChange();
    this.alertsService.refreshAlerts();
  }
}
