import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ThemeColor } from '../../../core/ui/theme/theme-color';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChipComponent {

  @Input()
  set color(color: ThemeColor) {
    this.colorClass = color;
  }

  @HostBinding('class') colorClass: ThemeColor = null;

}
