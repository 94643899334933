import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Type } from '@angular/core';
import { Popup, PopupOptions } from 'mapbox-gl';

export interface PopupComponent<C> {
  popup: Popup;
  componentRef: ComponentRef<C>;
}

/** Helper for dynamically rendering angular components inside of mapbox-gl map popups */
@Injectable()
export class PopupRenderer {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  renderPopup<C>(componentType: Type<C>, viewContainerRef: ViewContainerRef, popupOpts: Partial<PopupOptions>): PopupComponent<C> {
    const componentRef = this.createComponent(componentType, viewContainerRef);
    const popup = this.createPopup(componentRef, popupOpts);

    return { popup, componentRef };
  }

  private createComponent<C>(componentType: Type<C>, viewContainerRef: ViewContainerRef): ComponentRef<C> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    return componentRef;
  }

  private createPopup<C>(componentRef: ComponentRef<C>, popupOpts: Partial<PopupOptions>): Popup {
    const popup = new Popup(popupOpts);
    popup.setDOMContent(componentRef.location.nativeElement);

    return popup;
  }
}
