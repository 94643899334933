import { Entity } from '.';
import { object, string, number, integer } from 'decoders';

const intervalMap = {
  month: 'Monthly',
};

interface PlanMetadata {
  title: string;
  period: string;
  description_html: string;
}

export interface Plan extends Entity {
  name: string;
  amount: number;
  currency: string;
  interval: string;
  trial_period_days: number;
  metadata: PlanMetadata;
}

export const planDecoder = object({
  id: string,
  name: string,
  amount: integer,
  currency: string,
  interval: string,
  trial_period_days: number,
  display_name: string,
  description_html: string,
}).transform<Plan>((value) => {
  // pull metadata out so that will be localizable later
  const metadata: PlanMetadata = {
    title: value.display_name,
    period: intervalMap[value.interval] || 'Monthly',
    description_html: value.description_html,
  };
  return {
    id: value.id,
    name: value.name,
    amount: value.amount,
    currency: value.currency,
    interval: value.interval,
    trial_period_days: value.trial_period_days,
    metadata,
  };
});
