<ng-container *ngIf="!loading; else loaderTemplate ? loaderTemplate : defaultLoader">
  <ng-content></ng-content>
</ng-container>

<ng-template #defaultLoader>
  <mat-toolbar class="loading-toolbar">
    <mat-progress-bar
      mode="query"
      aria-label="Loading"
    ></mat-progress-bar>
  </mat-toolbar>
</ng-template>
