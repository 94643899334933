import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';

import { OverlayModule } from '@angular/cdk/overlay';

/**
 * Encapsulates the imports for Angular Material components used across the application
 * */
@NgModule({
  exports: [
    MatBadgeModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatSortModule,

    OverlayModule,
  ],
})
export class MaterialCommonModule {}
