import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  InventoryLink,
  NavigationDropdown,
  NavigationItem,
} from '../navigation/navigation-item';
import { NavigationConfig } from '../navigation/navigation-config';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  items: NavigationItem[];
  inventoryItems: InventoryLink[];

  private openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this.openChangeSubject.asObservable();

  triggerOpenChange(item: NavigationDropdown) {
    this.openChangeSubject.next(item);
  }

  initializeNavigation(navigationConfig: NavigationConfig) {
    this.items = navigationConfig.items;
    this.inventoryItems = navigationConfig.inventoryItems;
  }
}
