import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { withLatestFrom } from 'rxjs/operators';
import { ResizeObserverService } from 'src/app/core/services/resize-observer/resize-observer.service';
import { MapArtifactsStore } from 'src/app/map/services/map-artifacts-store.service';
import { PopupRenderer } from 'src/app/map/services/popup-renderer';
import { EntityList } from 'src/app/models';
import { Asset } from 'src/app/models/asset';
import { Coordinates } from 'src/app/models/location';
import { ActiveRecentInactive } from 'src/app/models/relevance-status';
import { RemoteLocation } from 'src/app/models/remote-location';
import { MessageEventService } from 'src/app/util/message-event.service';
import { NearestLocationService } from '../../services/nearest-location.service';
import { OrphanedAssetMapService } from '../../services/orphaned-asset-map.service';

@Component({
  selector: 'app-jobsite-select-dialog',
  templateUrl: './jobsite-select-dialog.component.html',
  styleUrls: ['./jobsite-select-dialog.component.scss'],
  providers: [
    OrphanedAssetMapService,
    PopupRenderer,
    ResizeObserverService,
    MapArtifactsStore,
    NearestLocationService,
  ],
})
export class JobsiteSelectDialogComponent {
  today = new Date();

  jobsites: EntityList<RemoteLocation> = {
    loading: true,
    entities: [],
    perPage: 20,
    page: 0,
    total: 0,
  };
  selectedJobsite: string;
  selectedDate: Date = null;
  selectedTime: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { asset: Asset },
    private dialogRef: MatDialogRef<JobsiteSelectDialogComponent>,
    private orphanedAssetMapService: OrphanedAssetMapService,
    private messageService: MessageEventService,
    private locationsService: NearestLocationService
  ) {
    // Set the selected date and time to the locations most recent activity
    this.locationsService
      .getEntityList(
        {
          type: 'remote',
          coordinates: this.data.asset.tracking.coordinates,
          relevanceStatus: ActiveRecentInactive,
          geofenceRadius: 20000,
        },
        { perPage: 1000, page: 0 }
      )
      .subscribe((locations) => {
        this.jobsites = locations;
        if (this.jobsites.entities.length > 0) {
          const mostRecentJobsite = this.jobsites.entities[0];
          this.selectedJobsite = mostRecentJobsite.id;
        } else {
          this.selectedJobsite = '';
        }
        this.updateCoordinates(this.data.asset.tracking.coordinates);
      });

    // Update the jobsite when the asset on the map is dragged
    this.orphanedAssetMapService.coordinateChanges$
      .pipe(withLatestFrom(this.orphanedAssetMapService.viewChanges$))
      .subscribe(([coordinates, mapView]) => {
        // Find the closest jobsite
        const closest = this.orphanedAssetMapService.findClosestJobsite(
          coordinates,
          this.jobsites.entities
        );
        // Find distance in pixels
        const distance = this.orphanedAssetMapService.findPixelDistance(
          closest.distance,
          coordinates.latitude,
          mapView.zoom
        );
        // If orphaned asset is touching location artifact, update the location else set the location to null
        if (distance < 13) {
          this.selectedJobsite = closest.jobsite.id;
          this.updateCoordinates(closest.jobsite.coordinates);
        } else {
          this.selectedJobsite = null;
        }
      });
  }

  // assignJobsite shows an error if no jobsite is selected or returns the given coordinates and time for the given jobsite
  assignJobsite() {
    if (!this.selectedJobsite) {
      this.messageService.pushEvent({
        status: 'error',
        message: 'No jobsite selected',
      });
      return;
    }
    if (this.selectedDate !== null) {
      // Get the selected time and add it to the date
      const [hours, minutes] = this.selectedTime.split(':');
      this.selectedDate.setHours(parseInt(hours));
      this.selectedDate.setMinutes(parseInt(minutes));
      if (this.selectedDate > new Date()) {
        this.messageService.pushEvent({
          status: 'error',
          message: 'Selected time in the future',
        });
        return;
      }
    }
    const jobsite = this.jobsites.entities.find(
      (site) => site.id === this.selectedJobsite
    );
    this.dialogRef.close({
      jobsite,
      time: this.selectedDate,
    });
  }

  // updateCoordinates sets the map to the given coordinates
  updateCoordinates(coordinates: Coordinates) {
    this.orphanedAssetMapService.loaded$.subscribe(() => {
      this.orphanedAssetMapService.updateCoordinates(coordinates);
    });
  }

  // onJobsiteChange finds the coordinates for the selected jobsite and updates the coordinates of the map
  onJobsiteChange(event: MatSelectChange) {
    const jobsite = this.jobsites.entities.find(
      (location) => location.id === event.value
    );

    this.updateCoordinates(jobsite.coordinates);
  }

  onDateChange() {
    if (this.selectedTime === null) {
      this.selectedTime = '12:00';
    }
  }
}
