<div class="panel-container">
  <div class="panel-header">
    <h1>Notifications</h1>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="alerts">
      <div
        class="alert-notification"
        *ngFor="let alert of alerts"
        (click)="navigateTo(alert.route)"
      >
        <mat-icon color="warn" class="icon" [ngClass]="alert.color">{{
          alert.icon
        }}</mat-icon>

        <span class="alert-message"
          ><span class="alert-value">{{ alert.value }}</span>
          {{ alert.message }}</span
        >
      </div>
    </div>
  </mat-dialog-content>
</div>
