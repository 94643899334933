import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldDirective } from './form-field.directive';
import { SharedCommonDirectivesModule } from './shared-common-directives.module';

@NgModule({
  imports: [CommonModule, SharedCommonDirectivesModule],
  declarations: [FormFieldDirective],
  exports: [FormFieldDirective, SharedCommonDirectivesModule],
})
export class SharedDirectivesModule {}
