import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { NEVER, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Webhook } from 'src/app/models/webhook';
import { UnauthorizedError } from 'src/app/util/http.service';
import { WebhooksService } from '../../services/webhooks.service';

interface WebhookEditData {
  webhook: Webhook;
  global: boolean;
  unauthorized_delete: {};
  readonly: boolean;
}

@Component({
  selector: 'app-webhook-edit',
  templateUrl: './webhook-edit.component.html',
  styleUrls: ['./webhook-edit.component.scss'],
})
export class WebhookEditComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  modifiedWebhook?: Webhook = null;
  unauthorized_edit = {};
  unauthorized_delete = this.data.unauthorized_delete || {};

  constructor(
    private router: Router,
    private webhooksService: WebhooksService,
    @Inject(MAT_DIALOG_DATA) public data: WebhookEditData,
    private dialogRef: MatDialogRef<WebhookEditComponent>
  ) {}

  getContextRouterLink(item: Webhook): string[] {
    return this.webhooksService.getContextRouterLink(item);
  }

  updateModifiedWebhooks(item: Webhook, attributes: Partial<Webhook>) {
    // attributes that can be modified
    const attrs = ['url', 'name', 'enabled'];

    // If webhook has not already been modified add it to the modified object
    if (!this.modifiedWebhook) {
      this.modifiedWebhook = {
        ...item,
      };
    }

    // Update attributes of modified webhook with new attributes
    Object.keys(attributes).forEach((attr) => {
      this.modifiedWebhook[attr] = attributes[attr];
    });
    // Returns true if all of the existing attributes match the modified attributes (Nothing has been changed)
    const match = attrs.reduce((prev, curr) => {
      return prev && item[curr] === this.modifiedWebhook[curr];
    }, true);
    // If nothing has been changed webhook has not been modified
    if (match) {
      this.modifiedWebhook = null;
    }
  }

  handleUrlChange(event: Event, item: Webhook) {
    const input: HTMLInputElement = <HTMLInputElement>event.target;

    this.updateModifiedWebhooks(item, { url: input.value });
  }

  handleEnabledToggle(event: MatSlideToggleChange, item: Webhook) {
    this.updateModifiedWebhooks(item, { enabled: event.checked });
  }

  handleNameChange(event: Event, item: Webhook) {
    const input: HTMLInputElement = <HTMLInputElement>event.target;

    this.updateModifiedWebhooks(item, { name: input.value });
  }

  getFailuresLabel(item: Webhook): string {
    if (item.failures === null || typeof item.failures === 'undefined') {
      return '';
    } else if (item.failures === 1) {
      return '1 recent failure';
    }

    return `${item.failures} recent failures`;
  }

  getEventTypesFormatted(item: Webhook): string {
    return item.eventTypes.join(', ');
  }

  handleSaveClick(event: any, item: Webhook) {
    if (!this.modifiedWebhook || this.unauthorized_edit[item.id]) {
      return; // no changes to be saved
    }

    const webhook = this.modifiedWebhook;
    this.subscription.add(
      this.webhooksService
        .updateEntity(webhook)
        .pipe(
          catchError((error: Error) => {
            if (error instanceof UnauthorizedError) {
              this.unauthorized_edit[webhook.id] = true;
            }
            return NEVER;
          })
        )
        .subscribe((updatedWebhook) => {
          item.enabled = updatedWebhook.enabled;
          item.name = updatedWebhook.name;
          item.url = updatedWebhook.url;
          this.dialogRef.close();
        })
    );
  }
  moreDetails() {
    this.router.navigate(['/webhooks', this.data.webhook.id]);
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openContext(webhook: Webhook) {
    this.router.navigate(this.getContextRouterLink(webhook));
    this.dialogRef.close();
  }
}
