import { featureCollection, point } from '@turf/helpers';
import { EventEmitter } from 'events';
import {
  AnyLayer,
  EventData,
  ExpressionName,
  GeoJSONSource,
  GeoJSONSourceRaw,
  Map,
  MapMouseEvent,
} from 'mapbox-gl';
import { RelevanceStatus } from 'src/app/models/relevance-status';
import { Coordinates } from '../../models/location';
import {
  AssetArtifact,
  LocationArtifact,
  MapArtifacts,
} from '../../models/map-artifacts';
import { Colors, Expressions, Icons } from './map.constants';

export interface MapPopupArtifacts {
  locations?: LocationArtifact[];
  orphaned_assets?: AssetArtifact[];
}

/**
 * Display icons for MapArtifacts
 *
 * Event handlers:
 * - `artifactclick`
 * - `clusterclick`
 */
export class MapArtifactIcons {
  static instanceCount = 0;

  private readonly sourceId: string;
  private readonly clusterMarkerLayerId: string;
  private readonly clusterLabelLayerId: string;
  private readonly iconLayerId: string;

  private eventEmitter = new EventEmitter();

  private map: Map;

  private clusterRadius = 20;

  artifacts: MapArtifacts;

  constructor() {
    this.sourceId = `artifacts-source-${MapArtifactIcons.instanceCount}`;
    this.clusterMarkerLayerId = `artifacts-cluster-label-${MapArtifactIcons.instanceCount}`;
    this.clusterLabelLayerId = `artifacts-cluster-marker-${MapArtifactIcons.instanceCount}`;
    this.iconLayerId = `icon-layer-${MapArtifactIcons.instanceCount}`;

    MapArtifactIcons.instanceCount += 1;
  }

  addTo(map: Map, beforeLayer = '') {
    this.map = map;

    map.addSource(this.sourceId, this.getSourceDefinition());
    map.addLayer(this.getClusterMarkerLayer(), beforeLayer);
    map.addLayer(this.getClusterLabelLayer(), beforeLayer);
    map.addLayer(this.getIconLayer(), beforeLayer);

    this.initializeEventHandlers(map);

    return this;
  }

  updateArtifacts(artifacts: MapArtifacts) {
    this.artifacts = artifacts;
    this.update();
  }

  private update() {
    if (this.map) {
      const source = <GeoJSONSource>this.map.getSource(this.sourceId);
      if (this.map.isStyleLoaded()) {
        source.setData(this.getArtifactsGeoJSON());
      } else {
        this.map.once('styledata', () => {
          source.setData(this.getArtifactsGeoJSON());
        });
      }
    }
  }

  private getSourceDefinition(): GeoJSONSourceRaw {
    return {
      type: 'geojson',
      cluster: true,
      clusterRadius: this.clusterRadius,
      clusterMaxZoom: this.map.getMaxZoom(),
      data: this.getArtifactsGeoJSON(),
    };
  }

  private getArtifactsGeoJSON() {
    return featureCollection(this.getArtifactFeatures());
  }

  private getArtifactFeatures() {
    return this.artifacts
      ? [
          ...this.artifacts.fixed_locations.map((l) =>
            this.getArtifactFeature('location', l.coordinates, {
              ...l,
              asset_status:
                l.tracking?.asset_status || RelevanceStatus.Inactive,
            })
          ),
          ...this.artifacts.remote_locations.map((l) =>
            this.getArtifactFeature('location', l.coordinates, {
              ...l,
              asset_status:
                l.tracking?.asset_status || RelevanceStatus.Inactive,
            })
          ),
          ...this.artifacts.mobile_locations.map((l) =>
            this.getArtifactFeature('location', l.tracking.coordinates, {
              ...l,
              asset_status:
                l.tracking?.asset_status || RelevanceStatus.Inactive,
            })
          ),
          ...this.artifacts.roving_locations.map((l) =>
            this.getArtifactFeature('location', l.coordinates, {
              ...l,
              asset_status:
                l.tracking?.asset_status || RelevanceStatus.Inactive,
            })
          ),
          ...this.getOrphanedAssetClusters().map((a) =>
            this.getArtifactFeature('assets', a.coordinates, a.assets)
          ),
        ].filter((feature) => !!feature)
      : [];
  }

  /**
   * Groups assets with matching coordinates (there may be overlap since the coordinates are that of the location the report to)
   */
  private getOrphanedAssetClusters(): {
    coordinates: Coordinates;
    assets: AssetArtifact[];
  }[] {
    const hashGroups: { [key: string]: AssetArtifact[] } =
      this.artifacts.orphaned_assets.reduce((acc, asset) => {
        if (!asset.tracking?.coordinates) {
          return acc;
        }
        const hash = `${asset.tracking.coordinates.longitude},${asset.tracking.coordinates.latitude}`;
        acc[hash] = acc[hash] ? [...acc[hash], asset] : [asset];
        return acc;
      }, {});

    return Object.values(hashGroups).map((assets) => ({
      coordinates: assets[0].tracking.coordinates,
      assets,
    }));
  }

  private getArtifactFeature(
    type: 'location' | 'assets',
    coordinates: Coordinates,
    data: any
  ) {
    return coordinates
      ? point([coordinates.longitude, coordinates.latitude], {
          type,
          data,
        })
      : null;
  }

  private getClusterMarkerLayer(): AnyLayer {
    return {
      id: this.clusterMarkerLayerId,
      type: 'circle',
      source: this.sourceId,
      filter: ['all', Expressions.isCluster],
      paint: {
        'circle-color': Colors.cluster,
        'circle-radius': 15,
      },
    };
  }

  private getClusterLabelLayer(): AnyLayer {
    return {
      id: this.clusterLabelLayerId,
      type: 'symbol',
      source: this.sourceId,
      filter: ['all', Expressions.isCluster],
      layout: {
        'text-field': [
          <ExpressionName>'number-format',
          ['get', 'point_count'],
          {},
        ],
        'text-font': ['Montserrat Bold', 'Arial Unicode MS Bold'],
        'text-size': 11,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': Colors.clusterText,
      },
    };
  }

  private getIconLayer(): AnyLayer {
    return {
      id: this.iconLayerId,
      type: 'symbol',
      source: this.sourceId,
      filter: ['all', Expressions.isNotCluster],
      layout: {
        'icon-image': [
          'case',
          Expressions.isAssets,
          Icons.Asset.name,
          Expressions.isFixed,
          Icons.Fixed.name,
          Expressions.isMobile,
          Icons.Mobile.name,
          Expressions.isRoving,
          Icons.Roving.name,
          Icons.Remote.name,
        ],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-halo-color': 'rgba(0, 0, 0, 0.2)',
        'icon-halo-blur': 2,
        'icon-halo-width': 3,
        'icon-color': [
          'case',
          Expressions.isAssets,
          Colors.asset,
          Expressions.isActive,
          Colors.active,
          Expressions.isRecent,
          Colors.recent,
          Expressions.isInactive,
          Colors.inactive,
          Expressions.isIgnored,
          Colors.ignored,
          Colors.unconfirmed,
        ],
      },
    };
  }

  private initializeEventHandlers(map: Map) {
    [this.clusterMarkerLayerId, this.iconLayerId].forEach((layerId) => {
      map.on('mouseenter', layerId, () => this.handleMarkerLayerEnter());
      map.on('mouseleave', layerId, () => this.handleMarkerLayerLeave());
    });

    map.on('click', this.iconLayerId, (event) => this.handleIconClick(event));
    map.on('click', this.clusterMarkerLayerId, (event) =>
      this.handleClusterClick(event)
    );
  }

  private handleMarkerLayerEnter() {
    this.map.getCanvas().style.cursor = 'pointer';
  }

  private handleMarkerLayerLeave() {
    this.map.getCanvas().style.cursor = '';
  }

  private handleIconClick(event: MapMouseEvent & EventData) {
    const properties = event.features[0].properties;
    this.eventEmitter.emit('artifactclick', {
      type: properties.type,
      data: JSON.parse(properties.data),
      coordinates: event.features[0].geometry.coordinates,
    });
  }

  private handleClusterClick(event: MapMouseEvent & EventData) {
    const { cluster_id, point_count } = event.features[0].properties;
    const coordinates = event.features[0].geometry.coordinates;

    const source = this.map.getSource(this.sourceId) as GeoJSONSource;

    source.getClusterLeaves(cluster_id, point_count, 0, (_, leaves) => {
      this.eventEmitter.emit('clusterclick', {
        data: this.parseClusterArtifacts(leaves),
        coordinates,
      });
    });
  }

  private parseClusterArtifacts(features): MapPopupArtifacts {
    return features.reduce(
      (acc, feature) => {
        if (feature.properties.type === 'assets') {
          acc.orphaned_assets = [
            ...acc.orphaned_assets,
            ...feature.properties.data,
          ];
        } else if (feature.properties.type === 'location') {
          acc.locations.push(feature.properties.data);
        }
        return acc;
      },
      { locations: [], orphaned_assets: [] }
    );
  }

  on(event, fn, onlyOnce = false) {
    if (onlyOnce) {
      this.eventEmitter.once(event, fn);
    } else {
      this.eventEmitter.addListener(event, fn);
    }
    return this;
  }

  once(event, fn) {
    return this.on(event, fn, true);
  }

  off(event, fn) {
    this.eventEmitter.removeListener(event, fn);
    return this;
  }
}
