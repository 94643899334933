import { Component } from '@angular/core';
import { UserDataService } from '../../util/user-data.service';
import { LayoutService } from '../layout.service';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  title$ = this.headerService.title$;
  isDesktop$ = this.layoutService.isDesktop$;

  user = this.userDataService.user;
  planTiers = this.userDataService.planTiers;

  constructor(
    private userDataService: UserDataService,
    private headerService: HeaderService,
    private layoutService: LayoutService
  ) {}

  openSidenav() {
    this.layoutService.openSidenav();
  }
}
