import { featureCollection, point } from '@turf/helpers';
import { EventEmitter } from 'events';
import { AnyLayer, GeoJSONSource, GeoJSONSourceRaw, Map } from 'mapbox-gl';
import { Coordinates, LocationType } from '../../models/location';
import { RelevanceStatus } from '../../models/relevance-status';
import { Colors, Icons } from './map.constants';

const convertLngLat = ({ lng, lat }): Coordinates => ({
  longitude: lng,
  latitude: lat,
});

const ICON_MAP = {
  remote: Icons.Remote.name,
  mobile: Icons.Mobile.name,
  fixed: Icons.Fixed.name,
  asset: Icons.Asset.name,
  roving: Icons.Roving.name,
};

const COLOR_MAP = {
  [RelevanceStatus.Active]: Colors.active,
  [RelevanceStatus.Recent]: Colors.recent,
  [RelevanceStatus.Inactive]: Colors.inactive,
  [RelevanceStatus.Ignored]: Colors.ignored,
  [RelevanceStatus.Unconfirmed]: Colors.unconfirmed,
  asset: Colors.asset,
};

export class MapDraggablePoint {
  static instanceCount = 0;

  private readonly sourceId: string;
  readonly layerId: string;

  private map: Map;

  private eventEmitter = new EventEmitter();

  private options = {
    strokeColor: '#000000',
    strokeWeight: 0.5,
    strokeOpacity: 0.75,
    fillColor: '#214D7D',
    fillOpacity: 0.15,
  };

  constructor(
    private coordinates: Coordinates,
    private type: LocationType | 'asset',
    private status: RelevanceStatus | 'asset'
  ) {
    this.sourceId = `draggable-point-source-${MapDraggablePoint.instanceCount}`;
    this.layerId = `draggable-point-layer-${MapDraggablePoint.instanceCount}`;

    MapDraggablePoint.instanceCount = MapDraggablePoint.instanceCount + 1;
  }

  setCoordinates(coordinates: Coordinates) {
    this.coordinates = coordinates;
    this.update();
  }

  getCoordinates(): Coordinates {
    return this.coordinates;
  }

  private update() {
    if (this.map) {
      const source = <GeoJSONSource>this.map.getSource(this.sourceId);
      source.setData(this.getPointGeoJSON());
    }
  }

  addTo(map: Map, beforeLayer = '') {
    const addPointToMap = () => {
      map.addSource(this.sourceId, this.getSource());
      map.addLayer(this.getPointLayer(), beforeLayer);
    };

    // @ts-ignore - hack https://github.com/mapbox/mapbox-gl-js/issues/6707#issuecomment-495481411
    if (map._loaded) {
      addPointToMap();
    } else {
      map.once('load', () => {
        addPointToMap();
      });
    }

    this.map = map;
    this.initializeEventHandlers(map);

    return this;
  }

  private getSource(): GeoJSONSourceRaw {
    return {
      type: 'geojson',
      data: this.getPointGeoJSON(),
    };
  }

  private getPointGeoJSON() {
    return featureCollection([this.getPointFeature()]);
  }

  private getPointFeature() {
    return point([this.coordinates.longitude, this.coordinates.latitude]);
  }

  private getPointLayer(): AnyLayer {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': ICON_MAP[this.type],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-halo-color': 'rgba(0, 0, 0, 0.2)',
        'icon-halo-blur': 2,
        'icon-halo-width': 2,
        'icon-color': COLOR_MAP[this.status],
      },
    };
  }

  private initializeEventHandlers(map: Map) {
    const canvas = map.getCanvasContainer();

    map.on('mouseenter', this.layerId, () => {
      canvas.style.cursor = 'move';
    });

    map.on('mouseleave', this.layerId, () => {
      canvas.style.cursor = '';
    });

    const onDragStart = (event, dragEventName, endEventName) => {
      const onDrag = (e) => {
        canvas.style.cursor = 'grabbing';

        this.setCoordinates(convertLngLat(e.lngLat));
        this.eventEmitter.emit('drag', { coordinates: this.coordinates });
      };

      const onEnd = (e) => {
        canvas.style.cursor = '';

        this.setCoordinates(convertLngLat(e.lngLat));
        this.eventEmitter.emit('dragend', { coordinates: this.coordinates });

        map.off(dragEventName, onDrag);
      };

      // Prevent default map drag behaviour
      event.preventDefault();

      canvas.style.cursor = 'grab';

      map.on(dragEventName, onDrag);
      map.once(endEventName, onEnd);
    };

    map.on('mousedown', this.layerId, (event) => {
      onDragStart(event, 'mousemove', 'mouseup');
    });

    map.on('touchstart', this.layerId, (event) => {
      onDragStart(event, 'touchmove', 'touchend');
    });
  }

  on(event, fn, onlyOnce = false) {
    if (onlyOnce) {
      this.eventEmitter.once(event, fn);
    } else {
      this.eventEmitter.addListener(event, fn);
    }
    return this;
  }

  once(event, fn) {
    return this.on(event, fn, true);
  }

  off(event, fn) {
    this.eventEmitter.removeListener(event, fn);
    return this;
  }
}
