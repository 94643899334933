import { SortDirection } from '@angular/material/sort';
import { Decoder } from 'decoders';
import { Observable } from 'rxjs';
import { Entity, EntityList, Identified, identityEncoder } from '../models';
import {
  HttpService,
  HttpServiceBuilder,
  HttpServiceConfig,
  PaginationOptions,
} from './http.service';

export type SortFilter<C extends string> = Partial<Record<C, SortDirection>>;
export interface Sortable<C extends string> {
  sort?: SortFilter<C>;
}

export abstract class EntityService<E extends Entity, F = {}> {
  protected httpService: HttpService<E>;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<E>,
    route: string,
    decoder: Decoder<E>
  ) {
    this.httpService = httpServiceBuilder.build({
      route,
      decoder,
      encoder: this.getEncoder(),
    });
  }

  getEncoder(): (entity: E) => {} {
    return identityEncoder;
  }

  getCreateConfig(entity?: E): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  getDeleteConfig(): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  createEntity(entity: E): Observable<E> {
    const config = this.getCreateConfig(entity);
    return this.httpService.create(entity, config);
  }

  deleteEntity(entity: E, destroy = false): Observable<boolean> {
    return this.deleteEntityById(entity.id, destroy);
  }

  deleteEntityById(id: string, destroy = false): Observable<boolean> {
    const config = this.getDeleteConfig();

    const query = {};
    if (destroy) {
      query['destroy'] = true;
    }

    return this.httpService.deleteById(id, config, query);
  }

  restoreEntity(entity: E): Observable<E> {
    const config = { ...this.httpService.getConfig() };
    config.route = `${config.route}/${entity.id}/undelete`;

    return this.httpService.create(null, config);
  }

  getEntity(id: string): Observable<E> {
    return this.httpService.getById(id);
  }

  getEntityListConfig(filter: F): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  getEntityListQuery(filter: F): {} {
    return {};
  }

  getEntityList(
    filter: F,
    pageOptions: PaginationOptions
  ): Observable<EntityList<Identified<E>>> {
    const config = this.getEntityListConfig(filter);
    const query = this.getEntityListQuery(filter);
    return this.httpService.search(query, pageOptions, config);
  }

  getUpdateConfig(entity: E): HttpServiceConfig<E> {
    return this.httpService.getConfig();
  }

  updateEntity(entity: E): Observable<E> {
    const config = this.getUpdateConfig(entity);
    return this.httpService.update(entity.id, entity, config);
  }

  /**
   * deleteMultiple sends a patch request to delete multiple entities, ids sent as query
   *
   * @param ids ids of the entities to be deleted
   */
  deleteMultiple(ids: string[]): Observable<E[]> {
    const config: HttpServiceConfig<E> = {
      ...this.getDeleteConfig(),
    };
    config.route = `${config.route}/delete`;

    const query = {
      'id[]': ids,
    };

    return this.httpService.patchMultiple(query, config);
  }
}
