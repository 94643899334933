import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { identityEncoder } from 'src/app/models';
import {
  Asset,
  AssetPinpoint,
  AssetTracking,
  assetTrackingsDecoder,
} from 'src/app/models/asset';
import { RemoteLocation } from 'src/app/models/remote-location';
import { HttpService, HttpServiceBuilder } from 'src/app/util/http.service';

@Injectable()
export class OrphanedAssetService {
  protected httpService: HttpService<AssetPinpoint, AssetTracking[]>;

  constructor(
    httpServiceBuilder: HttpServiceBuilder<AssetPinpoint, AssetTracking[]>
  ) {
    this.httpService = httpServiceBuilder.build({
      route: 'assets',
      decoder: assetTrackingsDecoder,
      encoder: identityEncoder,
    });
  }

  // pinpointAsset assigns the orphaned asset to the given location at the given timestamp, makes the asset no longer orphaned
  pinpointAsset(
    asset: Asset,
    location: RemoteLocation,
    timestamp: Date
  ): Observable<AssetTracking[]> {
    const q: {} = {
      latitude: location.coordinates.latitude,
      longitude: location.coordinates.longitude,
      timestamp,
    };
    const params = new HttpParams({
      fromObject: q,
    });

    return this.httpService.create(
      null,
      {
        route: `assets/${asset.id}/pinpoints`,
      },
      params
    );
  }
}
