import { Injectable } from '@angular/core';
import { StateService } from '../core/services/state/state.service';

export interface ImpersonationDetails {
  emails: {
    impersonator: string;
    impersonated: string;
  };
}
const initialData = {
  emails: null,
};

@Injectable({
  providedIn: 'root',
})
export class ImpersonateDataService extends StateService<ImpersonationDetails> {

  impersonationEmails$ = this.select((d) => d.emails);
  isImpersonating$ = this.select((d) => !!d.emails);

  constructor() {
    super(initialData);
  }

  setImpersonation(impersonatorEmail: string, impersonatedEmail: string): void {
    this.patchState({ emails: { impersonated: impersonatedEmail, impersonator: impersonatorEmail } });
  }

  clearImpersonation(): void {
    this.patchState(initialData);
  }

}
