import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganizationsService } from '../../organization/organizations.service';
import { Organization } from '../../models/organization';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit, OnDestroy {

  org: Organization;
  loading = true;

  private subscriptions = new Subscription();

  constructor(
    private organizationService: OrganizationsService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.organizationService.getEntity().pipe(
      finalize(() => this.loading = false),
    ).subscribe((organization) => {
      this.org = organization;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
