import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Nomenclature, Organization, PlanTiers } from '../models/organization';
import { FeatureFlag, User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userSubject = new BehaviorSubject<User>(null);
  user: User;
  nomenclature: Nomenclature = {
    singular: {
      fixed_location: 'Warehouse',
      remote_location: 'Job Site',
      mobile_location: 'Vehicle',
      roving_location: 'Trailer',
    },
    plural: {
      fixed_location: 'Warehouses',
      remote_location: 'Job Sites',
      mobile_location: 'Vehicles',
      roving_location: 'Trailers',
    },
  };
  organization: Organization;
  planTiers: PlanTiers;
  privileges: Map<string, string[]>;
  featureFlags: FeatureFlag[];

  setUser(user: User) {
    this.userSubject.next(user);
    this.user = user;
    this.nomenclature = user.organization.industry.nomenclature;
    this.organization = user.organization;
    this.planTiers = this.organization.plan_tiers;
    this.privileges = user.privileges;
    this.featureFlags = this.user.feature_flags;
  }

  clearUser() {
    this.user = null;
    this.nomenclature = null;
    this.organization = null;
    this.planTiers = null;
    this.privileges = null;
    this.featureFlags = null;
  }
}
