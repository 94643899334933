import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Webhook } from '../../../models/webhook';
import { EntityList } from '../../../models';
import { MatDialog } from '@angular/material/dialog';
import { WebhookEditComponent } from '../webhook-edit/webhook-edit.component';

@Component({
  selector: 'app-webhook-list',
  templateUrl: './webhook-list.component.html',
  styleUrls: ['./webhook-list.component.scss'],
})
export class WebhookListComponent {
  @Input()
  webhooks: EntityList<Webhook>;

  @Input()
  global = false;

  @Input()
  unauthorized_delete = {};

  @Output() delete = new EventEmitter<Webhook>();

  displayedColumns = ['name', 'organization', 'type', 'actions'];

  constructor(private dialog: MatDialog) {}

  handleDeleteClick(item: Webhook) {
    if (this.unauthorized_delete[item.id]) {
      return;
    }
    this.delete.emit(item);
  }

  editWebhook(webhook: Webhook, is_disabled: boolean) {
    const dialog = this.dialog.open(WebhookEditComponent, {
      data: {
        webhook,
        global: this.global,
        is_disabled,
      },
      minWidth: '375px',
    });
    dialog.afterClosed().subscribe((isDelete) => {
      if (isDelete) {
        this.handleDeleteClick(webhook);
      }
    });
  }
}
