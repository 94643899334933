<ng-container *ngIf="setupStatus$ | async as setupStatus">
  <ng-container *ngIf="shouldShowTrigger(setupStatus)">
    <button
      mat-raised-button
      color="primary"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="toggleOpen($event)"
      matBadge="0"
      matBadgeSize="medium"
      data-qa="onboarding-trigger"
      class="onboarding-button"
    >
      Organization Setup
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (overlayOutsideClick)="toggleOpen($event)"
    >
      <app-onboarding-overlay
        (close)="toggleOpen(null)"
      ></app-onboarding-overlay>
    </ng-template>
  </ng-container>
</ng-container>
