import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureFlagGuard } from 'src/app/core/guards/featureFlag.guard';
import { PrivilegeGuard } from 'src/app/core/guards/privilege.guard';
import { ContentComponent } from './content/content.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SubscriptionGuard } from './core/guards/subscription.guard';
import { LayoutComponent } from './layout/layout/layout.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { PaymentRequiredComponent } from './payment-required/payment-required.component';

const subRequiredRoutes: Routes = [
  {
    path: 'assets',
    loadChildren: () =>
      import('./assets/assets.module').then((m) => m.AssetsModule),
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'gateways',
    loadChildren: () =>
      import('./gateways/gateways.module').then((m) => m.GatewaysModule),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./locations/locations.module').then((m) => m.LocationsModule),
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
  },
  {
    path: 'webhooks',
    loadChildren: () =>
      import('./webhooks/webhooks.module').then((m) => m.WebhooksModule),
  },
  {
    path: 'impersonate',
    loadChildren: () =>
      import('./impersonate/impersonate.module').then(
        (m) => m.ImpersonateModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./vitals/vitals.module').then((m) => m.VitalsModule),
    canActivate: [FeatureFlagGuard, PrivilegeGuard],
    data: {
      featureFlag: 'vitals-dashboard',
      privilege: 'vital/read',
      redirectTo: '/map',
    },
  },
  { path: '**', component: NotfoundComponent },
];

const noSubRequiredRoutes: Routes = [
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./subscription/subscription.module').then(
        (m) => m.SusbcriptionModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./alerts/alerts.module').then((m) => m.AlertsModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'organization',
    loadChildren: () =>
      import('./organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
  },

  {
    path: 'hardware',
    loadChildren: () =>
      import('./hardware/hardware.module').then((m) => m.HardwareModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'payment_required',
    component: PaymentRequiredComponent,
  },
  // default to map view
  { path: '', redirectTo: '/map', pathMatch: 'full' },
];

const authenticatedRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: ContentComponent,
        children: noSubRequiredRoutes,
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, SubscriptionGuard],
    canActivateChild: [AuthGuard, SubscriptionGuard],
    children: [
      {
        path: '',
        component: ContentComponent,
        children: subRequiredRoutes,
      },
    ],
  },
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  ...authenticatedRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
